/* Fonts  */

  @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300&display=swap');

/* colours  */
p {
  font-size: 16px ;
}
:root {
  --primary: #000000;
  --secondary: #e8151b;
  --ternary: #243d6e;
  --sec-btn: #ffffff;
  --primary-font: #5c5c5c;
}

/* Basic css */
.header-body{
  background: #fff;
}
.header{
  position: relative; 
  width: 100%;
  z-index: 1212212;
  transition: 0.5s;
}
.header.sticky{
  position: fixed;
  top: 0;
  width: 100%;
  transition: 0.5s;

}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;

}

.sec-first-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  flex-wrap: wrap;
}

.sec-first-wrapper .big-boxes {
  width: 49.5%;
  position: relative;
  height: 50vh;
  min-height: 460px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.sec-first-wrapper .big-boxes:hover img {
  transform: scale(1.1);
}

.sec-first-wrapper .big-boxes img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s;
}

.in-box-content {
  position: absolute;
  padding: 0 4rem;
  z-index: 11;
}

.in-box-content h2 {
  color: #ffffff;
}

.in-box-content p {
  color: #ffffff;
  max-width: 450px;
}

.box-ovarlays {
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  background: #00000037;
}

.about-us {
  padding: 0.2rem 0;
  background: #e5e5e5;
}

.inner-services .grid-item {
  margin-bottom: 2rem;
}

.grid-item {
  width: 33%;
}
.grid-item-2 {
  width: 66.5%;
  background: #002786;
  margin-bottom: 0.5rem;
}

.block img {
  height: 100%;
  width: 100%;
}
.grid-item-3 {
  width: 33%;
  margin-bottom: 0.5rem;
}

.grid-on-mobile {
  display: none;
}

.block {
  background-color: #9b3333;
  color: #fff;
  /* display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; */
  height: 440px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.block img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

@media (max-width: 990px) {
  .block {
    width: 100%;
  }
  .grid-item {
    width: 100%;
  }
  .second-wrapper {
    flex-direction: column !important;
  }
}
.second-wrapper {
  padding: 6rem 0 !important;
  margin: 0 auto;
  padding: 0 30px;
  max-width: 1260px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.second-wrapper .grid-item img {
  transition: 0.5s;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.second-wrapper .grid-item-2 img {
  transition: 0.5s;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.second-wrapper .grid-item-3 img {
  transition: 0.5s;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.second-wrapper .grid-item:hover img {
  transform: scale(1.2);
}
.second-wrapper .grid-item-2:hover img {
  transform: scale(1.2);
}
.second-wrapper .grid-item-3:hover img {
  transform: scale(1.2);
}

.abt-second-content {
  position: absolute;
  bottom: 0;
  padding: 3rem 3rem 2rem;
  margin-bottom: 5rem;
  max-width: 290px;
  background-color: #005da9;
  color: #fff;
  z-index: 11;
}

.second-wrapper .grid-item:hover .abt-second-content {
  background-color: #ffffff;
}

.second-wrapper .grid-item:hover .abt-second-content h2 {
  color: #005da9 !important;
}

.second-wrapper .grid-item:hover .abt-second-content p {
  color: #474646 !important;
}

.abt-second-content h2 {
  font-size: 28px;
  color: #fff;
  text-transform: uppercase;
}

.abt-second-content p {
  font-size: 14px;
  color: #fff;
}

.latest {
  background: #f4f4f4;
  padding: 4rem 0;
}

.inside-latest-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.latest-box-small {
  width: 24.5%;
  height: 50vh;
  background: #fff;
}

.latest-box-big {
  width: 49%;
  height: 50vh;
  background: #fff;
}

.latest-container {
  /* padding: 6rem 0 !important;
  padding: 0 30px; */
  margin: 0 auto;
  max-width: 1260px;
}

.latest-pc {
  display: block;
}

.latest-mobile {
  display: none;
}

.latest-content {
  padding: 1rem 1rem;
}

.latest-content p {
  font-weight: 600;
  text-transform: capitalize;
}

.latest-content p span {
  font-size: 13px;
}

.latest-content h5 {
  font-size: 18px;
  color: #008cff;
}

.latest-content a {
  text-transform: capitalize;
  font-size: 800;
  color: #5c5c5c;
}

.latest-box-big .latest-content h2 {
  font-size: 3.5rem;
}

.latest-box-big .latest-content h5 {
  color: #5c5c5c;
}

.latest-box-big .latest-content p {
  font-size: 14px;
  font-weight: 400;
}

.latest-box-big:hover {
  transition: box-shadow 0.25s;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* .owl-carousel .owl-stage-outer {
  height: 550px;
} */

@media (max-width: 1450px) {
  .latest-box-small {
    width: 95% !important;
    height: 50vh;
    margin: 0 01rem;
  }
  .latest-box-big {
    width: 95% !important;
    height: 50vh;
  }
}

@media (max-width: 990px) {
  .latest-pc {
    display: none;
  }
  .latest-mobile {
    display: block !important;
  }
}

.footer {
  background: #005da9;
}

.footer-container {
  padding: 6rem 0 !important;
  margin: 0 auto;
  padding: 0 30px;
  max-width: 1260px;
}

.footer-grid {
  width: 33.333333% !important;
  position: relative;
  margin-bottom: 1rem;
}

.footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap !important;
}

.footer-wrapper .footer-grid h2 {
  color: #fff;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;
}

.footer-wrapper .footer-grid ul li a {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
}

.footer-wrapper .footer-grid ul li {
  padding-bottom: 0.6rem;
}

.footer-wrapper .footer-grid ul {
  padding-top: 1rem;
}

.footer-wrapper .footer-grid ul li a::after {
  content: ">";
  position: absolute;
  right: 0;
  margin-right: 6rem;
  font-size: 1rem;
  font-weight: bolder;
}

.footer-border {
  border: 1px solid #fff !important;
}

/********************************************
               Footer
*********************************************/

.footer {
  width: 100%;
  height: auto;
  /* background: rgb(7, 32, 90);
  background: linear-gradient(
    87deg,
    rgba(7, 32, 90, 1) 0%,
    rgba(13, 70, 132, 1) 71%
  ); */
  /* background-image: url("../images/footer-bg.jpg"); */
  background: #282b36;
  padding-top: 4rem;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.footer-pattern {
  position: absolute;
  width: 100%;
  height: 100%;
}

.widget-text p {
  color: #ffffff;
  font-size: 13px;
  line-height: 25px;
  padding: 15px 60px 0 0;
  font-weight: 300;
}

.widget-title {
  margin-bottom: 2rem;
}

.widget-title h5 {
  font-size: 22px;
  color: #ffffff !important;
  margin-bottom: 3rem;
}

.widget-list ul li {
  color: #fff;
  z-index: 121;
  font-size: 13px;
  font-weight: lighter;
}

.widget-list i {
  margin-right: 5px;
}

.widget-list ul {
  margin: 0;
  margin-top: 20px;
  z-index: 121;
  padding-left: 0 !important;
}

.widget-list ul li {
  margin-top: 15px;
  z-index: 121;
}

.widget-list ul li a {
  text-decoration: none;
  color: #ffffff;
  transition: 0.5s;
  z-index: 121;
  font-size: 15px;
  font-weight: 330;
  font-family: "Heebo", sans-serif;
  /* text-transform: capitalize !important; */
}

.footer-logo-btn {
  margin: 2rem 0;
}

.footer-logo-btn a {
  background: #1c3f94;
  padding: 1rem 2rem;
  text-align: center !important;
  font-size: 14px;
  font-family: "Heebo", sans-serif;
  color: #fff;
}

@media (max-width: 662px) {
  .abt-content p {
    padding-right: 0rem !important;
  }
}

.widget-list ul li a:hover {
  margin-left: 5px;
}

/* .widget-list ul li::before {
  content: "\f054";
  font-family: "my-icon-font";
  color: rgb(255, 254, 254);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -2em;
} */

.widget-contact ul li {
  list-style: none !important;
  margin-left: 8px;
  z-index: 1;
}

.widget-contact p {
  font-family: "Heebo", sans-serif;
  font-weight: 300;
  color: #f0f0f0;
  margin-bottom: 0;
}

/* .widget-title h5::before {
  content: "";
  position: absolute;
  margin-top: 40px;
  width: 40px;
  height: 5px;
  background: #f0f0f0;
} */

/* .widget-title h5::before {
  content: "";
  position: absolute;
  margin-top: 40px;
  width: 82px;
  height: 2px;
  background: #f0f0f0;
} */

.widget-contact {
  display: flex;
  margin-left: 10px;
  margin-top: 0px;
  z-index: 2;
  text-transform: lowercase !important;
}

.widget-contact .fa:before {
  position: absolute;
  margin-left: -20px;
  margin-top: 20px;
  color: #f0f0f0;
}

.widget-social ul {
  /* margin-left: -25px; */
}

.widget-social ul li {
  display: inline-block;
  padding-right: 25px;
  z-index: 121;
}

.btn-footer {
  margin-top: 25px;
}

.footer hr {
  border-top: 1px solid rgba(114, 114, 114, 0.493);
  width: 100%;
  margin: 0 auto;
}

.btn-footer p {
  font-size: 13px;
  text-align: center;
  color: var(--sec-btn);
}

.btn-footer a {
  color: var(--secondary);
}

.btn-footer a:hover {
  text-decoration: none;
}

.ttm-our-location-list {
  margin-top: 10px !important;
}

@media (max-width: 987px) {
  .footer-bottom p {
    font-size: 12px !important;
  }
}

/* Animations */

@keyframes fadeAndScale {
  from {
    opacity: 0;
    transform: scale3d(0, 0, 1);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

/* .copyrights {
  display: flex;
  justify-content: space-between;
} */

.copyrights p {
  font-size: 14px;
  color: #fff;
  text-align: center;
}

.copyrights a {
  color: #fff;
  transition: 0.5s !important;
  font-weight: 600;
}

/* .navbar-light .navbar-toggler-icon {
  background-image: url(../images/menu.png);
} */

.form-control {
  border-radius: 0rem !important;
  height: calc(1.5em + 0.75rem + 15px);
  font-size: 14px;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
select.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.subscribe-btn {
  background-color: #005da9 !important;
  padding: 0.5rem 1rem;
  color: #202020;
  border: none;
  color: #fff;
  margin: 5px 0 0 5px;
}

.footer-logo-align {
  /* display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; */
}

.footer-logo-align img {
  margin-bottom: 0.5rem;
  height: 2.5rem;
}

.working-hours {
  margin-top: 1rem;
}

.working-hours p {
  text-transform: capitalize;
}

.widget-title p {
  color: #fff;
  margin-bottom: 0 !important;
}

.latest .head h2 {
  text-align: center;
  padding-bottom: 2rem;
}

.subscribe-col {
  margin-bottom: 1rem !important;
}

.drop-down-cus {
  width: 100%;
  background: #105ca8;
  color: #fff !important;
  transition: 0.5s !important;
  opacity: 1;
}


/******************** Inside About ***********************/

.about-us-inside {
  padding: 5rem 0;
}

.inner-content {
  padding: 2rem 0;
}
.images-after img::before {
  content: "";
  width: 80%;
  height: 560px;
  position: absolute;
  z-index: -111;
  right: 0px;
  margin-top: 120px;
  background-image: red;
  z-index: 1111;
}

.three-boxes {
  padding: 2.5rem 0;
  background: #f5f4f4;
}

.threebox-wrapper {
  background: #fff;
}
.threebox-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 5%), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  flex-wrap: wrap;
  padding: 2rem 2rem;
}

.boxes {
  width: 32%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3rem;
  min-height: 10rem;
  border: 0.3rem solid #92c3eb;
  border-radius: 0.5rem;
  background: #0c4a97;
  color: #f0f0f0 !important;
}

.boxes p {
  color: #f0f0f0 !important;
}

.container-adjust {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.container-adjust {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.certificate-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  box-shadow: 0 4px 8px 0 rgba(238, 238, 238, 0.001),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.certificates hr {
  margin: 3rem 0 !important;
}
.certificate-wrapper .col-lg-3 {
  border-right: 1px solid #002786;
}
.certificate-wrapper .col-lg-9 {
  padding: 0 4rem;
}
.certificates {
  padding: 3rem 0;
}
.certificate-img {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.certificate-img img {
  height: 10rem;
}
.certificate-img h4 {
  font-size: 18px;
}

/* CONTACT */
.contact-box {
  width: 100%;
  display: block;
  margin-bottom: 30px;
}
.contact-box small {
  width: 100%;
  display: block;
  margin-bottom: 5px;
  opacity: 0.5;
  font-weight: 600;
}
.contact-box a {
  color: #0e0e0e;
  text-decoration: underline;
}
.contact-box .address-box {
 
  margin-bottom: 1rem;
}
.contact-box p{
  margin-bottom: 0;
  padding: 3px;
}
.map {
  height: 380px;
  margin-left: -50px;
  margin-right: -50px;
  margin-bottom: 60px;
  margin-top: 30px;
  position: relative;
}
.map:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #4237ef;
  mix-blend-mode: color;
}
.map .map-note {
  width: 300px;
  height: calc(100% - 60px);
  position: absolute;
  left: 50px;
  top: 30px;
  z-index: 2;
  padding: 30px;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: #4237ef;
}
.map .map-note h6 {
  margin-top: auto;
  font-weight: 600;
}

.contact-image {
  width: 100%;
  margin: 0;
}
.contact-image img {
  width: 100%;
}

/* CONTACT FORM */
.contact-block {
  padding: 5rem 0;
}
.contact-block h2 {
  font-family: "markPro";
}
.contact-block h5 {
  font-family: "markPro";
}

.contact-form {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
}

.contact-form .form-group {
  margin-bottom: 0;
}
.contact-form #contact {
  display: block;
}
.contact-form #contact .form-group {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 35px;
}
.contact-form #contact .form-group:last-child {
  margin-bottom: 0;
}
.contact-form #contact .form-group .checkbox {
  display: flex;
  flex-wrap: wrap;
  width: 30%;
  height: 52px;
  border: 1px solid #ccc;
  margin-right: 5%;
  align-items: center;
  padding-left: 15px;
}
.contact-form #contact .form-group .checkbox:last-child {
  margin-right: 0;
}
.contact-form #contact .form-group .checkbox input[type="checkbox"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  display: inline-block;
  margin-right: 5px;
}
.contact-form #contact .form-group .checkbox input[type="checkbox"]:checked {
  background: #131314;
  -webkit-box-shadow: inset 0px 0px 0px 2px #fff;
  -moz-box-shadow: inset 0px 0px 0px 2px #fff;
  box-shadow: inset 0px 0px 0px 2px #fff;
}
.contact-form #contact .form-group .checkbox small {
  display: inline-block;
  margin-top: 2px;
}
.contact-form #contact .form-group .checkbox label {
  order: 3;
}
.contact-form #contact .form-group p {
  width: 100%;
  display: block;
  line-height: 1;
  margin-bottom: 15px;
}
.contact-form #contact .form-group small {
  display: block;
  font-size: 13px;
  opacity: 0.4;
}
.contact-form #contact .form-group input[type="text"] {
  width: 100%;
  background: none;
  position: relative;
  z-index: 2;
}
.contact-form #contact .form-group textarea {
  width: 100%;
  position: relative;
  z-index: 2;
}
.contact-form #contact .form-group label.error {
  color: red;
  margin-top: 5px;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 13px;
}
.contact-form input.error {
  border: 1px solid red;
}
.contact-form textarea.error {
  border: 1px solid red;
}
.contact-form #success,
.contact-form #error {
  display: none;
  float: left;
  width: 100%;
  margin-top: 30px;
  font-size: 600;
  font-size: 15px;
}
.contact-form #error {
  background: red;
  color: #fff;
}
.contact-form #success {
  background: green;
  color: #fff;
}
.contact-form .alert {
  border: none;
  border-radius: 0;
  padding: 40px 30px;
}

.services-box .services-content2 {
  margin-top: 25px;
  padding-bottom: 30px;
  transition: 0.3s;
  padding-left: 30px;
  padding-right: 30px;
}

.services-icon i {
  font-size: 42px;
  color: #fff;
  text-align: center;
  line-height: 90px;
}

.contact-area::after {
  content: "";
  background: #f4fbfb;
  position: absolute;
  width: 90%;
  height: 100%;
  left: 5%;
  top: 0;
  z-index: 0;
}
.after-none::after {
  display: none;
}
.contact-area .container {
  position: relative;
  z-index: 1;
}
.contact-bg02 .slider-btn {
  width: 100%;
}

.contact-bg-an-01 {
  position: absolute;
  left: 9.8%;
  top: 25%;
  animation: alltuchtopdown 3s infinite;
  -webkit-animation: alltuchtopdown 3s infinite;
  animation-delay: 0s;
  -webkit-animation-delay: 1s;
}
.contact-bg-an-02 {
  position: absolute;
  right: 3%;
  bottom: 7%;
  animation: alltuchtopdown 5s infinite;
  -webkit-animation: alltuchtopdown 5s infinite;
  animation-delay: 0s;
  -webkit-animation-delay: 3s;
}

.services-box.contact-box {
  background: #0e2052;
  color: #fff;
}
.services-box.contact-box h5 {
  color: #fff;
}
.services-box.contact-box .services-icon i {
  background: #3788eb;
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

@keyframes alltuchtopdown {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    -webkit-transform: rotateX(0deg) translateY(-20px);
    -moz-transform: rotateX(0deg) translateY(-20px);
    -ms-transform: rotateX(0deg) translateY(-20px);
    -o-transform: rotateX(0deg) translateY(-20px);
    transform: rotateX(0deg) translateY(-20px);
  }
  100% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
}
@-webkit-keyframes alltuchtopdown {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    -webkit-transform: rotateX(0deg) translateY(-20px);
    -moz-transform: rotateX(0deg) translateY(-20px);
    -ms-transform: rotateX(0deg) translateY(-20px);
    -o-transform: rotateX(0deg) translateY(-20px);
    transform: rotateX(0deg) translateY(-20px);
  }
  100% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
}
.contact-field input {
  width: 100%;
  border: none;
  background-color: #fafafa;
  padding: 15px 15px;
  transition: 0.3s;
  border-radius: 0px;
  border: 1px solid rgba(183, 183, 183, 0.38);
  margin-bottom: 1rem;
}
.contact-field select {
  width: 100%;
  border: none;
  background-color: #fafafa;
  padding: 15px 15px;
  transition: 0.3s;
  border-radius: 0px;
  border: 1px solid rgba(183, 183, 183, 0.38);
}

.services-box.contact-box {
  background: #0e2052;
  color: #fff;
}
.services-box.contact-box p {
  color: #fff;
  font-size: 14px;
}
.custom-btn {
  background-color: #0c4a97;
  color: #fff;
  text-align: center;
  padding: 10px 20px;
  margin: 1rem 0;
}
.custom-btn:hover {
  background-color: #2264b4;
  color: #fff;
  text-align: center;
  padding: 10px 20px;
}
.contact-field input:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}
.services-area .services-box {
  padding-top: 30px;
  border-radius: 10px;
}

.custom-btn {
  background-color: #0c4a97;
  color: #fff;
  text-align: center;
  padding: 10px 20px;
  margin: 1rem 0;
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.c-message textarea {
  width: 100%;
}

/* .nav-white .nav-item .nav-link {
  color: #000000;
  padding: 2rem 0;
} */

/* Product details */

.productbg {
  background-size: cover;
  padding: 5rem 3rem;
}

.product_detailsection {
  background-color: #f4f4f4;
  min-height: 383px;
}
.inner_head {
  margin-top: 2rem;
  text-align: left;
  margin: auto;
}
.inner_head h2 {
  color: #004a98;
  text-transform: uppercase;

  padding-bottom: 20px;
  font-weight: 550;
  font-size: 23px;
}

.product_text {
  padding-top: 2rem;
}
.product_sidemenu {
  background-color: #fff;
  padding: 0px;
  /* padding: 1.4rem 0 !important; */
  height: auto;
  position: relative;

  z-index: 11;
}
.product_sidemenu .active {
  background: #005da9;
  color: #fff !important;
  margin: 0 !important;
}
.product_sidemenu .active a {
  color: #fff !important;
}
.product_sidemenu h4 {
  color: #6e6e6e;
  font-weight: bold;
  text-transform: uppercase;

  font-size: 18px;
  font-weight: 550;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-bottom: 1px solid #a8a8a875;
}

.product_sidemenu ul {
  margin: 0;
  padding: 0;
}
.product_sidemenu ul li {
  padding: 12px 30px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product_sidemenu ul li::after {
  content: ">";
  position: relative;
  font-size: 22px;
  height: 100%;
}

.product_sidemenu ul li a {
  color: #005da9;
  font-weight: 600;
  width: 100%;
  font-size: 16px;
  display: block;
  text-transform: uppercase;
  position: relative;
}
.product_sidemenu ul li .active {
  border-right: 5px solid #004a98;
}
.product_sidemenu ul li a:hover {
  text-decoration: none;
  color: #004a98;
}

.underline {
  position: relative;
  margin: 3px;
}

.underline:hover:after,
.underline:focus:after,
.underline:active:after {
  height: 100%;
}
.underline:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0px;
  height: 0;

  width: 6px;
  transition: height 0.25s;
}
.product_dtails_text {
  background-color: #bd1701;
  height: 100%;
  display: flex;
  align-items: center;
}
.product_dtails_text ul li {
  padding: 8px 20px;
  border-bottom: 1px solid #d15d4e;
}
.product_dtails_text ul li {
  display: inline-block;
  width: 49%;
  color: #fff;
}

.product_dtails {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.product_dtailsbottom {
  padding: 30px 10px;
}

.product_dtailsbottom ul li {
  margin: 15px;
}
.product_dtailsbottom ul li:before {
  font-family: "FontAwesome";
  content: "\f105";
  margin: 0 5px 0 -15px;
  color: #012f60;
}




.panel-group {
  width: 100%;
}
.panel-body {
  background: #004a98;
  color: #fff;
  padding: 2rem 0;
}
.location-box h3 {
  margin-top: 0;
  min-height: 54px;
  color: #fff;
  font-size: 24px;
}
#accordion-1 .panel-body {
  background-color: #005da9;
  color: #ffffff;
  border-top-color: #005da9;
}
#accordion-1 .panel-default > .panel-heading {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  color: #ffffff;
  background: #282b36;
  border-color: #282b36;
  padding: 16px;
}
.panel-heading a {
  color: #fff !important;
}
.panel-heading a:hover {
  text-decoration: none !important;
  color: #eaeaeb !important;
  transition: 0.5s;
}
.location-box a {
  display: flex;
}
.location-box .fa {
  width: 24px;
  height: 24px;
  text-align: center;
  padding: 5px;
  font-size: 13px;
  color: #282b36;
  background: #fff;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.location-box p {
  font-size: 13px;
  line-height: 1.5;
  color: #fff;
}
.location-box {
  margin: 15px 0 15px 15px;
  padding-right: 10px;
  color: #fff;
  margin-bottom: 30px;
  min-height: 280px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.location-box a {
  padding: 0.5rem 0;
  font-size: 14px;
  color: #fff;
}
.location-box a span {
  color: #fff;
}
#accordion-1 .panel-body .col-md-4.col-sm-6:nth-child(3n) .location-box {
  border-right: none;
}
.location-sec {
  background-size: cover;
  padding-top: 40px;
  padding-bottom: 40px;
}
.contact-div h1 {
  font-size: 28px;
}
.contact-div h5 {
  font-size: 22px;
}
.contact-div {
  max-width: 800px;
  margin: 40px auto;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  padding: 30px;
  position: relative;
  background: #fff;
  margin-bottom: 44px;

  z-index: 11;
}
.mail_send a {
  font-size: 14px;
  font-weight: 100;
  background: #005da9;
  color: #fff;
  padding: 0.6em 2.5rem;
  transition: 0.5s;
}
.mail_send a:hover {
  text-decoration: none !important;
  background: #076ec3;
}
.mail_send {
  margin: 1rem 1rem;
}
input,
input::placeholder {
  font: 14px/3 sans-serif !important;
}
select,
select::placeholder {
  font: 14px/1 sans-serif !important;
  color: rgba(255, 255, 255, 0.676);
}

/*************** Our services Inner ********************/

.our-services-inner {
  padding: 6rem 0;
}

.our-sv-wrapper-inner {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.our-sv-wrapper-inner .our-sv-cards {
  width: 100%;
  height: auto;
  margin-bottom: 2rem;
  background-color: #ffffff;
  padding: 40px 40px 40px 40px;
  box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  min-height: 350px;
}

.our-sv-cards-ovarlays {
  width: 100%;
  height: 0%;
  background-color: #005da9;
  position: absolute !important;
  transition: 0.5s;
  bottom: 0;
}
.our-sv-wrapper-inner .our-sv-cards:hover .our-sv-cards-ovarlays {
  height: 100% !important;
}
.our-sv-wrapper-inner .our-sv-cards:hover p {
  color: #fff !important;
}
.our-sv-wrapper-inner .our-sv-cards:hover h3 {
  color: #fff !important;
}
.our-sv-wrapper-inner .our-sv-cards:hover a {
  color: #fff !important;
}
.our-sv-wrapper-inner .our-sv-cards:hover .fas {
  color: #fff !important;
  z-index: 111;
}
.sv-content {
  z-index: 11;
}

/* .our-sv-wrapper .our-sv-cards:nth-child(1) .cta-btn {
  align-self: flex-end;
} */

.our-sv-wrapper-inner .our-sv-cards .fas {
  font-size: 3rem;
  color: #005da9;
}
.our-sv-wrapper-inner .our-sv-cards h3 {
  margin: 20px 0;
  font-size: 24px;
}
.our-sv-wrapper-inner .our-sv-cards:nth-child(1) .cta-btn a {
  background-color: #005da9;
  padding: 1rem 2rem;
  color: #ffffff;
}
.our-sv-wrapper-inner .our-sv-cards:nth-child(1) .cta-btn {
  transition: 0.5s;
}
.our-sv-wrapper-inner .our-sv-cards:nth-child(1) h6 {
  text-transform: uppercase;
  color: #005da9;
}
.cta-btn:hover {
  text-decoration: none;
  transform: translateY(-40%);
}
.cta-btn:hover a {
  text-decoration: none;
}
.our-sv-wrapper-inner .our-sv-cards a {
  text-transform: uppercase;
  font-size: 12px;
  font-family: "Inter", sans-serif;
  color: #005da9;
}

.our-sv-wrapper-inner .our-sv-cards a:hover {
  text-decoration: none;
}
.our-services-inner {
  padding: 6rem 0;
}

.our-sv-wrapper-inner {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
}

@media (max-width: 990px) {
  .our-sv-wrapper-inner {
    display: grid;
    grid-template-columns: 1fr;
  }
}
.services-inner {
  text-align: center;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.services-inner h6 {
  color: #002786;
  font-size: 16px;
}
.services-inner h2 {
  font-size: 38px;
}
.services-inner p {
  max-width: 600px;
}

.career-inner {
  padding: 3rem 0;
}
.career-location {
  margin-top: 3rem;
}
.career-location .fa {
  color: #004a98;
  font-size: 1.5rem;
}
.career-location p {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.location-id {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
}
.location-id .fa {
  padding-right: 1rem;
}
.job-exp ul {
  margin-left: 1rem;
}
.job-exp ul li {
  list-style: disc;
  font-size: 16px;
  font-family: "Heebo", sans-serif;
  color: #7a7a7a;
  font-weight: lighter;
  padding-bottom: 1rem;
}
.career-apply {
  margin-top: 2rem;
}
.career-apply a {
  background-color: #004a98;
  padding: 1rem 2rem;
  color: #ffffff;
}

.career-form {
  padding: 6rem;
}

.custom-wrapper-where {
}
.home-accordion .col-lg-8 {
  padding: 0;
}
.home-accordion .col-lg-4 {
  padding: 0;
  background: #0795b2;
}
.contactAccordion .accordion-item {
  background: #282b36 !important;
  border-color: #282b36 !important;
  color: #fff !important;
}
.contactAccordion .accordion-button {
  color: #fff !important;
}
.contactAccordion .accordion-body {
  background-color: #005da9 !important;
}

.accoridionMain-body {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr !important;
  column-gap: 3rem;
}
.accoridionMain-body h4 {
  color: #fff;
  font-size: 22px;
  margin-bottom: 1rem;
  text-transform: capitalize;
}
.accoridionMain-body p span {
  color: #fff !important;
}
.accoridionMain-body p {
  color: #fff !important;
}
.accoridionMain-body ul li {
  color: #fff;
  display: flex;
}
.accoridionMain-body ul li i {
  color: #f0f0f0;
  margin-right: 0.5rem;
  margin-top: 5px;
}

@media (max-width: 990px) {
  .accoridionMain-body {
    grid-template-columns: 1fr !important;
  }
}

.homeImage-accordion img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.modal-body-inner  {
  margin-bottom: 1.5rem;
}
.modal-dialog .modal-body{
  padding-bottom: 2rem;
}
.modal-dialog .modal-body h4 {
  font-size: 16px;
  color: #5a6268;
  font-weight: bolder;
}
@media(max-width:990px){
  .modal-body .row{
    gap: 3rem !important;
  }
}