/*
	Theme Name: Thebuilders
	Theme URI: http://www.thebuilders.ninzio.com
	Description: Thebuilders Corporate Responsive Retina-Ready WordPress Premium Theme
	Author: Ninzio Team
	Author URI: http://www.ninzio.com
	Version: 2.5
	License: GNU General Public License version 3.0
	License URI: http://www.gnu.org/licenses/gpl-3.0.html
	Tags: one-column, two-columns, right-sidebar, editor-style, featured-images, post-formats, translation-ready
	Text Domain: thebuilders
*/

/*TABLE OF CONTENTS
/*=============*/

/*
	MIX
	VC FRONT-END
	OWLCAROWSEL
	SEARCH
	404
	SLICK CAROUSELS
	HEADER
	GRID
	COLUMNS
	ANIMATION
	GALLERY
	CAROUSELS
	SECTION
	WIDGETS
	BUTTON
	DROPCAP
	HIGHLIGHT
	ICON LIST
	ICONS
	SEPARATOR
	ICON SEPARATOR
	SOCIAL LINKS
	VIDEO, AUDIO EMBED
	RECENT TWEETS
	CONTENTBOX
	TAGLINE
	TABS
	TABLES
	ACCORDION
	TIMER
	ALERT MESSAGE
	ICON-PROGRESS-BAR
	PROGRESS
	STEPS
	CIRCLE PROGRESS
	COUNTER
	TESTIMONIALS
	CLIENTS
	PERSONS
	POPUP
	MEDIA SLIDER
	PRICING TABLE
	PROJECTS
	POSTS
	COMMENTS
	WOO
	RESPONSIVE
	RETINA
*/

/*MIX
/*=============*/

[class*=" icon-"],
[class^="icon-"] {
  font-family: icomoon;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ps-container .ps-scrollbar-y-rail {
  position: absolute;
  right: 3px;
  width: 8px;
  border-radius: 4px;
  opacity: 0;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  background-color: rgba(255, 255, 255, 0.2);
}

.ps-container:hover .ps-scrollbar-y-rail,
.ps-container.hover .ps-scrollbar-y-rail {
  opacity: 0.6;
}

.ps-container .ps-scrollbar-y-rail:hover,
.ps-container .ps-scrollbar-y-rail.hover,
.ps-container .ps-scrollbar-y-rail.in-scrolling {
  opacity: 0.9;
}

.ps-container .ps-scrollbar-y {
  position: absolute;
  right: 0;
  width: 8px;
  border-radius: 4px;
  -webkit-transition: background-color.2s linear;
  transition: background-color 0.2s linear;
  background-color: rgba(255, 255, 255, 0.5);
}

.ps-container.ie .ps-scrollbar-x,
.ps-container.ie .ps-scrollbar-y {
  visibility: hidden;
}

.ps-container.ie:hover .ps-scrollbar-x,
.ps-container.ie:hover .ps-scrollbar-y,
.ps-container.ie.hover .ps-scrollbar-x,
.ps-container.ie.hover .ps-scrollbar-y {
  visibility: visible;
}

body,
button,
input,
pre,
code,
kbd,
samp,
dt,
textarea {
  color: #777;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333;
  margin: 0 0 5px;
  font-weight: 600;
}

.hidden {
  display: none !important;
  visibility: hidden !important;
}
.visible {
  display: block !important;
  visibility: visible !important;
}
.nz-clearfix:after,
.single_variation_wrap:after {
  content: "";
  display: table;
  clear: both;
}
*,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  word-wrap: break-word;
}

.wpml-ls-statics-post_translations {
  padding: 20px 0;
  margin: 0 auto;
}

.wpml-ls-statics-post_translations img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

#lang_sel_footer {
  background-color: #232323;
}

#lang_sel_footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

#lang_sel_footer ul li {
  width: 100%;
  text-align: center;
  padding: 0;
}

#lang_sel_footer ul li a {
  display: block;
  padding: 10px 5px;
  background-color: transparent !important;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

#lang_sel_footer ul li a:hover {
  opacity: 0.5;
}

#lang_sel_footer ul li a img {
  vertical-align: middle;
  margin-right: 5px;
}

#lang_sel_footer:after {
  content: "";
  display: table;
  clear: both;
}

html {
  position: static;
  height: 100% !important;
  overflow-y: scroll;
  overflow-x: hidden;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  margin: 0;
  min-height: 100%;
  counter-reset: stepcounter;
}

svg:not(:root) {
  overflow: hidden;
}
iframe,
object,
embed {
  border: none;
  vertical-align: bottom;
  max-width: 100%;
}
figure {
  max-width: 100% !important;
  margin: 0;
}
img,
img[class*="wp-image"] {
  max-width: 100%;
  height: auto !important;
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: bottom;
  margin: 0;
}
.map img {
  max-width: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
th {
  text-align: left;
}
tr,
th,
td {
  padding: 10px;
}

blockquote,
q {
  quotes: none;
}
q:before,
q:after {
  content: "";
  content: none;
}
blockquote > p:last-child {
  margin-bottom: 0;
}
blockquote {
  padding: 25px 25px 25px 70px;
  margin: 0 0 25px;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-style: italic;
  font-size: 16px;
  line-height: 26px;
  background-color: #f4f4f4;
  color: #777;
  position: relative;
}

blockquote:after {
  position: absolute;
  display: block;
  content: "";
  top: 30px;
  left: 30px;
  width: 40px;
  height: 40px;

  background-repeat: no-repeat;
  background-position: left top;
}

.twitter-tweet-rendered .EmbeddedTweet,
.twitter-tweet-rendered {
  width: 100% !important;
  max-width: 100% !important;
}

.single .single-post-content blockquote {
  background-color: #f7f7f7;
  display: inline-block;
}

.single-format-quote .single-post-content blockquote {
  background-color: transparent;
}

.single .single-post-content table {
  width: 100%;
  border-collapse: collapse;
}

.single .single-post-content table td {
  border: 1px solid #eee;
}

pre {
  display: block;
  font-family: monospace !important;
  white-space: pre;
  margin: 1em 0;
  background-color: #f5f5f5;
  padding: 15px !important;
}

ul,
ol {
  list-style-position: inside;
}
ul li,
ol li {
  padding: 5px 0;
}
a {
  text-decoration: none;
  outline: none;
}
a:hover,
a:active {
  outline: none;
}
abbr[title],
dfn[title] {
  cursor: help;
}
ins,
mark {
  text-decoration: none;
}
del {
  text-decoration: line-through;
}
strong,
b {
  font-weight: 700;
}
var,
address {
  font-style: normal;
}
sub,
sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -8px;
}
sub {
  bottom: -4px;
}
p,
q,
pre,
address,
hr,
code,
samp,
dl,
ol,
ul,
li ul,
li ol,
form,
table,
fieldset,
menu,
kbd,
samp {
  margin: 0 0 10px;
  padding: 0;
}
ul ul,
ol ol {
  margin-left: 20px;
}

dl dd {
  margin-left: 25px;
}
dl {
  padding: 20px 20px 15px;
  border-radius: 3px;
  border: 1px solid #eaeaea;
}
dl dt {
  float: left;
  clear: left;
  width: 100px;
  text-align: right;
  font-weight: 700;
}
dl dt:after {
  content: ":";
}
dl dd {
  margin: 0 0 0 110px;
  padding: 0 0 5px;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.mejs-time-current {
  background-color: rgba(255, 255, 255, 0.3) !important;
}
.mejs-controls button {
  background-color: transparent !important;
  box-shadow: none !important;
  color: inherit !important;
  -webkit-transform: translateY(0) !important;
  -ms-transform: translateY(0) !important;
  transform: translateY(0) !important;
}
.post-video > div.wp-video {
  width: 100% !important;
}
.post-video,
.post-audio {
  overflow: hidden;
  margin-bottom: 0px;
  width: 100%;
}
.vc_custom_heading {
  margin-bottom: 0 !important;
}
.queryloader__overlay__percentage {
  font-size: 72px !important;
}
img[class*="wp-image-"] + br,
img + br,
img + p:empty {
  display: none;
}
p:empty {
  display: none !important;
}
.alignleft,
img.alignleft {
  margin-right: 1.5em;
  display: inline;
  float: left;
}
.alignright,
img.alignright {
 
  display: inline;
  float: right;

}

@media(max-width:990px){
  .alignright,
    img.alignright {
      padding-left: 0px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-top: 1rem;
      
    }
}
.aligncenter,
img.aligncenter {
  margin-right: auto;
  margin-left: auto;
  display: block;
  clear: both;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.post-video {
  overflow: hidden;
}
.mejs-poster img {
  display: block !important;
}
.wp-playlist .mejs-poster img {
  display: none !important;
}
.mejs-overlay-button {
  background-repeat: no-repeat !important;
}
.mejs-controls .mejs-time-rail .mejs-time-current {
  background: #444 !important;
}
.wp-playlist {
  padding: 10px !important;
  background-color: #fff !important;
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border: none !important;
}
.wp-playlist-item {
  padding: 10px 20px 7px !important;
  border-bottom: 1px solid #eaeaea !important;
}
.wp-playlist-tracks .wp-playlist-item:last-child {
  border-bottom: none !important;
  padding-bottom: 0 !important;
}
.wp-playlist-item-length {
  right: 20px !important;
  top: 10px !important;
}
.wp-playlist-light .wp-playlist-playing {
  background-color: transparent !important;
}
.post-video > div.wp-video {
  width: 100% !important;
}
.post-video .mejs-mediaelement {
  position: relative !important;
  height: auto !important;
}
.post-video > div,
.post-video .wp-video-shortcode {
  height: auto !important;
}

.post-body img[class*="wp-image"],
.post-body img[class*="wp-image"] {
  margin-bottom: 15px;
}

.post-body figure {
  margin-bottom: 15px;
}

.post-body figure img[class*="wp-image"],
.post-body figure img[class*="wp-image"] {
  margin-bottom: 0px;
}

fieldset {
  border: none;
}
textarea,
select[size],
select[multiple] {
  height: auto;
}
textarea {
  min-height: 100px;
  overflow: auto;
  resize: vertical;
  width: 100%;
}
optgroup {
  font-style: normal;
  font-weight: 400;
}
label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 26px;
}
input[type="radio"],
input[type="checkbox"] {
  margin: 5px 0;
  display: inline-block;
}

textarea,
select,
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"] {
  display: block;
  outline: 0;
  margin: 0 0 15px;
  text-align: left;
  vertical-align: top;
  height: 50px;
  max-width: 100%;
  width: 100%;
  padding: 0px 20px;
  line-height: 50px;
  background-color: #eeeeee;
  border: none !important;
  color: #999;
  font-size: 14px;
  position: relative;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.select2-search input {
  margin: 5px 0px !important;
  height: 25px !important;
  padding: 0px 20px !important;
  line-height: 25px !important;
}

.footer textarea,
.footer select,
.footer input[type="date"],
.footer input[type="datetime"],
.footer input[type="datetime-local"],
.footer input[type="email"],
.footer input[type="month"],
.footer input[type="number"],
.footer input[type="password"],
.footer input[type="search"],
.footer input[type="tel"],
.footer input[type="text"],
.footer input[type="time"],
.footer input[type="url"],
.footer input[type="week"] {
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

::-webkit-input-placeholder {
  color: #999999;
}
:-moz-placeholder {
  color: #999999;
}
::-moz-placeholder {
  color: #999999;
}
:-ms-input-placeholder {
  color: #999999;
}

.footer ::-webkit-input-placeholder {
  color: #c9c9c9;
}
.footer :-moz-placeholder {
  color: #c9c9c9;
}
.footer ::-moz-placeholder {
  color: #c9c9c9;
}
.footer :-ms-input-placeholder {
  color: #c9c9c9;
}

.site-widget-are ::-webkit-input-placeholder {
  color: #c9c9c9;
}
.site-widget-are :-moz-placeholder {
  color: #c9c9c9;
}
.site-widget-are ::-moz-placeholder {
  color: #c9c9c9;
}
.site-widget-are :-ms-input-placeholder {
  color: #c9c9c9;
}

.wpcf7 textarea {
  height: 200px;
  line-height: 24px;
}

textarea {
  line-height: 24px;
  padding: 20px;
}

.mob-menu li a .mi,
.header-top-menu li a .mi,
.header-menu li a .mi,
.ls .lang_sel_sel:before,
.widget_icl_lang_sel_widget .lang_sel_sel:before,
.tagline-title:after,
.nz-media-slider .flex-direction-nav a:before,
#slider-arrow:after,
.woocommerce .star-rating,
.woocommerce-page .woocommerce-product-rating .star-rating:before,
.woocommerce .comment-text .star-rating:before,
.widget_recent_reviews .star-rating:before,
.widget_top_rated_products .star-rating:before,
.woocommerce .woocommerce-product-rating .star-rating span:before,
.woocommerce-page .woocommerce-product-rating .star-rating span:before,
.woocommerce .comment-text .star-rating span:before,
.widget_recent_reviews .star-rating span:before,
.widget_top_rated_products .star-rating span:before,
.woocommerce-tabs .comment-form-rating .stars a:after,
.widget_product_search form:after,
.blog-post .post .post-meta > *:before,
.nz-breadcrumbs > .container > *:before,
.nz-breadcrumbs > .container > *:after,
.woocommerce .single-product-summary .product_meta > *:before,
.ninzio-nav-single > *:after,
.ninzio-nav-single > *:before,
.nz-tweets:before,
.widget_categories ul li a:before,
.widget_pages ul li a:before,
.widget_archive ul li a:before,
.widget_meta ul li a:before,
.owl-controls .owl-buttons div:before,
.nz-testimonials .text-icon:before {
  font-family: icomoon !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.blank-true .header,
.blank-true .footer,
.blank-true .nz-breadcrumbs {
  display: none !important;
}

body:not(.vc_editor) .lazy,
body:not(.vc_editor) .lazy-load {
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.lazy.in,
.lazy-load.in {
  opacity: 1 !important;
}

#nz-content {
  position: relative;
  z-index: 78;
  background-color: #ffffff;
}

.error404 #nz-content {
  background-color: #ffffff;
}

#gen-wrap {
  position: relative;
  left: 0;
}

#gen-wrap,
#wrap {
  height: 100%;
}

#wrap,
#wrap.full {
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  position: relative;
  background-color: #fff;
}

.error404 .widget_search input[type="text"] {
  background-color: #ffffff;
}

.error404-big {
  font-size: 100px;
  line-height: 100px;
  font-weight: 700;
  margin-bottom: 0px;
}

#wrap.nz-boxed {
  box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.3);
}

#nz-content:not(.padding-false) {
  padding: 70px 0 70px 0;
}

#nz-content.padding-true.sidebar-left,
#nz-content.padding-true.sidebar-right {
  padding: 70px 0 45px 0;
}

.main-content,
.sidebar {
  margin-bottom: 25px;
  width: 100%;
}

.page-full-width {
  width: 100% !important;
  max-width: 100% !important;
}

.blank-true .page-content-wrap {
  padding-top: 0 !important;
}

.rich-header {
  z-index: 53;
  overflow: hidden;
  width: 100%;
  height: 90px;
  background-color: #333333;
  color: #777777;
  padding: 14px 0;
  position: relative;
}

.rich-header.version1 {
  height: 100px;
  text-align: center;
}

.rich-header.version2 {
  height: 200px;
  text-align: center;
}

.rich-header .parallax-container {
  height: 150%;
  top: 0px;
  left: 0;
  width: 100%;
  z-index: -1000;
  overflow: hidden;
  max-width: none;
  position: absolute;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  background-size: cover !important;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  transform: translateY(0px);
}

.rich-header .fixed-container {
  width: 100%;
  height: 430px;
  top: 0px;
  position: fixed;
  z-index: -1;
  background-attachment: scroll;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  background-size: cover !important;
  background-position: center top;
  background-repeat: no-repeat;
}

.rich-header .container {
  display: table;
  height: 100%;
}

.rich-header .rh-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
}

.rh-separator {
  display: block !important;
  height: 10px;
  line-height: 10px;
}

.blank-true .rich-header {
  display: none;
}

.rich-header h1 {
  font-size: 20px;
  line-height: 30px;
  color: inherit;
}

.rich-header.version1 h1,
.rich-header.version2 h1 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.rich-header.version2 h1 {
  font-size: 24px;
  line-height: 34px;
  display: inline-block;
  padding: 10px 25px;
}

.nz-breadcrumbs {
  padding: 0px;
  text-align: center;
  display: inline-block;
}

.nz-breadcrumbs > *,
.nz-breadcrumbs a {
  color: inherit;
  text-transform: none;
  font-size: 13px;
  letter-spacing: 2px;
  line-height: 30px;
  padding-left: 20px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  position: relative;
  margin-bottom: 5px;
}

.nz-breadcrumbs > *:first-child {
  padding-left: 0px;
}

.nz-breadcrumbs a {
  color: inherit !important;
}

.nz-breadcrumbs > *:before {
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 15px;
  line-height: 15px;
  content: "/";
  position: absolute;
  top: 1px;
  left: 0px;
  text-align: center;
}

.nz-breadcrumbs > *:first-child:before {
  display: none;
}

.footer {
  position: relative;
  z-index: 70;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-color: #2b3034;
}

.footer .widget a {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.footer {
  width: 100%;
  position: relative;
}

.footer-info {
  background-color: #212326;
  min-height: 60px;
  font-size: 16px;
}

.footer-info .container > * {
  min-height: 60px;
  line-height: 60px;
  width: 100%;
  display: block;
  color: #979899;
}

.footer-info .container > a {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.footer-info .container > a:hover {
  color: #fff !important;
}

#top {
  text-align: center;
  cursor: pointer;
  position: fixed;
  bottom: 0px;
  right: 20px;
  width: 40px;
  height: 50px;
  border-radius: 70px 70px 0 0;

  background-repeat: no-repeat;
  background-position: 50% 50%;
  z-index: 9999999999999999999;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  opacity: 0;
}

#top.active {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.footer-info-area {
  min-height: 64px;
}

.footer-info-area > .container {
  position: relative;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-copyright {
  text-align: center;
  color: #ffffff;
  padding: 15px 0;
}

.footer-menu {
  padding: 15px 0;
}
.footer-menu ul {
  list-style: none;
  margin: 0;
}
.footer-menu ul li {
  margin: 0;
  padding: 0;
  display: block;
}

.footer-menu ul li a {
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  -webkit-transition: color 0.3s ease-out;
  transition: color 0.3s ease-out;
  color: #7a7a7a;
  position: relative;
}

.footer-menu ul li a:after {
  content: "";
  position: absolute;
  width: 0%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 2px;
  bottom: 0px;
  left: 50%;
  opacity: 0;
  -webkit-transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.footer-menu ul li a:hover:after {
  opacity: 1 !important;
  width: 40px !important;
}

.footer-menu ul li a:hover {
  color: #ffffff;
}

.nz-thumbnail {
  position: relative;
  overflow: hidden;
}

.nz-thumbnail > img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.nz-thumbnail:hover > img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.single .nz-thumbnail:hover > img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

#nz-content .nz-single-image:after,
#nz-content .nz-single-image:before {
  content: "";
}

.ninzio-overlay,
#nz-content .nz-single-image:after {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 3;
  text-align: center;
  opacity: 0;
  color: #fff !important;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.4);
}

.ninzio-overlay,
#nz-content .nz-single-image:after {
  background-color: rgba(0, 0, 0, 0.4);
}

.ninzio-overlay-content {
  width: 100%;
  padding: 20px;
  position: absolute;
  top: 49.5%;
  left: 49.5%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.nz-thumbnail:hover .ninzio-overlay,
.nz-persons .person:hover .ninzio-overlay,
a.nz-single-image:hover:before,
.gallery-item:hover .ninzio-overlay,
#nz-content .nz-single-image:hover:after {
  opacity: 1;
}

.nz-overlay-before,
#nz-content .nz-single-image:before {
  width: 60px;
  line-height: 60px;
  height: 60px;
  border-radius: 30px;
  margin: -30px 0 0 -30px;
  text-indent: -90000em;

  background-position: 48% 50%;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.7) !important;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 200ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 200ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
}

#nz-content .nz-single-image:before {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-backface-visibility: hidden;
  z-index: 5;
  opacity: 0;
}

#nz-content .nz-single-image:hover:before {
  opacity: 1;
}

.gallery-item .nz-overlay-before:before {
  display: none !important;
}

.wp-caption {
  position: relative;
  overflow: hidden;
}

.wp-caption a {
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateX(0) translateY(0);
  -ms-transform: translateX(0) translateY(0);
  transform: translateX(0) translateY(0);
}
.wp-caption .wp-caption-text {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 15px 20px;
  z-index: 5;
  color: #fff !important;
  background-color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  text-align: center;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
}

.wp-caption:hover .wp-caption-text {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.ninzio-navigation:not(:empty),
.woocommerce-pagination:not(:empty) {
  width: 100%;
  margin: 50px 0 0 0;
  text-align: center;
  position: relative;
}

.woocommerce-pagination:not(:empty) {
  padding: 0 0 0 0;
}

.load-more .woocommerce-pagination {
  display: none;
}

.ninzio-navigation ul,
.woocommerce-pagination ul {
  margin: 0;
  text-align: center;
  width: auto;
  display: inline-block;
  padding: 0 15px;
  z-index: 60;
  position: relative;
  background-color: #fff;
}

.ninzio-navigation:not(:empty):before,
.woocommerce-pagination:not(:empty):before {
  display: block;
  width: 100%;
  height: 2px;
  border-bottom: 1px dashed #eeeeee;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -1px;
}

.ninzio-navigation li,
.woocommerce-pagination li {
  display: inline-block;
  margin: 0 2px;
  padding: 0;
}

.ninzio-navigation li:last-child,
.woocommerce-pagination li:last-child {
  margin-right: 0px;
}

.ninzio-navigation li a,
.ninzio-navigation li .current,
.woocommerce-pagination li a,
.woocommerce-pagination li .current {
  display: inline-block;
  text-decoration: none;
  max-width: 100%;
  background-color: #eeeeee;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  padding: 7px 15px 7px 15px;
  cursor: pointer;
  color: #777777 !important;
  text-transform: uppercase;
  position: relative;
  border-radius: 300px;
  font-weight: 600;
}

.ninzio-navigation li a:hover,
.ninzio-navigation li .current,
.woocommerce-pagination li a:hover,
.woocommerce-pagination li .current {
  color: #fff !important;
}

.ninzio-navigation li a .icon,
.woocommerce-pagination li a .icon {
  line-height: inherit;
  -webkit-transform: translateY(2px);
  -ms-transform: translateY(2px);
  transform: translateY(2px);
  display: block;
}

.one-page-bullets {
  display: none;
  position: fixed;
  right: 30px;
  top: 51%;
  z-index: -1;
  opacity: 0;
  -webkit-transform: translateY(-51%);
  -ms-transform: translateY(-51%);
  transform: translateY(-51%);
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  width: 24px;
  padding: 16px 6px;
}

.one-page-bullets.animate {
  opacity: 1;
  z-index: 100;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 5px;
}

.one-page-bullets ul {
  list-style: none;
  margin-bottom: 0;
}

.one-page-bullets ul li {
  padding: 0;
}

.one-page-bullets a {
  display: none;
}

.one-page-bullets ul li:first-child a[href*="#"] {
  margin-top: 0px;
}

.one-page-bullets a[href*="#"] {
  display: block;
  width: 10px;
  height: 10px;
  box-shadow: inset 0 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  text-indent: 100%;
  white-space: nowrap;
  text-indent: -9000em;
  margin-top: 10px;
  margin-left: -1px;
  position: relative;
  -webkit-transform: translate(1px);
  -ms-transform: translate(1px);
  transform: translate(1px);
  outline: none;
}

.one-page-bullets a[href*="#"]:hover,
.one-page-bullets .one-page-active a[href*="#"] {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.5);
}

.one-page-bullets a[href*="#"]:after {
  display: block;
  position: absolute;
  content: attr(title);
  padding: 5px 20px 5px 20px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  position: absolute;
  top: 50%;
  left: -200%;
  line-height: 24px;
  font-weight: 600;
  display: block;
  visibility: hidden;
  width: auto;
  height: auto;
  text-indent: 0;
  opacity: 0;
  text-transform: uppercase;
  -webkit-transform: translateX(-100%) translateY(-50%);
  -ms-transform: translateX(-100%) translateY(-50%);
  transform: translateX(-100%) translateY(-50%);
  -webkit-transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
  transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
}

.one-page-bullets a[href*="#"]:hover:after,
.one-page-bullets a[href*="#"]:hover:before {
  visibility: visible;
  opacity: 1;
}

.one-page-bullets a[href*="#"]:before {
  display: block;
  position: absolute;
  content: "";
  top: 50%;
  left: -200%;
  -webkit-transform: translateX(0px) translateY(-50%);
  -ms-transform: translateX(0px) translateY(-50%);
  transform: translateX(0px) translateY(-50%);
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 4px 0 4px 5px;
  border-color: transparent transparent transparent rgba(0, 0, 0, 0.8);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
  transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
}

.site-widget-area {
  position: fixed;
  top: 0;
  right: 0px;
  width: 400px;
  height: 100%;
  overflow: hidden;
  z-index: 100;
  -webkit-transition: transform 0.4s ease;
  transition: transform 0.4s ease;
  -webkit-transform: translateX(400px);
  -ms-transform: translateX(400px);
  transform: translateX(400px);
  -webkit-backface-visibility: hidden; /* Chrome, Safari, Opera */
  backface-visibility: hidden;
}

.site-widget-area .custom-scroll-bar {
  overflow: hidden;
  height: 100%;
  padding: 80px 50px 50px 50px;
  z-index: 80;
  position: relative;
}

.site-widget-area.animated {
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

/*VC FRONT-END
/*=============*/

.vc_welcome .vc_ui-button {
  color: #fff !important;
}

.vc_container-block > .col {
  width: 100%;
}

.vc_empty-placeholder {
  display: none;
}

/*OWLCAROWSEL
/*=============*/

.owl-controls .owl-buttons {
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
}

.owl-controls .owl-buttons div {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 40px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: -40px;
  margin-top: -20px;
  z-index: 15;
  box-shadow: inset 0 0 0 2px #eee;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  opacity: 0;
}

.owl-carousel:hover .owl-buttons div {
  opacity: 1;
}

.owl-controls .owl-buttons div:hover {
  box-shadow: inset 0 0 0 40px #eee;
}

.owl-controls .owl-buttons .owl-next {
  right: -40px;
  left: auto;
}

.owl-controls .owl-buttons div:before {
  display: block;
  width: 40px;
  height: 40px;
  content: "";
  position: absolute;
  top: 0;
  left: 0;

  background-repeat: no-repeat;
  background-position: 50%;
  -webkit-transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.owl-controls .owl-buttons .owl-next:before {
  right: auto;
  left: 0px;
}

.nz-carousel {
  margin-left: -15px;
  margin-right: -15px;
}

/*SEARCH
/*=============*/

.search #nz-content {
  background-color: #ffffff;
  padding-bottom: 70px;
}

.search #nz-content > .container {
  max-width: 960px;
}

.search-results-title {
  margin-bottom: 25px;
  font-size: 18px;
  line-height: 28px;
}

.search-form {
  position: relative;
  width: 100%;
  margin: 0 auto 70px auto;
}

.search-form input[type="submit"] {
  border-radius: 0;
  white-space: nowrap;
  overflow: hidden;
  border: none;
  outline: none;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 0;
  width: 60px !important;
  height: 60px;
  text-indent: -90000em;

  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent !important;
  -webkit-transform: translateY(0px) !important;
  -ms-transform: translateY(0px) !important;
  transform: translateY(0px) !important;
  box-shadow: none;
  margin-bottom: 0px;
}

.search-form input[type="text"] {
  top: 0;
  right: 0;
  padding-right: 65px;
  height: 60px;
  line-height: 60px;
}

.search-posts > article {
  padding: 30px;
  margin-bottom: 30px;
  position: relative;
  background-color: #f7f7f7;
}

.search-posts .post-title {
  margin: 0px 0 20px 0;
  font-size: 20px;
  line-height: 30px;
}

.search-posts .post-title a {
  color: #999 !important;
}

.search-posts .post-indication {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  background-color: #fcfcfc;
}

.search-posts .post-meta {
  padding: 10px 0;
  margin-bottom: 25px;
  border-bottom: 1px solid #e0e0e0;
}

.search-posts .post-content {
  padding-bottom: 25px;
}

.suggestions {
  font-size: 18px;
  line-height: 28px;
}

/*404
/*=============*/

.error404 #nz-content {
  padding-bottom: 70px;
}

.error404-wrap {
  text-align: center;
}

.error404-title {
  padding: 20px 0 20px 0;
  color: #999999;
  text-transform: uppercase;
}

.error404-wrap .search-form {
  max-width: 400px;
  margin: 0 auto;
}

/*SLICK CAROUSELS
/*=============*/

.nz-slick-carousel {
  position: relative;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  zoom: 1;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.nz-slick-item {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
  padding-left: 50px;
  padding-right: 50px;
  -webkit-transform: translateX(0) translateY(0) scale(0.9);
  -ms-transform: translateX(0) translateY(0) scale(0.9);
  transform: translateX(0) translateY(0) scale(0.9);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.nz-slick-item.slick-center {
  -webkit-transform: translateX(0) translateY(0) scale(1);
  -ms-transform: translateX(0) translateY(0) scale(1);
  transform: translateX(0) translateY(0) scale(1);
}

.nz-slick-item img,
.nz-nz-recent-posts .post img {
  display: block;
  pointer-events: none;
}

.nz-slick-item.slick-loading img {
  display: none;
}

.nz-slick-item.dragging {
  pointer-events: none;
}

.slick-initialized .nz-slick-item {
  display: block !important;
}

.slick-loading .nz-slick-item {
  visibility: hidden;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  cursor: pointer;
  background: transparent;
  color: #e0e0e0;
  top: 50%;
  margin-top: -10px;
  padding: 0;
  border: none;
  outline: none;
  font-size: 50px;
  line-height: 50px;
  text-align: center;
  width: 50px;
  height: 50px;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.slick-next {
  left: auto;
  right: 0;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  color: #777777;
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  position: relative;
}

.slick-prev:before {
  left: -1px;
}
.slick-next:before {
  right: -1px;
}

.slick-dots {
  list-style: none;
  margin: 40px 0 0 0;
  padding: 0;
  text-align: center;
  width: 100%;
}

.slick-dots li {
  padding: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 40px;
  position: relative;
}

.slick-dots li:after {
  display: block;
  content: "";
  width: 60px;
  height: 1px;
  background-color: #eeeeee;
  position: absolute;
  top: 50%;
  right: -70px;
}

.slick-dots li:last-child:after {
  display: none;
}

.slick-dots li button {
  text-indent: -9000em;
  display: block;
  padding: 0;
  margin: 0;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #e0e0e0;
  -webkit-transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

/*HEADER
/*=============*/

.mob-menu li[class*=" icon-"]:before,
.mob-menu li[class^="icon-"]:before,
.header-menu li[class*=" icon-"]:before,
.header-menu li[class^="icon-"]:before,
.header-top li[class*=" icon-"]:before,
.header-top li[class^="icon-"]:before {
  display: none;
}

.mi {
  display: none;
  padding-right: 10px;
}

.mob-menu li[class*=" icon-"] > a .mi,
.mob-menu li[class^="icon-"] > a .mi,
.header-menu li[class*=" icon-"] > a .mi,
.header-menu li[class^="icon-"] > a .mi,
.header-top li[class*=" icon-"] > a .mi,
.header-top li[class^="icon-"] > a .mi {
  display: inline-block;
}

.mob-menu ul li > a:only-child .di,
.desk-menu ul > li > a:only-child .di {
  display: none !important;
}

.mob-menu ul li > a > .di {
  display: block;
  position: absolute;
  top: 50%;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  height: 30px;
  width: 30px;
  margin-top: -15px;
  right: 15px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.mob-menu ul li > a > .di.animate {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.header .logo {
  position: relative;
  margin: 0 auto;
  display: block;
}

.header .logo-mob {
  display: inline-block;
}

.header .logo a {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  text-align: center;
  line-height: inherit;
}

.mob-header .logo a {
  text-align: center;
}

.header .logo a img {
  margin-bottom: 0;
  margin-top: -2px;
  border: none;
  vertical-align: middle;
  width: auto;
}

.mob-header .logo a img {
  max-height: 30px !important;
}

.ls ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ls ul li {
  padding: 0;
  margin: 0;
}

.ls li a,
.ls li a:visited,
.widget_icl_lang_sel_widget li a,
.widget_icl_lang_sel_widget li a:visited {
  display: block !important;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  position: relative !important;
  height: 40px;
  z-index: 99;
  outline: none;
  padding: 0px !important;
  color: #fff;
  background-color: #303030;
  font-size: 12px !important;
  line-height: 40px !important;
}

.ls li .lang_sel_sel {
  border-top: none !important;
}

.widget_icl_lang_sel_widget li a,
.widget_icl_lang_sel_widget li a:visited {
  height: 50px;
  line-height: 50px !important;
}

.widget_icl_lang_sel_widget li a,
.widget_icl_lang_sel_widget li a:visited {
  padding: 0px !important;
}

.widget_icl_lang_sel_widget #lang_sel_click a {
  background: none !important;
}

.ls .lang_sel_sel:before,
.widget_icl_lang_sel_widget .lang_sel_sel:before {
  content: "\e915";
  display: block;
  position: absolute;
  top: 0;
  font-size: 16px !important;
  line-height: 40px;
  text-align: center;
  height: 40px;
  width: 40px;
  right: 3px;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: transform 300ms ease;
  transition: transform 300ms ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.ls.animated > div > ul > li > .lang_sel_sel:before {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.ls img,
.widget_icl_lang_sel_widget img,
#lang_sel_click .iclflag {
  vertical-align: middle;
  position: relative;
  top: -1px !important;
  margin-right: 4px;
}

#lang_sel_click .lang_sel_sel {
  position: relative;
}
#lang_sel_click li {
  float: none !important;
  width: 100% !important;
  padding: 0;
}

#lang_sel ul {
  list-style: none;
}

#lang_sel ul li {
  position: relative;
  padding: 0;
}

.ls #lang_sel ul ul,
.ls #lang_sel_click ul ul {
  left: auto;
  height: auto;
  z-index: 98;
  margin: 0;
}

.widget_icl_lang_sel_widget #lang_sel ul ul,
.widget_icl_lang_sel_widget #lang_sel_click ul ul {
  position: relative;
  top: 0px;
  right: 0;
  display: none;
  left: auto;
  border-top: none;
  height: auto;
  visibility: visible;
  z-index: 99;
  margin: 0 !important;
  padding-top: 0px !important;
}

.no-js #lang_sel ul li:hover ul {
  display: block;
}

.ls .lang_sel_sel,
.widget_icl_lang_sel_widget .lang_sel_sel {
  padding-right: 20px !important;
  position: relative;
}

.ls > div > ul > li .lang_sel_sel:only-child {
  padding-right: 10px !important;
}

.ls > div > ul > li .lang_sel_sel:only-child:before,
.widget_icl_lang_sel_widget > div > ul > li .lang_sel_sel:only-child:before {
  display: none;
}
#lang_sel_click li:before {
  display: none !important;
}

.ls .lang_sel_list_horizontal li,
.ls .lang_sel_list_vertical li {
  float: left !important;
}

.ls .lang_sel_list_vertical .lang_sel_sel:before,
.ls .lang_sel_list_horizontal .lang_sel_sel:before {
  display: none;
}

.ls .lang_sel_list_vertical .lang_sel_sel,
.ls .lang_sel_list_horizontal .lang_sel_sel {
  padding-right: 10px !important;
}

.mob-header {
  background-color: #ffffff;
  z-index: 98;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11);
}

.mob-header-top {
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: #ffffff;
  text-align: center;
}

.mob-header-top > .container {
  height: inherit;
}

.mob-header-top .mob-menu-toggle,
.mob-sidebar-toggle2,
.mob-sidebar-toggle {
  height: 30px;
  width: 30px;
  border-radius: 30px;
  position: absolute;
  top: 10px;
  right: 0px;
  display: block;
  cursor: pointer;

  background-repeat: no-repeat;
  background-position: 48% center;
}

.mob-header-top .mob-menu-toggle.animated,
.mob-sidebar-toggle2.animated,
.mob-sidebar-toggle.animated {
}

.mob-sidebar-toggle {
  right: 0px;
  left: auto;
}

.mob-sidebar-toggle2 {
  right: 15px;
  top: 15px;
  left: auto;
  z-index: 81;
}

.mob-header-content .cart-toggle {
  width: 100%;
  height: 55px;
  display: block;
  cursor: pointer;
  position: relative;
  background-repeat: no-repeat;
  background-position: 15px center;

  border-bottom: 1px solid #f9f9f9;
}

.mob-header-content .cart-toggle a {
  display: block;
  height: inherit;
  width: inherit;
  outline: none;
}

.mob-header-content {
  padding: 0px 0 25px 0px;
  background-color: #ffffff;
  display: none;
}

.mob-header-content .slogan {
  color: #999;
  padding-top: 15px;
  margin-bottom: 25px;
}

.mob-header-content .top-button {
  padding: 10px 15px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  outline: none;
  border: none;
  color: #999;
  background-color: #f5f5f5;
  position: relative;
  display: block;
  border-radius: 3px;
  margin-bottom: 25px;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  z-index: 1;
  text-align: center;
  text-transform: uppercase;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.mob-header-content .top-button:hover {
  background-color: #f0f0f0;
}

.site-overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  z-index: 98;
}

.site-overlay.animated {
  visibility: visible;
  opacity: 1;
}

.mob-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mob-menu li[class*=" icon-"]:before,
.mob-menu li[class^="icon-"]:before,
.header-menu li[class*=" icon-"]:before,
.header-menu li[class^="icon-"]:before {
  display: none;
}

.mob-menu li {
  position: relative;
  padding: 0px;
}

.mob-menu li a {
  display: block !important;
  position: relative !important;
  padding: 15px 25px 15px 0px;
  color: #999;
  border-bottom: 1px solid #f7f7f7;
  outline: none;
  font-size: 14px !important;
  line-height: 24px !important;
  text-transform: uppercase;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.mob-menu li.menu-item-language img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  position: relative;
  top: -2px;
}

.mob-menu ul ul {
  display: none;
}

.mob-menu ul li > a > .di {
  display: block;
  position: absolute;
  top: 50%;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  height: 30px;
  width: 30px;
  margin-top: -15px;
  right: 0px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.mob-menu ul li > a > .di.animate {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mob-header-content .search {
  padding-top: 25px;
}

.mob-header-content input[type="text"] {
  margin-bottom: 0;
}

.mob-header-content .icon-cross {
  display: none;
}

.mob-header-content .search input[type="submit"] {
  width: 50px !important;
  height: 50px;
  background-color: transparent !important;
}

.mob-header .ls li a,
.mob-header .ls li a:visited {
  height: 65px;
  line-height: 65px !important;
  padding: 0px 25px !important;
  background-color: transparent;
  border-top: 1px solid #555555;
  text-transform: uppercase;
  font-size: 14px !important;
}

.mob-header .ls {
  border-bottom: 1px solid #555555;
}

.mob-header .ls .lang_sel_sel:before {
  line-height: 65px;
  height: 65px;
  width: 65px;
  right: 15px;
  display: block;
  position: absolute;
  top: 50%;
  font-size: 18px !important;
  line-height: 30px;
  text-align: center;
  height: 30px;
  width: 30px;
  margin-top: -15px;
  right: 15px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.mob-header .ls #lang_sel ul ul,
.mob-header .ls #lang_sel_click ul ul {
  display: none;
}

.mob-header .cart-toggle .cart-info {
  color: #999;
  position: absolute;
  line-height: 20px;
  font-size: 14px;
  height: 20px;
  width: 20px;
  display: inline-block;
  border-radius: 20px;
  text-align: center;
  top: 19px;
  left: 34px;
}

.mob-header-content .social-links {
  padding: 20px 0;
  height: auto;
  line-height: 36px;
}

.mob-header-content .social-text {
  vertical-align: top;
  text-transform: uppercase;
  padding-right: 10px;
}

/* .desk {
  display: none;
} */

.mob-header .label {
  margin-left: 15px;
  display: inline-block;
  padding: 6px 10px 6px 10px;
  color: #ffffff;
  text-transform: none;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  position: relative;
  top: -1px;
  left: 0px !important;
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
}

.mob-header-content .search {
  padding-top: 25px;
  border-top: 1px solid #f7f7f7;
}

.mob-header-content .search form,
.mob-header-content .search fieldset {
  margin: 0;
  position: relative;
}

.mob-header-content .search input[type="submit"] {
  width: 50px !important;
  height: 50px;
  text-indent: -9000em;
  position: absolute;
  right: 0px;
  top: 0;
  border: none;
  outline: none;
  padding: 0;
  margin-bottom: 0px !important;

  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: none;
}

.cart-info-2 {
  display: none;
}

/*GRID
/*=============*/

.container,
.full-width-true .nz-tabs.full-true .tab-content,
.ninzio-navigation,
.wpml-ls-statics-post_translations {
  width: 92%;
  max-width: 92%;
  margin: 0 auto 0 auto;
  position: relative;
}

.nz-row:before,
.nz-row:after {
  content: " ";
  display: table;
}

.nz-row:after {
  clear: both;
}

.col12,
.col11,
.col10,
.col9,
.col8,
.col7,
.col6,
.col5,
.col4,
.col3,
.col2,
.col1 {
  display: block;
  position: relative;
  margin-bottom: 25px;
  background-repeat: no-repeat;
  min-height: 1px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.col[data-margin="false"] {
  margin-bottom: 0;
}
.col[data-align="left"] {
  text-align: left;
}
.col[data-align="right"] {
  text-align: right;
}
.col[data-align="center"] {
  text-align: center;
}

/*COLUMNS
/*=============*/

.css-animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.css-animated.flipOutX {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
}
.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
}
.flipOutX {
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

.element-animate-true {
  -webkit-backface-visibility: hidden;
}

.element-animate-true[data-effect="fade"] {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  opacity: 0;
}

.css-animated.element-animate-true[data-effect="fade"] {
  opacity: 1;
}

.element-animate-true[data-effect="fade-bottom"] {
  opacity: 0;
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
}

.element-animate-true[data-effect="fade-top"] {
  opacity: 0;
  -webkit-transform: translateY(-50px);
  -ms-transform: translateY(-50px);
  transform: translateY(-50px);
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
}

.css-animated.element-animate-true[data-effect="fade-top"],
.css-animated.element-animate-true[data-effect="fade-bottom"] {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.element-animate-true[data-effect="fade-left"],
.element-animate-true[data-effect="fade-left-bounce"] {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  opacity: 0;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.element-animate-true[data-effect="ghost-left"],
.element-animate-true[data-effect="ghost-left-bounce"] {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  opacity: 0;
  -webkit-transform: translateX(-100px);
  -ms-transform: translateX(-100px);
  transform: translateX(-100px);
}

.element-animate-true[data-effect="fade-right"],
.element-animate-true[data-effect="fade-right-bounce"] {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  opacity: 0;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

.element-animate-true[data-effect="ghost-right"],
.element-animate-true[data-effect="ghost-right-bounce"] {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  opacity: 0;
  -webkit-transform: translateX(100px);
  -ms-transform: translateX(100px);
  transform: translateX(100px);
}

.element-animate-true[data-effect="fade-left-bounce"],
.element-animate-true[data-effect="ghost-left-bounce"],
.element-animate-true[data-effect="fade-right-bounce"],
.element-animate-true[data-effect="ghost-right-bounce"] {
  -webkit-transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.2s;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.2s;
}

.element-animate-true.css-animated[data-effect="fade-left"],
.element-animate-true.css-animated[data-effect="fade-right"],
.element-animate-true.css-animated[data-effect="ghost-left"],
.element-animate-true.css-animated[data-effect="ghost-right"],
.element-animate-true.css-animated[data-effect="fade-left-bounce"],
.element-animate-true.css-animated[data-effect="ghost-left-bounce"],
.element-animate-true.css-animated[data-effect="fade-right-bounce"],
.element-animate-true.css-animated[data-effect="ghost-right-bounce"] {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

.element-animate-true[data-effect="scale"] {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  opacity: 0;
  -webkit-transform: scale(0.2);
  -ms-transform: scale(0.2);
  transform: scale(0.2);
}

.css-animated.element-animate-true[data-effect="scale"] {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.element-animate-true[data-effect="scale-down"] {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  opacity: 0;
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.css-animated.element-animate-true[data-effect="scale-down"] {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.element-animate-true[data-effect="flip"] {
  opacity: 0;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

.css-animated.element-animate-true[data-effect="flip"] {
  opacity: 1;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
}

@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(1200px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(1200px) rotate3d(0, 1, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
  40% {
    -webkit-transform: perspective(1200px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(1200px) rotate3d(0, 1, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(1200px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(1200px) rotate3d(0, 1, 0, 10deg);
  }
  80% {
    -webkit-transform: perspective(1200px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(1200px) rotate3d(0, 1, 0, -5deg);
  }
  100% {
    -webkit-transform: perspective(1200px);
    transform: perspective(1200px);
  }
}

@keyframes flipInY {
  0% {
    -webkit-transform: perspective(1200px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(1200px) rotate3d(0, 1, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
  40% {
    -webkit-transform: perspective(1200px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(1200px) rotate3d(0, 1, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(1200px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(1200px) rotate3d(0, 1, 0, 10deg);
  }
  80% {
    -webkit-transform: perspective(1200px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(1200px) rotate3d(0, 1, 0, -5deg);
  }
  100% {
    -webkit-transform: perspective(1200px);
    transform: perspective(1200px);
  }
}

@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(1200px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(1200px) rotate3d(1, 0, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
  40% {
    -webkit-transform: perspective(1200px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(1200px) rotate3d(1, 0, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(1200px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(1200px) rotate3d(1, 0, 0, 10deg);
  }
  80% {
    -webkit-transform: perspective(1200px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(1200px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    -webkit-transform: perspective(1200px);
    transform: perspective(1200px);
  }
}

@keyframes flipInX {
  0% {
    -webkit-transform: perspective(1200px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(1200px) rotate3d(1, 0, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
  40% {
    -webkit-transform: perspective(1200px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(1200px) rotate3d(1, 0, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(1200px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(1200px) rotate3d(1, 0, 0, 10deg);
  }
  80% {
    -webkit-transform: perspective(1200px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(1200px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    -webkit-transform: perspective(1200px);
    transform: perspective(1200px);
  }
}

@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

@keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

/*ANIMATION
/*=============*/

.nz-clients.animate-scale .owl-item,
.nz-persons.animate-scale .owl-item,
.owl-carousel.animate-scale .owl-item,
.nz-recent-posts.animate-scale .owl-item,
.nz-gallery.carousel.animate-scale .owl-item,
.nz-gallery.grid.animate-scale .element-animate,
.nz-content-box.animate-scale .element-animate,
.nz-pricing-table.animate-scale .element-animate {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  opacity: 0;
  -webkit-transform: scale(0.2);
  -ms-transform: scale(0.2);
  transform: scale(0.2);
}

.nz-clients.animate-scale .owl-item.css-animated,
.nz-persons.animate-scale .owl-item.css-animated,
.owl-carousel.animate-scale .owl-item.css-animated,
.nz-recent-posts.animate-scale .owl-item.css-animated,
.nz-gallery.carousel.animate-scale .owl-item.css-animated,
.nz-gallery.grid.animate-scale .element-animate.css-animated,
.nz-content-box.animate-scale .element-animate.css-animated,
.nz-pricing-table.animate-scale .element-animate.css-animated {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.nz-clients.animate-fade .owl-item,
.nz-persons.animate-fade .owl-item,
.owl-carousel.animate-fade .owl-item,
.nz-recent-posts.animate-fade .owl-item,
.nz-gallery.carousel.animate-fade .owl-item,
.nz-gallery.grid.animate-fade .element-animate,
.nz-content-box.animate-fade .element-animate,
.nz-pricing-table.animate-fade .element-animate {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  opacity: 0 !important;
  -webkit-backface-visibility: hidden;
}

.nz-clients.animate-fade .owl-item.css-animated,
.nz-persons.animate-fade .owl-item.css-animated,
.owl-carousel.animate-fade .owl-item.css-animated,
.nz-recent-posts.animate-fade .owl-item.css-animated,
.nz-gallery.carousel.animate-fade .owl-item.css-animated,
.nz-gallery.grid.animate-fade .element-animate.css-animated,
.nz-content-box.animate-fade .element-animate.css-animated,
.nz-pricing-table.animate-fade .element-animate.css-animated {
  opacity: 1 !important;
}

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 30%, 0);
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, 30%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-30%, 0, 0);
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-30%, 0, 0);
  }
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-30%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-30%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(30%, 0, 0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(30%, 0, 0);
  }
}

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(30%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(30%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeOutTop {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -30%, 0);
  }
}

@keyframes fadeOutTop {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -30%, 0);
  }
}

.fadeOutTop {
  -webkit-animation-name: fadeOutTop;
  animation-name: fadeOutTop;
}

@-webkit-keyframes fadeInTop {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -30%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
  }
}

@keyframes fadeInTop {
  from {
    opacity: 0;
    transform: translate3d(0, -30%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInTop {
  -webkit-animation-name: fadeInTop;
  animation-name: fadeInTop;
}

/*GALLERY
/*=============*/

.nz-gallery {
  margin-left: -5px;
  margin-right: -5px;
}

.nz-gallery .gallery-item .ninzio-overlay {
  width: calc(100% - 10px);
  left: 5px;
}

.nz-gallery .gallery-item .wp-caption .ninzio-overlay {
  width: 100%;
  left: 0px;
}

.nz-gallery img {
  margin: 0 auto;
  display: block;
}

.nz-gallery .gallery-item {
  display: block;
  margin: 0 auto 10px auto;
  padding-right: 5px;
  padding-left: 5px;
  position: relative;
}

.nz-gallery.grid .gallery-item {
  float: left;
}

.nz-gallery a {
  text-decoration: none;
  display: block;
  position: relative;
}

.nz-gallery[data-columns="1"]:not(.carousel) .gallery-item {
  width: 100%;
}
.nz-gallery[data-columns="2"]:not(.carousel) .gallery-item {
  width: 50%;
}
.nz-gallery[data-columns="3"]:not(.carousel) .gallery-item {
  width: 33.3%;
}
.nz-gallery[data-columns="4"]:not(.carousel) .gallery-item {
  width: 50%;
}
.nz-gallery[data-columns="5"]:not(.carousel) .gallery-item {
  width: 33.3%;
}
.nz-gallery[data-columns="6"]:not(.carousel) .gallery-item {
  width: 33.3%;
}
.nz-gallery[data-columns="7"]:not(.carousel) .gallery-item {
  width: 33.3%;
}
.nz-gallery[data-columns="8"]:not(.carousel) .gallery-item {
  width: 50%;
}
.nz-gallery[data-columns="9"]:not(.carousel) .gallery-item {
  width: 33.3%;
}

.nz-gallery .wp-caption-text {
  display: none;
}

.gallery-caption {
  width: 100%;
}

/*CAROUSELS
/*=============*/

.owl-carousel .owl-wrapper:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel {
  display: none;
  position: relative;
  -ms-touch-action: pan-y;
  opacity: 0;
}

.owl-carousel .owl-wrapper {
  display: none;
  position: relative;
  -webkit-transform: translateX(0) translateY(0);
  -ms-transform: translateX(0) translateY(0);
  transform: translateX(0) translateY(0);
}

.owl-carousel .owl-wrapper-outer {
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 5;
}

.owl-carousel .owl-wrapper-outer.autoHeight {
  -webkit-transition: height 500ms ease;
  transition: height 500ms ease;
}

.owl-carousel .owl-item {
  float: left;
}
.owl-controls .owl-page,
.owl-controls .owl-buttons div {
  cursor: pointer;
}

.owl-pagination {
  text-align: center;
  padding: 0;
}

.owl-controls {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 2;
}

.owl-controls .owl-page {
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #eeeeee;
  border-radius: 10px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  margin: 0 10px;
}

.owl-controls .owl-page.active {
  -webkit-transform: scale(2) !important;
  transform: scale(2) !important;
}

.owl-controls .owl-page:last-child {
  margin-right: 0px;
}

.grabbing {
}

.owl-carousel .item {
  padding-right: 15px;
  padding-left: 15px;
  text-align: center;
}

/*SECTION
/*=============*/

.nz-section {
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 0;
  margin: 0 auto;
  position: relative;
  width: 100%;
  z-index: 1;
  overflow: hidden;
}

.nz-section.animate-true {
  background-position: 0 0;
  background-repeat: repeat !important;
  -webkit-background-size: auto !important;
  -moz-background-size: auto !important;
  background-size: auto !important;
}

.nz-section-back-video {
  position: absolute;
  top: -5px;
  right: 0px;
  min-width: 100%;
  width: 100%;
  height: auto;
  z-index: -1000;
  overflow: hidden;
  display: none;
}

.nz-video-overlay {
  position: absolute;
  top: 0px;
  right: 0px;
  background-position: left top;
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  display: none;
}

.nz-video-poster {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  background-size: cover !important;
  position: absolute;
  background-position: center top;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  position: absolute;
}

.nz-section .parallax-container,
.footer .parallax-container {
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  height: 150%;
  z-index: -1000;
  overflow: hidden;
  max-width: none;
  position: absolute;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  background-size: cover !important;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.nz-section .fixed-container {
  top: -70px;
  left: 0;
  min-height: 100%;
  min-height: 100vh;
  height: 100%;
  height: 100vh;
  width: 100%;
  z-index: -1;
  overflow: hidden;
  max-width: none;
  position: absolute;
  background-attachment: fixed;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  background-size: cover !important;
  background-position: center top;
  background-repeat: no-repeat;
}

.col.back-img {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.nz-section.autoheight-true .col-inner {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  background-size: cover !important;
  background-repeat: no-repeat;
}

.main-content .nz-section .container {
  width: 100% !important;
}

/*WIDGETS
/*=============*/

.footer-widget-area a:not(.button):not(.ui-slider-handle),
.sidebar a:not(.button):not(.ui-slider-handle) {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.widget,
.yawp_wim_widget {
  padding-bottom: 40px;
  margin-bottom: 35px;
}

.sidebar .widget:last-child {
  border-bottom: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.footer .widget {
  padding-bottom: 30px;
  margin-bottom: 0px;
  border-bottom: none;
}

.widget_title,
.yawp_wim_title {
  margin-bottom: 25px;
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  position: relative;
  text-transform: uppercase;
}

/*	Tag cloud
/*-------------------*/

.widget_tag_cloud .tagcloud a,
.post-tags a,
.widget_product_tag_cloud .tagcloud a,
.projects-tags a {
  text-decoration: none;
  display: inline-block;
  padding: 3px 12px 3px 12px;
  margin: 0 0 3px 0;
  color: #999999;
  background-color: #ffffff;
  font-size: 13px !important;
  text-transform: uppercase !important;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-backface-visibility: hidden;
  position: relative;
  text-transform: none !important;
}

/*	Search
/*-------------------*/

.widget_search form,
.woocommerce-product-search {
  position: relative;
  margin-bottom: 0;
  border: none;
}

.widget_search form fieldset,
.woocommerce-product-search fieldset {
  max-width: 100%;
  margin-bottom: 0;
  border: none;
}

.widget_search input[type="text"],
.widget_product_search input[type="search"],
.woocommerce-product-search input[type="search"] {
  height: 50px;
  font-size: inherit;
  margin-bottom: 0px;
}

.widget_search input[type="submit"],
.widget_product_search input[type="submit"],
.woocommerce-product-search input[type="submit"] {
  text-indent: -2000%;
  white-space: nowrap;
  overflow: hidden;
  border: none;
  outline: none;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 0;
  margin: 0;
  box-shadow: none !important;
  width: 50px !important;
  height: 50px;
  text-indent: -9000em;

  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent !important;
  z-index: 5;
  -webkit-transform: translateY(0px) !important;
  -ms-transform: translateY(0px) !important;
  transform: translateY(0px) !important;
}

/*	Categories, Pages, Archive, Meta
/*-------------------*/

.widget_categories ul,
.widget_pages ul,
.widget_archive ul,
.widget_meta ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.widget_categories ul li,
.widget_pages ul li,
.widget_archive ul li,
.widget_meta ul li {
  padding: 10px 0 !important;
}

.widget_categories ul li:last-child,
.widget_pages ul li:last-child,
.widget_archive ul li:last-child,
.widget_meta ul li:last-child {
  border-bottom: none;
}

.widget_categories ul li a,
.widget_pages ul li a,
.widget_archive ul li a,
.widget_meta ul li a {
  padding: 0 0 0 15px !important;
  position: relative;
  display: inline-block !important;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  text-transform: none !important;
  font-size: 15px !important;
}

.widget_categories select,
.widget_archive select {
  width: 100%;
  padding-right: 5px;
}

/*	Custom menu
/*-------------------*/

.widget_nav_menu ul,
.widget_product_categories ul {
  list-style: none;
  margin: 0;
  padding: 0px;
  position: relative;
}

.widget_nav_menu li,
.widget_product_categories li {
  padding: 2px 0 2px 0;
  position: relative;
}

.widget_nav_menu li a:before,
.widget_product_categories li a:before,
.widget_categories ul li a:before,
.widget_pages ul li a:before,
.widget_archive ul li a:before,
.widget_meta ul li a:before {
  content: "" !important;
  display: block !important;
  height: 4px !important;
  width: 4px;
  left: 0px !important;
  top: 50%;
  margin-top: -2px;
  -webkit-transform: none !important;
  -moz-transform: none !important;
  transform: none !important;
  opacity: 1;
  -webkit-transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.widget_nav_menu li,
.widget_product_categories li,
.widget_categories ul li,
.widget_pages ul li,
.widget_archive ul li,
.widget_meta ul li {
  border-bottom: 1px dashed #eeeeee;
}

.widget_nav_menu li:last-child,
.widget_product_categories li:last-child,
.widget_categories ul li:last-child,
.widget_pages ul li:last-child,
.widget_archive ul li:last-child,
.widget_meta ul li:last-child {
  border-bottom: none !important;
}

.widget_product_categories ul li span.count {
  display: inline-block;
  margin-left: 5px;
}

.widget_nav_menu ul ul,
.widget_product_categories ul ul {
  display: none;
}

.widget_nav_menu li[class*=" icon-"]:before,
.widget_nav_menu li[class^="icon-"]:before {
  display: none;
}

.widget_product_categories ul li a,
.widget_nav_menu ul li a {
  display: block !important;
  position: relative !important;
  padding: 5px 0 5px 15px;
  line-height: 28px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  outline: none;
  color: #999999;
  font-size: 18px;
}

.desk-menu > ul > [data-mm="true"] .widget_product_categories ul li a,
.desk-menu > ul > [data-mm="true"] .widget_nav_menu ul li a {
  padding: 5px 0 5px 15px !important;
}

.widget_nav_menu ul li a {
  text-transform: uppercase;
  padding: 5px 0;
}

.widget_nav_menu ul li a:before {
  display: none !important;
}

.widget_product_categories ul ul li a,
.widget_nav_menu ul ul li a {
  text-transform: none;
}

.widget_nav_menu ul li a img {
  vertical-align: middle;
  margin-right: 5px;
  -webkit-transform: translateY(-1px);
  -moz-transform: translateY(-1px);
  transform: translateY(-1px);
}

.widget_product_categories ul.children > li > a,
.widget_nav_menu ul.sub-menu > li > a {
  position: relative;
}

.widget_nav_menu ul li > a > span.toggle,
.widget_product_categories ul li > a > span.toggle {
  display: block;
  position: absolute;
  top: 50%;
  text-align: center;
  line-height: 30px;
  height: 30px;
  width: 30px;
  margin-top: -15px;
  right: 0;
  cursor: pointer;
  font-size: 16px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  color: #ccc;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.widget_nav_menu ul li > a.animate > span.toggle,
.widget_product_categories ul li > a.animate > span.toggle {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.widget_nav_menu ul li > a:only-child > span.toggle,
.widget_product_categories ul li > a:only-child > span.toggle {
  display: none !important;
}

/*	Calendar
/*-------------------*/

.widget_calendar {
  width: 100%;
}

.widget_calendar table {
  margin: 0;
  padding: 0;
  width: 100%;
  border: none !important;
  background-color: #fff;
  position: relative;
  border-spacing: 0;
  border-collapse: collapse;
  vertical-align: middle;
}

.widget_calendar caption {
  margin: 0 auto;
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
  background-color: #fff;
  width: 100% !important;
}

.widget_calendar th {
  font-weight: normal;
  text-align: center;
  padding: 0;
}

.widget_calendar th:first-child {
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #e0e0e0;
}

.widget_calendar th:last-child {
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #e0e0e0;
}

.widget_calendar td {
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
  text-align: center;
  text-decoration: none;
  padding: 0;
}

.widget_calendar td a {
  display: block;
  text-decoration: none;
  width: 100%;
  height: 100%;
}

.widget_calendar td#today {
  color: #fff;
  font-weight: 700;
}

.widget_calendar tfoot td.pad {
  background-color: transparent;
  border: none;
  line-height: 0;
  height: 0;
  width: 0;
}

.widget_calendar td#prev,
.widget_calendar td#next {
  position: absolute;
  top: 0;
  left: 0px;
  z-index: 5;
  padding-top: 0;
  padding-bottom: 0;
  padding: 0;
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
}

.widget_calendar td#prev {
  border-left: none;
  left: 1px;
}

.widget_calendar td#next {
  left: auto;
  right: 1px;
  border-right: none;
}

.widget_calendar td#prev a,
.widget_calendar td#next a {
  border: none;
  outline: none;
  width: 100%;
  height: inherit;
  margin: 0 auto;
  display: block;
  padding: 0;
}

.widget_calendar td#prev span,
.widget_calendar td#next span {
  display: block;
  height: 100%;
  width: 100%;
  text-align: center;
}

/*	Rss, Recent posts, Recent comments
/*-------------------*/

.widget_rss .widget_title a {
  display: inline;
  display: inline-block;
  text-decoration: none;
}

.widget_rss img {
  margin: 0;
  vertical-align: middle;
}

.widget_rss .widget_title a.rsswidget:first-child {
  width: 14px;
  height: 14px;
}

.widget_rss ul,
.widget_nz_recent_entries ul,
.widget_recent_comments ul,
.widget_recent_entries ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer .widget_rss ul,
.footer .widget_nz_recent_entries ul,
.footer .widget_recent_comments ul,
.footer .widget_recent_entries ul {
  background-color: transparent;
}

.widget_rss ul li,
.widget_recent_comments ul li,
.widget_recent_entries ul li {
  margin: 0;
  padding: 7px 0px 7px 0px !important;
  position: relative;
  display: block;
}

.widget_rss a,
.widget_nz_recent_entries a,
.widget_recent_comments a,
.widget_recent_entries a {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-transform: none !important;
  font-size: 15px !important;
  line-height: 25px !important;
}

.widget_rss a:before,
.widget_nz_recent_entries a:before,
.widget_recent_comments a:before,
.widget_recent_entries a:before {
  display: none !important;
}

.widget_recent_entries ul li:last-child,
.widget_recent_comments ul li:last-child,
.widget_rss ul li:last-child {
  border-bottom: none;
}

.widget_rss ul li:last-child,
.widget_nz_recent_entries ul li:last-child,
.widget_recent_entries ul li:last-child {
  margin-bottom: 0;
}

.widget_recent_entries ul li > * {
  display: block;
}

.widget_recent_entries .post-date {
  text-transform: none !important;
}

.widget_nz_recent_entries img {
  -webkit-transform: scale(1) !important;
  -ms-transform: scale(1) !important;
  transform: scale(1) !important;
}

.widget_recent_comments ul li {
  padding-left: 70px !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  padding-right: 0 !important;
  background-color: transparent !important;
  top: 0px;
  margin-bottom: 20px;
  position: relative;
  border-bottom: none !important;
  box-shadow: none !important;
  min-height: 64px;
}

.widget_recent_comments ul li:before {
  display: block;
  content: "";
  font-size: 18px;
  position: absolute;
  top: 6px;
  left: 0;
  width: 50px;
  height: 58px;
  border-radius: 0px;
  line-height: 58px;
  text-align: center;

  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.footer .widget_nz_recent_entries a:hover {
  color: #ffffff !important;
}

/*	Flickr
/*-------------------*/

.flickr_badge_image {
  float: left;
  position: relative;
}

.flickr_badge_image img {
  margin-bottom: 0;
}

.flickr_badge_image a {
  display: block;
  width: 100%;
  height: 100%;
}

.photos_from_flickr img {
  width: 100%;
}

.photos_from_flickr {
  margin-right: -2px;
  margin-left: -2px;
}

.photos_from_flickr .flickr_badge_image {
  width: 74px;
  margin: 0px;
  height: auto;
  padding-right: 2px;
  padding-left: 2px;
  padding-bottom: 2px;
}

/*	Twitter
/*-------------------*/

.widget_twitter ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.widget_twitter ul li {
  padding: 0px 0 10px 70px !important;
  position: relative;
  min-height: 70px;
}

.widget_twitter ul li:before {
  display: block;
  content: "";
  font-size: 18px;
  position: absolute;
  top: 16px;
  left: 0;
  width: 50px;
  height: 58px;
  border-radius: 0px;
  line-height: 58px;
  text-align: center;
}

.widget_twitter ul li:before {
  content: "";
  width: 50px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  left: 0px;
  top: 5px;
  z-index: 15;
  color: #878889;

  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.wiversion-light .widget_twitter ul li:before,
.footer-widget-area .widget_twitter ul li:before {
}

.widget_twitter ul li:last-child {
  border-bottom: none;
}

.widget_twitter ul li a {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.widget_twitter ul li a:before {
  display: none !important;
}

.widget_twitter ul li p {
  margin-bottom: 0;
  font-size: 13px;
}

.widget_twitter ul li a.tweet-author {
  font-weight: bold;
  padding: 0px !important;
}
.widget_twitter ul li a.tweet-time {
  margin-top: 3px;
  display: block;
}

.widget_twitter ul li a.tweet-time {
  color: #999;
  text-transform: uppercase !important;
  font-size: 12px !important;
}

/*	Facebook
/*-------------------*/

.widget_facebook .fb-like-box,
.widget_facebook .fb-like-box span,
.widget_facebook .fb-like-box span iframe {
  width: 100% !important;
  max-width: 100% !important;
  border-bottom-color: #e0e0e0;
}

/*  Front-end login
/*-------------------*/

.widget_reglog #registration-form,
.widget_reglog #password-form {
  display: none;
}

#loginform p {
  margin-bottom: 0;
}
#loginform .login-submit {
  padding-top: 5px;
}

#loginform .login-submit input[type="submit"] {
  padding: 4px 15px;
  font-size: 12px;
  line-height: 22px;
}

/*  Mailchimp
/*-------------------*/

.widget_mailchimp .mailchimp-subtitle:not(:empty) {
  padding-bottom: 25px;
}

.widget_mailchimp .mailchimp-description:not(:empty) {
  padding-top: 15px;
}

.widget_mailchimp form,
.nz-mailchimp form {
  margin-bottom: 0;
  position: relative;
}

#mc-embedded-subscribe {
  height: 40px;
  line-height: 40px;
  padding: 0 35px;
  font-size: 13px;
  border-radius: 0px;
}

#mce-EMAIL {
  font-size: inherit;
  padding-left: 60px;
  height: 50px;
  text-transform: none;

  background-repeat: no-repeat;
  background-position: 18px 50%;
}

.wiversion-light #mce-EMAIL {
}

.nz-mailchimp #mce-EMAIL {
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #e0e0e0;
}

/*  Recent entries
/*-------------------*/

.widget_nz_recent_entries li.post {
  position: relative;
  padding: 10px 0;
  min-height: 55px;
}

.widget_nz_recent_entries ul {
  margin-top: -6px;
}

.widget_nz_recent_entries li.post:first-child {
  padding-top: 0;
}

.widget_nz_recent_entries .post .post-date {
  display: block;
  padding: 0;
  text-transform: none !important;
}

.widget_nz_recent_entries .post .post-date-custom > span:first-child {
  font-size: 18px;
  line-height: 35px;
  height: 35px;
  display: block;
}

.widget_nz_recent_entries .post .post-date-custom > span:last-child {
  font-size: 11px;
  line-height: 18px;
  height: 20px;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
  background-color: #f4f9ea;
  color: #999;
}

.widget .widget-post-category a:not(.button),
.widget .widget-post-category {
  color: #818284 !important;
  font-style: italic;
}

.footer .widget .widget-post-category a:not(.button):hover {
  color: #fff !important;
}

.widget_nz_recent_entries .widget-post-date {
  color: #ffffff !important;
  font-weight: 700;
  font-size: 12px;
  text-transform: none !important;
}

.widget_nz_recent_entries .post-title {
  margin-bottom: 5px;
  display: block;
  font-size: 14px;
  line-height: 24px;
  position: relative;
  text-transform: none !important;
}

.footer .widget_nz_recent_entries .post-title {
  color: #c4c5c4 !important;
}

.widget_nz_recent_entries .post-body {
  padding-left: 80px !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  padding-right: 0 !important;
  background-color: transparent !important;
  top: 0px;
  margin-bottom: 20px;
  position: relative;
  border-bottom: none !important;
  box-shadow: none !important;
}

.widget_nz_recent_entries ul li:last-child .post-body {
  margin-bottom: 0px;
}

.widget_nz_recent_entries .post-body .nz-thumbnail {
  display: block;
  position: absolute;
  top: 6px;
  left: 0;
  width: 60px;
  height: 60px;
}

/*  Recent projects
/*-------------------*/

.widget_recent_projects .recent-projects {
  margin-right: -2px;
  margin-left: -2px;
}

.widget_recent_projects .recent-projects > .post {
  width: 33.3333333%;
  padding-right: 2px;
  padding-left: 2px;
  padding-bottom: 4px;
  float: left;
  position: relative;
}

.widget_recent_projects .recent-projects > .post a {
  display: block;
  overflow: hidden;
  position: relative;
}

.widget_recent_projects .nz-thumbnail > img {
  -webkit-transform: scale(1) !important;
  -ms-transform: scale(1) !important;
  transform: scale(1) !important;
}

/*  Schedule
/*-------------------*/

.nz-schedule {
  padding: 0px;
}

.nz-schedule ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer .nz-schedule ul {
  background-color: transparent;
  padding: 0px;
}

.nz-schedule li {
  padding: 10px;
  font-size: 14px;
  color: #777;
}

.footer .nz-schedule li {
  border-bottom-color: rgba(255, 255, 255, 0.1);
  color: #afb0b2;
}

.nz-schedule ul li:last-child {
  border-bottom: none;
}

.nz-schedule li div:first-child {
  float: left;
}
.nz-schedule li div:last-child {
  float: right;
}

/*  WPML
/*-------------------*/

.widget-area .widget_icl_lang_sel_widget ul,
.widget-area .widget_icl_lang_sel_widget ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.widget-area .widget_icl_lang_sel_widget ul li,
.widget-area .widget_icl_lang_sel_widget ul li {
  margin: 0;
  padding: 0;
}

.widget-area .widget_icl_lang_sel_widget li a,
.widget-area .widget_icl_lang_sel_widget li a:visited,
.widget-area .widget_icl_lang_sel_widget #lang_sel_click a {
  height: 50px;
  padding: 0px 20px !important;
  color: #777 !important;
  background-color: #f7f7f7 !important;
  line-height: 50px !important;
  text-transform: uppercase;
}

.widget-area .widget_icl_lang_sel_widget li ul a,
.widget-area .widget_icl_lang_sel_widget li ul a:visited {
  border-top: none;
}

.widget-area .widget_icl_lang_sel_widget li a:hover {
  color: #333 !important;
}

.widget-area .widget_icl_lang_sel_widget .lang_sel_sel:before {
  line-height: 50px;
  height: 50px;
  width: 50px;
  right: 0px;
}

.widget-area .widget_icl_lang_sel_widget.animated .lang_sel_sel:before {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

/*  Instagram
/*-------------------*/

.null-instagram-feed ul:after {
  content: "";
  display: table;
  clear: both;
}

.null-instagram-feed ul {
  list-style: none;
  margin: 0 -2px 0 -2px;
  padding: 0;
}

.null-instagram-feed ul li {
  margin: 0;
  padding: 0 2px 4px 2px !important;
  float: left;
  width: 33.3333333%;
}

.null-instagram-feed ul li a {
  display: block;
  position: relative;
  overflow: hidden;
}

.null-instagram-feed ul li a:after,
.photos_from_flickr .flickr_badge_image a:after,
.recent-projects > .post a:after,
.widget_nz_recent_entries .ninzio-overlay:before {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  margin-top: -15px;
  margin-left: -15px;
  background-color: rgba(0, 0, 0, 0.7);

  background-repeat: no-repeat;
  background-position: 50% 50%;
  content: "";
  opacity: 0;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  z-index: 4;
}

.null-instagram-feed ul li a:after {
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
}

.null-instagram-feed ul li a:before,
.photos_from_flickr .flickr_badge_image a:before,
.recent-projects > .post a:before {
  display: none !important;
}

.woocommerce .product .nz-thumbnail .nz-overlay-before {
  margin-top: -25px;
  margin-left: -25px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.null-instagram-feed ul li a:hover:after,
.null-instagram-feed ul li a:hover:before,
.photos_from_flickr .flickr_badge_image a:hover:after,
.photos_from_flickr .flickr_badge_image a:hover:before,
.recent-projects > .post a:hover:after,
.recent-projects > .post a:hover:before,
.woocommerce .product .nz-thumbnail:hover .nz-overlay-before,
.widget_nz_recent_entries .ninzio-overlay:hover:before {
  opacity: 1;
}

/*  Fast contact
/*-------------------*/

.widget_fast_contact_widget input[type="text"] {
  margin: 0 0 5px;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  font-size: 13px;
  color: #999999;
}

.footer .widget_fast_contact_widget input[type="text"] {
  height: 40px;
  line-height: 40px;
}

.widget_fast_contact_widget textarea {
  height: 90px !important;
  min-height: 90px !important;
  padding: 10px 20px;
  margin-bottom: 10px;
  font-size: 13px;
  color: #999999;
}

.footer .widget_fast_contact_widget input[type="text"],
.footer .widget_fast_contact_widget textarea {
  color: #c5c5c5;
  text-transform: none;
}

.widget_fast_contact_widget .button {
  margin-bottom: 0px;
}

.widget_fast_contact_widget .alert {
  padding: 0;
  margin: 0;
  position: relative;
  border: none;
  margin-bottom: 5px;
  font-size: 12px;
}

.widget_fast_contact_widget .sending,
.widget_fast_contact_widget .alert {
  display: none;
}

.ninzio-contact-form-submit-div {
  position: relative;
}

.widget_fast_contact_widget .sending {
  position: absolute;
  width: 28px;
  height: 28px;
  top: 0;
  right: 0;
  z-index: 15;
  display: none;
}

.widget_fast_contact_widget .sending:before {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 0%;
  font-size: 10px;
  margin: -14px 0 0 0px;
  -webkit-animation: contact-form-loading 0.3s infinite linear;
  animation: contact-form-loading 0.3s infinite linear;
}

.footer .widget_fast_contact_widget .sending:before {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}

.widget_fast_contact_widget .sending:before,
.widget_fast_contact_widget .sending:after {
  border-radius: 28px;
  width: 28px;
  height: 28px;
}

@-webkit-keyframes contact-form-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes contact-form-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*  Sidebar stylings
/*-------------------*/

.sidebar .widget,
.sidebar .yawp_wim_widget {
  padding-bottom: 40px !important;
}

.sidebar .widget_title,
.sidebar .yawp_wim_title {
  color: #333333;
  margin-bottom: 25px;
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 24px;
  position: relative;
  text-transform: uppercase;
}

.sidebar .widget_title:after,
.sidebar .yawp_wim_title:after {
  content: "";
  width: 30px;
  height: 3px;
  display: block;
  margin-top: 7px;
}

.sidebar .widget_tag_cloud .tagcloud a,
.sidebar .post-tags a,
.sidebar .widget_product_tag_cloud .tagcloud a,
.sidebar .projects-tags a {
  color: #999 !important;
  box-shadow: inset 0 0 0 1px #e0e0e0;
}

.sidebar .widget_shopping_cart .cart_list > li > a,
.sidebar .widget_products .product_list_widget > li > a,
.sidebar .widget_recently_viewed_products .product_list_widget > li > a,
.sidebar .widget_recent_reviews .product_list_widget > li > a,
.sidebar .widget_top_rated_products .product_list_widget > li > a {
  color: #333333 !important;
}

.sidebar .widget_product_categories ul li a,
.sidebar .widget_nav_menu ul li a {
  color: #999999 !important;
}

.sidebar .widget_recent_comments ul li:before {
  background-color: #e6e6e6;
}

.sidebar .widget_recent_entries a,
.sidebar .widget_nz_recent_entries .post-title {
  color: #333333 !important;
}

.sidebar .widget_categories ul li a,
.sidebar .widget_pages ul li a,
.sidebar .widget_archive ul li a,
.sidebar .widget_meta ul li a {
  color: #777 !important;
}

.sidebar .nz-schedule {
  box-shadow: inset 0 0 0 2px #e0e0e0;
}

.sidebar .widget_nz_recent_entries .post-body:before,
.sidebar .widget_twitter ul li:before {
  background-color: #e6e6e6;
}

/*  Site Sidebar stylings
/*-------------------*/

.site-widget-area {
  background-color: #2d3034;
  color: #818385;
}

.site-widget-area textarea,
.site-widget-area select,
.site-widget-area input[type="date"],
.site-widget-area input[type="datetime"],
.site-widget-area input[type="datetime-local"],
.site-widget-area input[type="email"],
.site-widget-area input[type="month"],
.site-widget-area input[type="number"],
.site-widget-area input[type="password"],
.site-widget-area input[type="search"],
.site-widget-area input[type="tel"],
.site-widget-area input[type="text"],
.site-widget-area input[type="time"],
.site-widget-area input[type="url"],
.site-widget-area input[type="week"] {
  background-color: #3e4248;
  color: #ffffff;
}

.site-widget-area ::-webkit-input-placeholder {
  color: #e0e0e0;
}
.site-widget-area :-moz-placeholder {
  color: #e0e0e0;
}
.site-widget-area ::-moz-placeholder {
  color: #e0e0e0;
}
.site-widget-area :-ms-input-placeholder {
  color: #e0e0e0;
}

.site-widget-area a:not(.button) {
  color: #c0c1c2;
}

.site-widget-area a:not(.button):hover {
  color: #ffffff !important;
}

.site-widget-area .widget_title,
.site-widget-area .yawp_wim_title {
  color: #ffffff;
}

.site-widget-area .widget_title:after,
.site-widget-area .yawp_wim_title:after {
  content: "";
  width: 30px;
  height: 3px;
  display: block;
  margin-top: 7px;
}

.site-widget-area .widget_tag_cloud .tagcloud a,
.site-widget-area .post-tags a,
.site-widget-area .widget_product_tag_cloud .tagcloud a,
.site-widget-area .projects-tags a {
  color: #818385;
  box-shadow: inset 0 0 0 1px #3e4248;
  background-color: transparent;
}

.site-widget-area .widget_shopping_cart .cart_list > li > a,
.site-widget-area .widget_products .product_list_widget > li > a,
.site-widget-area
  .widget_recently_viewed_products
  .product_list_widget
  > li
  > a,
.site-widget-area .widget_recent_reviews .product_list_widget > li > a,
.site-widget-area .widget_top_rated_products .product_list_widget > li > a,
.site-widget-area .widget_product_categories ul li a,
.site-widget-area .widget_nav_menu ul li a,
.site-widget-area .widget_recent_entries a,
.site-widget-area .widget_nz_recent_entries .post-title,
.site-widget-area .widget_categories ul li a,
.site-widget-area .widget_pages ul li a,
.site-widget-area .widget_archive ul li a,
.site-widget-area .widget_meta ul li a {
  color: #818385;
}

.site-widget-area .nz-schedule {
  box-shadow: inset 0 0 0 2px #3e4248;
}

.site-widget-area .widget_nz_recent_entries .post-body:before,
.site-widget-area .widget_twitter ul li:before,
.site-widget-area .widget_recent_comments ul li:before {
  background-color: #3e4248;
}

.site-widget-area
  .widget_price_filter
  .price_slider_wrapper
  .ui-widget-content {
  background: #3e4248;
}

.site-widget-area .widget_shopping_cart p.buttons > a,
.site-widget-area .widget_price_filter .price_slider_amount .button {
  color: #818385;
  box-shadow: inset 0 0 0 2px #3e4248;
}

.site-widget-area .widget_shopping_cart .cart_list > li,
.site-widget-area .widget_products .product_list_widget > li,
.site-widget-area .widget_recently_viewed_products .product_list_widget > li,
.site-widget-area .widget_recent_reviews .product_list_widget > li,
.site-widget-area .widget_top_rated_products .product_list_widget > li {
  border-bottom: 1px solid #3e4248;
}

.site-widget-area .null-instagram-feed ul li,
.site-widget-area .widget_recent_projects .recent-projects > .post {
  width: 33.3333333%;
}

.site-widget-area .widget_calendar table,
.site-widget-area .widget_calendar caption {
  background-color: transparent;
}

.site-widget-area .widget_calendar td#prev,
.site-widget-area .widget_calendar td#next,
.site-widget-area .widget_calendar td,
.site-widget-area .widget_calendar caption,
.site-widget-area .widget_calendar th:first-child,
.site-widget-area .widget_calendar th:last-child {
  border-color: #3e4248;
}

.site-widget-area .widget_calendar caption,
.site-widget-area .widget_calendar th,
.site-widget-area .widget_calendar td,
.site-widget-area .widget_calendar td#prev,
.site-widget-area .widget_calendar td#next,
.site-widget-area .widget_calendar td#prev span,
.site-widget-area .widget_calendar td#next span {
  height: 41px;
  width: 41px;
  line-height: 41px;
}

.site-widget-area .woocommerce .star-rating:before,
.site-widget-area
  .woocommerce-page
  .woocommerce-product-rating
  .star-rating:before,
.site-widget-area .woocommerce .comment-text .star-rating:before,
.site-widget-area .widget_recent_reviews .star-rating:before,
.site-widget-area .widget_top_rated_products .star-rating:before {
  color: #3e4248;
}

.site-widget-area .widget_nav_menu ul li a {
  padding: 15px 0;
  border-bottom: 1px solid #3e4248;
}

.site-widget-area .widget_nav_menu > div > ul > li:first-child > a {
  border-top: 1px solid #3e4248;
}

.site-widget-area .widget_categories ul li,
.site-widget-area .widget_pages ul li,
.site-widget-area .widget_archive ul li,
.site-widget-area .widget_meta ul li {
  border-bottom: 1px solid #3e4248;
}

/*  Mobile styling
/*-------------------*/

.mob-header .yawp_wim_wrap {
  padding: 0px !important;
}

.mob-header .yawp_wim_title {
  padding: 31px 0 18px 0;
  display: block;
  text-transform: uppercase;
}

.mob-header .button,
.mob-header button,
.mob-header input[type="reset"],
.mob-header input[type="submit"],
.mob-header input[type="button"],
.mob-header .button:hover,
.mob-header button:hover,
.mob-header input[type="reset"]:hover,
.mob-header input[type="submit"]:hover,
.mob-header input[type="button"]:hover {
  color: #ffffff !important;
}

.mob-header .instagram-pics,
.mob-header .widget_rss ul,
.mob-header .widget_nz_recent_entries ul,
.mob-header .widget_recent_comments ul,
.mob-header .widget_recent_entries ul,
.mob-header .widget_twitter ul,
.mob-header .nz-schedule ul,
.mob-header .widget_categories ul,
.mob-header .widget_pages ul,
.mob-header .widget_archive ul,
.mob-header .widget_meta ul,
.mob-header .widget_nav_menu .menu-header-menu-container > ul,
.mob-header .widget_product_categories ul,
.mob-header .widget_shopping_cart .cart_list,
.mob-header .widget_products .product_list_widget,
.mob-header .widget_recently_viewed_products .product_list_widget,
.mob-header .widget_recent_reviews .product_list_widget,
.mob-header .widget_top_rated_products .product_list_widget,
.mob-header .widget_rating_filter ul {
  display: block;
}

.mob-header .null-instagram-feed ul li a,
.mob-header .null-instagram-feed p > a,
.mob-header .flickr_badge_image a,
.mob-header .widget_recent_projects .recent-projects > .post a,
.mob-header .widget_twitter ul li a.tweet-time,
.mob-header .widget_reglog a,
.mob-header .widget_categories ul li a,
.mob-header .widget_pages ul li a,
.mob-header .widget_archive ul li a,
.mob-header .widget_meta ul li a,
.mob-header .widget_rss a,
.mob-header .widget_recent_entries ul li > *,
.mob-header .recentcomments a,
.mob-header .widget_shopping_cart .cart_list > li > a,
.mob-header .widget_products .product_list_widget > li > a,
.mob-header .widget_recently_viewed_products .product_list_widget > li > a,
.mob-header .widget_recent_reviews .product_list_widget > li > a,
.mob-header .widget_top_rated_products .product_list_widget > li > a {
  padding: 0 !important;
  border-bottom: none !important;
}

.mob-header #mce-EMAIL,
.mob-header #user_login,
.mob-header #user_pass {
  background-color: #eeeeee;
  margin-bottom: 10px;
}

.mob-header .widget_nz_recent_entries .post-body:before,
.mob-header .widget_recent_comments ul li:before {
  background-color: #f7f7f7;
}

.mob-header .widget_nz_recent_entries .post-title {
  padding: 0px !important;
  border-bottom: none !important;
}

.mob-header .widget_nav_menu li,
.mob-header .widget_product_categories li {
  background-color: transparent !important;
  border-bottom: none !important;
  padding: 10px 0 !important;
}

.mob-header .widget_nav_menu li a,
.mob-header .widget_product_categories li a {
  border-bottom: none !important;
}

.mob-header .widget_tag_cloud .tagcloud a,
.mob-header .post-tags a,
.mob-header .widget_product_tag_cloud .tagcloud a,
.mob-header .projects-tags a {
  display: inline-block !important;
  border-bottom: none !important;
  box-shadow: inset 0 0 0 1px #eeeeee;
}

.mob-header .widget_rss a,
.mob-header .ninzio-contact-form-submit {
  display: inline-block !important;
}

.mob-header .widget_shopping_cart .cart_list > li,
.mob-header .widget_products .product_list_widget > li,
.mob-header .widget_recently_viewed_products .product_list_widget > li,
.mob-header .widget_recent_reviews .product_list_widget > li,
.mob-header .widget_top_rated_products .product_list_widget > li {
  padding: 10px 0px;
}

.mob-header .widget_shopping_cart .cart_list .remove {
  background-color: #f7f7f7;
}

.mob-header .widget_nz_recent_entries .post-body:before,
.mob-header .widget_twitter ul li:before {
  background-color: #e6e6e6;
}

.mob-header .widget_calendar caption,
.mob-header .widget_calendar th,
.mob-header .widget_calendar td,
.mob-header .widget_calendar td#prev,
.mob-header .widget_calendar td#next,
.mob-header .widget_calendar td#prev span,
.mob-header .widget_calendar td#next span {
  height: 41px;
  width: 41px;
  line-height: 41px;
}

.mob-header .nz-schedule {
  box-shadow: inset 0 0 0 2px #e0e0e0;
}

.mob-header .photos_from_flickr .flickr_badge_image {
  width: 73px;
}

.mob-header .widget_shopping_cart p.buttons > a,
.mob-header .widget_price_filter .price_slider_amount .button {
  color: #999999 !important;
}

.mob-header .widget_rss a,
.mob-header .widget_nz_recent_entries a,
.mob-header .widget_recent_comments a,
.mob-header .widget_recent_entries a {
  display: inline !important;
  padding: 0 !important;
  border: none !important;
  position: static !important;
}

/*  Footer stylings
/*-------------------*/

.footer-wa {
  color: #bfbfbf;
  padding: 50px 0;
}

.footer-wa:empty {
  padding: 0;
}

.footer-widget-area textarea,
.footer-widget-area select,
.footer-widget-area input[type="date"],
.footer-widget-area input[type="datetime"],
.footer-widget-area input[type="datetime-local"],
.footer-widget-area input[type="email"],
.footer-widget-area input[type="month"],
.footer-widget-area input[type="number"],
.footer-widget-area input[type="password"],
.footer-widget-area input[type="search"],
.footer-widget-area input[type="tel"],
.footer-widget-area input[type="text"],
.footer-widget-area input[type="time"],
.footer-widget-area input[type="url"],
.footer-widget-area input[type="week"] {
  background-color: rgba(0, 0, 0, 0.2);
  color: #ffffff;
}

.footer-widget-area ::-webkit-input-placeholder {
  color: #e0e0e0;
}
.footer-widget-area :-moz-placeholder {
  color: #e0e0e0;
}
.footer-widget-area ::-moz-placeholder {
  color: #e0e0e0;
}
.footer-widget-area :-ms-input-placeholder {
  color: #e0e0e0;
}

.footer-widget-area a:not(.button) {
  color: #c0c1c2;
}

.footer-widget-area a:not(.button):hover {
  color: #ffffff !important;
}

.footer-widget-area .widget_title,
.footer-widget-area .yawp_wim_title {
  color: #ffffff;
}

.footer-widget-area .widget_title:after,
.footer-widget-area .yawp_wim_title:after {
  content: "";
  width: 30px;
  height: 3px;
  display: block;
  margin-top: 7px;
}

.footer-widget-area .widget_tag_cloud .tagcloud a,
.footer-widget-area .post-tags a,
.footer-widget-area .widget_product_tag_cloud .tagcloud a,
.footer-widget-area .projects-tags a {
  color: #818385;
  box-shadow: inset 0 0 0 1px #3e4248;
  background-color: transparent;
}

.footer-widget-area .widget_shopping_cart .cart_list > li > a,
.footer-widget-area .widget_products .product_list_widget > li > a,
.footer-widget-area
  .widget_recently_viewed_products
  .product_list_widget
  > li
  > a,
.footer-widget-area .widget_recent_reviews .product_list_widget > li > a,
.footer-widget-area .widget_top_rated_products .product_list_widget > li > a,
.footer-widget-area .widget_product_categories ul li a,
.footer-widget-area .widget_nav_menu ul li a,
.footer-widget-area .widget_recent_entries a,
.footer-widget-area .widget_nz_recent_entries .post-title,
.footer-widget-area .widget_categories ul li a,
.footer-widget-area .widget_pages ul li a,
.footer-widget-area .widget_archive ul li a,
.footer-widget-area .widget_meta ul li a {
  color: #bfbfbf;
}

.footer-widget-area .nz-schedule ul li {
  border-bottom: 1px dashed #3e4248;
}

.footer-widget-area .nz-schedule ul li:last-child {
  border-bottom: none;
}

.footer-widget-area
  .widget_price_filter
  .price_slider_wrapper
  .ui-widget-content {
  background: #3e4248;
}

.footer-widget-area .widget_shopping_cart p.buttons > a,
.footer-widget-area .widget_price_filter .price_slider_amount .button {
  color: #818385;
  box-shadow: inset 0 0 0 2px #3e4248;
}

.footer-widget-area .widget_shopping_cart .cart_list > li,
.footer-widget-area .widget_products .product_list_widget > li,
.footer-widget-area .widget_recently_viewed_products .product_list_widget > li,
.footer-widget-area .widget_recent_reviews .product_list_widget > li,
.footer-widget-area .widget_top_rated_products .product_list_widget > li {
  border-bottom: 1px solid #3e4248;
}

.footer-widget-area .widget_calendar table,
.footer-widget-area .widget_calendar caption {
  background-color: transparent;
}

.footer-widget-area .widget_calendar td#prev,
.footer-widget-area .widget_calendar td#next,
.footer-widget-area .widget_calendar td,
.footer-widget-area .widget_calendar caption,
.footer-widget-area .widget_calendar th:first-child,
.footer-widget-area .widget_calendar th:last-child {
  border-color: #3e4248;
}

.footer-widget-area .woocommerce .star-rating:before,
.footer-widget-area
  .woocommerce-page
  .woocommerce-product-rating
  .star-rating:before,
.footer-widget-area .woocommerce .comment-text .star-rating:before,
.footer-widget-area .widget_recent_reviews .star-rating:before,
.footer-widget-area .widget_top_rated_products .star-rating:before {
  color: #3e4248;
}

.footer-widget-area .widget_calendar caption,
.footer-widget-area .widget_calendar th,
.footer-widget-area .widget_calendar td,
.footer-widget-area .widget_calendar td#prev,
.footer-widget-area .widget_calendar td#next,
.footer-widget-area .widget_calendar td#prev span,
.footer-widget-area .widget_calendar td#next span {
  height: 41px;
  width: 41px;
  line-height: 41px;
}

.footer-widget-area .photos_from_flickr .flickr_badge_image {
  width: 73px;
}

.footer-widget-area .widget_categories ul li,
.footer-widget-area .widget_pages ul li,
.footer-widget-area .widget_archive ul li,
.footer-widget-area .widget_meta ul li,
.footer-widget-area .widget_nz_recent_entries li {
  border-bottom: 1px dashed #3e4248;
}

.footer-widget-area .widget_nz_recent_entries ul li:last-child {
  border-bottom: none;
}

.footer-widget-area .widget_nz_recent_entries .post-body {
  margin-bottom: 0px;
}

/*  BLOG/WOO corrections
/*-------------------*/

.blog-layout-wrap textarea,
.blog-layout-wrap select,
.blog-layout-wrap input[type="date"],
.blog-layout-wrap input[type="datetime"],
.blog-layout-wrap input[type="datetime-local"],
.blog-layout-wrap input[type="email"],
.blog-layout-wrap input[type="month"],
.blog-layout-wrap input[type="number"],
.blog-layout-wrap input[type="password"],
.blog-layout-wrap input[type="search"],
.blog-layout-wrap input[type="tel"],
.blog-layout-wrap input[type="text"],
.blog-layout-wrap input[type="time"],
.blog-layout-wrap input[type="url"],
.blog-layout-wrap input[type="week"],
.shop-layout-wrap textarea,
.shop-layout-wrap select,
.shop-layout-wrap input[type="date"],
.shop-layout-wrap input[type="datetime"],
.shop-layout-wrap input[type="datetime-local"],
.shop-layout-wrap input[type="email"],
.shop-layout-wrap input[type="month"],
.shop-layout-wrap input[type="number"],
.shop-layout-wrap input[type="password"],
.shop-layout-wrap input[type="search"],
.shop-layout-wrap input[type="tel"],
.shop-layout-wrap input[type="text"],
.shop-layout-wrap input[type="time"],
.shop-layout-wrap input[type="url"],
.shop-layout-wrap input[type="week"] {
  background-color: #f7f7f7;
  color: #999999;
}

.shop-layout-wrap .widget_categories ul li,
.shop-layout-wrap .widget_pages ul li,
.shop-layout-wrap .widget_archive ul li,
.shop-layout-wrap .widget_meta ul li,
.blog-layout-wrap .widget_categories ul li,
.blog-layout-wrap .widget_pages ul li,
.blog-layout-wrap .widget_archive ul li,
.blog-layout-wrap .widget_meta ul li {
  border-bottom: 1px dashed #e0e0e0;
}

/*BUTTON
/*=============*/

.button,
button,
input[type="reset"],
input[type="submit"],
input[type="button"],
.wc-proceed-to-checkout a,
.single_add_to_cart_button {
  outline: none;
  border: none;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase !important;
  position: relative;
  display: inline-block;
  margin: 0 0px 5px 0;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  text-align: center;
  z-index: 1;
}

.button.full-true {
  width: 100%;
  margin-left: 0px !important;
}

.button + .button {
  margin-left: 15px;
}

.button-ghost {
  background-color: transparent;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.button-normal,
button,
input[type="reset"],
input[type="submit"],
input[type="button"],
.wc-proceed-to-checkout a {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.button-3d.button {
  -webkit-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px);
}

.button.small {
  padding: 4px 25px;
  font-size: 12px;
  line-height: 22px;
}

.button.medium,
.button,
button,
input[type="reset"],
input[type="submit"],
input[type="button"],
.wc-proceed-to-checkout a,
.single_add_to_cart_button {
  padding: 14px 34px;
  font-size: 14px;
  line-height: 22px;
}

.button.large {
  padding: 18px 44px;
  font-size: 14px;
  line-height: 24px;
}

.button.square,
button,
input[type="reset"],
input[type="submit"],
input[type="button"],
.wc-proceed-to-checkout a {
  border-radius: 0;
}

.button.rounded {
  border-radius: 3px;
}

.button.round {
  border-radius: 250px;
}

.button.animate-false .btn-icon {
  display: block;
  margin: 0px;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 120%;
}

.button.round.animate-false .btn-icon {
  left: 3px;
}

.button.small.animate-false .btn-icon {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.button.medium.animate-false .btn-icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
}

.button.large.animate-false .btn-icon {
  width: 60px;
  height: 60px;
  line-height: 60px;
}

.button.small.icon-true.animate-false {
  padding-left: 55px;
}

.button.medium.icon-true.animate-false {
  padding-left: 84px;
}

.button.large.icon-true.animate-false {
  padding-left: 104px;
}

.button.icon-true.animate-false .btn-icon:after {
  width: 1px;
  height: 100%;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.button-ghost.icon-true.animate-false .btn-icon:after {
  width: 2px;
}

.blue.button-3d.button.icon-true.animate-false .btn-icon:after {
  background: #2980b9;
}

.blue.button-normal.button.icon-true.animate-false .btn-icon:after {
  background: #2980b9;
}
.blue.button-normal.button.icon-true.animate-false:hover .btn-icon:after {
  background: #246e9f;
}

.blue.button-ghost.button.icon-true.animate-false .btn-icon:after {
  background: #3498db;
}
.blue.button-ghost.button.icon-true.animate-false:hover .btn-icon:after {
  background: #2980b9;
}

/*------*/

.turquoise.button-3d.button.icon-true.animate-false .btn-icon:after {
  background: #118f82;
}

.turquoise.button-normal.button.icon-true.animate-false .btn-icon:after {
  background: #118f82;
}
.turquoise.button-normal.button.icon-true.animate-false:hover .btn-icon:after {
  background: #0e796e;
}

.turquoise.button-ghost.button.icon-true.animate-false .btn-icon:after {
  background: #14a697;
}
.turquoise.button-ghost.button.icon-true.animate-false:hover .btn-icon:after {
  background: #118f82;
}

/*------*/

.pink.button-3d.button.icon-true.animate-false .btn-icon:after {
  background: #d74949;
}

.pink.button-normal.button.icon-true.animate-false .btn-icon:after {
  background: #d74949;
}
.pink.button-normal.button.icon-true.animate-false:hover .btn-icon:after {
  background: #c54343;
}

.pink.button-ghost.button.icon-true.animate-false .btn-icon:after {
  background: #f25252;
}
.pink.button-ghost.button.icon-true.animate-false:hover .btn-icon:after {
  background: #d74949;
}

/*------*/

.violet.button-3d.button.icon-true.animate-false .btn-icon:after {
  background: #5e456e;
}

.violet.button-normal.button.icon-true.animate-false .btn-icon:after {
  background: #5e456e;
}
.violet.button-normal.button.icon-true.animate-false:hover .btn-icon:after {
  background: #4e395b;
}

.violet.button-ghost.button.icon-true.animate-false .btn-icon:after {
  background: #745587;
}
.violet.button-ghost.button.icon-true.animate-false:hover .btn-icon:after {
  background: #5e456e;
}

/*------*/

.peacoc.button-3d.button.icon-true.animate-false .btn-icon:after {
  background: #158cc0;
}

.peacoc.button-normal.button.icon-true.animate-false .btn-icon:after {
  background: #158cc0;
}
.peacoc.button-normal.button.icon-true.animate-false:hover .btn-icon:after {
  background: #106872;
}

.peacoc.button-ghost.button.icon-true.animate-false .btn-icon:after {
  background: #009ee3;
}
.peacoc.button-ghost.button.icon-true.animate-false:hover .btn-icon:after {
  background: #158cc0;
}

/*------*/

.chino.button-3d.button.icon-true.animate-false .btn-icon:after {
  background: #98947b;
}

.chino.button-normal.button.icon-true.animate-false .btn-icon:after {
  background: #98947b;
}
.chino.button-normal.button.icon-true.animate-false:hover .btn-icon:after {
  background: #8e8677;
}

.chino.button-ghost.button.icon-true.animate-false .btn-icon:after {
  background: #b4af91;
}
.chino.button-ghost.button.icon-true.animate-false:hover .btn-icon:after {
  background: #98947b;
}

/*------*/

.mulled_wine.button-3d.button.icon-true.animate-false .btn-icon:after {
  background: #671b2a;
}

.mulled_wine.button-normal.button.icon-true.animate-false .btn-icon:after {
  background: #671b2a;
}
.mulled_wine.button-normal.button.icon-true.animate-false:hover
  .btn-icon:after {
  background: #521621;
}

.mulled_wine.button-ghost.button.icon-true.animate-false .btn-icon:after {
  background: #7d2133;
}
.mulled_wine.button-ghost.button.icon-true.animate-false:hover .btn-icon:after {
  background: #671b2a;
}

/*------*/

.vista_blue.button-3d.button.icon-true.animate-false .btn-icon:after {
  background: #19a588;
}

.vista_blue.button-normal.button.icon-true.animate-false .btn-icon:after {
  background: #19a588;
}
.vista_blue.button-normal.button.icon-true.animate-false:hover .btn-icon:after {
  background: #168f76;
}

.vista_blue.button-ghost.button.icon-true.animate-false .btn-icon:after {
  background: #1bbc9b;
}
.vista_blue.button-ghost.button.icon-true.animate-false:hover .btn-icon:after {
  background: #19a588;
}

/*------*/

.black.button-3d.button.icon-true.animate-false .btn-icon:after {
  background: #454846;
}

.black.button-normal.button.icon-true.animate-false .btn-icon:after {
  background: #454846;
}
.black.button-normal.button.icon-true.animate-false:hover .btn-icon:after {
  background: #454846;
}

.black.button-ghost.button.icon-true.animate-false .btn-icon:after {
  background: #2a2a2a;
}
.black.button-ghost.button.icon-true.animate-false:hover .btn-icon:after {
  background: #454846;
}

/*------*/

.grey.button-3d.button.icon-true.animate-false .btn-icon:after {
  background: #485f5d;
}

.grey.button-normal.button.icon-true.animate-false .btn-icon:after {
  background: #485f5d;
}
.grey.button-normal.button.icon-true.animate-false:hover .btn-icon:after {
  background: #394b4a;
}

.grey.button-ghost.button.icon-true.animate-false .btn-icon:after {
  background: #5b7876;
}
.grey.button-ghost.button.icon-true.animate-false:hover .btn-icon:after {
  background: #485f5d;
}

/*------*/

.grey_light.button-3d.button.icon-true.animate-false .btn-icon:after {
  background: #e0e0e0;
}

.grey_light.button-normal.button.icon-true.animate-false .btn-icon:after {
  background: #e0e0e0;
}
.grey_light.button-normal.button.icon-true.animate-false:hover .btn-icon:after {
  background: #d0d0d0;
}

.grey_light.button-ghost.button.icon-true.animate-false .btn-icon:after {
  background: #eeeeee;
}
.grey_light.button-ghost.button.icon-true.animate-false:hover .btn-icon:after {
  background: #e0e0e0;
}

/*------*/

.orange.button-3d.button.icon-true.animate-false .btn-icon:after {
  background: #d37201;
}

.orange.button-normal.button.icon-true.animate-false .btn-icon:after {
  background: #d37201;
}
.orange.button-normal.button.icon-true.animate-false:hover .btn-icon:after {
  background: #bd6601;
}

.orange.button-ghost.button.icon-true.animate-false .btn-icon:after {
  background: #eb7f00;
}
.orange.button-ghost.button.icon-true.animate-false:hover .btn-icon:after {
  background: #d37201;
}

/*------*/

.sky.button-3d.button.icon-true.animate-false .btn-icon:after {
  background: #7692b4;
}

.sky.button-normal.button.icon-true.animate-false .btn-icon:after {
  background: #7692b4;
}
.sky.button-normal.button.icon-true.animate-false:hover .btn-icon:after {
  background: #667e9c;
}

.sky.button-ghost.button.icon-true.animate-false .btn-icon:after {
  background: #85a5cc;
}
.sky.button-ghost.button.icon-true.animate-false:hover .btn-icon:after {
  background: #7692b4;
}

/*------*/

.green.button-3d.button.icon-true.animate-false .btn-icon:after {
  background: #487520;
}

.green.button-normal.button.icon-true.animate-false .btn-icon:after {
  background: #487520;
}
.green.button-normal.button.icon-true.animate-false:hover .btn-icon:after {
  background: #3b601a;
}

.green.button-ghost.button.icon-true.animate-false .btn-icon:after {
  background: #588f27;
}
.green.button-ghost.button.icon-true.animate-false:hover .btn-icon:after {
  background: #487520;
}

/*------*/

.yellow.button-3d.button.icon-true.animate-false .btn-icon:after {
  background: #e6bd0f;
}

.yellow.button-normal.button.icon-true.animate-false .btn-icon:after {
  background: #e6bd0f;
}
.yellow.button-normal.button.icon-true.animate-false:hover .btn-icon:after {
  background: #d5af0f;
}

.yellow.button-ghost.button.icon-true.animate-false .btn-icon:after {
  background: #ffd10f;
}
.yellow.button-ghost.button.icon-true.animate-false:hover .btn-icon:after {
  background: #e6bd0f;
}

/*------*/

.sandy_brown.button-3d.button.icon-true.animate-false .btn-icon:after {
  background: #dca868;
}

.sandy_brown.button-normal.button.icon-true.animate-false .btn-icon:after {
  background: #dca868;
}
.sandy_brown.button-normal.button.icon-true.animate-false:hover
  .btn-icon:after {
  background: #c2945c;
}

.sandy_brown.button-ghost.button.icon-true.animate-false .btn-icon:after {
  background: #f2b872;
}
.sandy_brown.button-ghost.button.icon-true.animate-false:hover .btn-icon:after {
  background: #dca868;
}

/*------*/

.purple.button-3d.button.icon-true.animate-false .btn-icon:after {
  background: #714e9c;
}

.purple.button-normal.button.icon-true.animate-false .btn-icon:after {
  background: #714e9c;
}
.purple.button-normal.button.icon-true.animate-false:hover .btn-icon:after {
  background: #533972;
}

.purple.button-ghost.button.icon-true.animate-false .btn-icon:after {
  background: #855cb8;
}
.purple.button-ghost.button.icon-true.animate-false:hover .btn-icon:after {
  background: #714e9c;
}

/*------*/

.white.button-3d.button.icon-true.animate-false .btn-icon:after {
  background: #eeeeee;
}

.white.button-normal.button.icon-true.animate-false .btn-icon:after {
  background: #eeeeee;
}
.white.button-normal.button.icon-true.animate-false:hover .btn-icon:after {
  background: #cccccc;
}

.white.button-ghost.button.icon-true.animate-false .btn-icon:after {
  background: #ffffff;
}
.white.button-ghost.button.icon-true.animate-false:hover .btn-icon:after {
  background: #eeeeee;
}

.animate-false.button-normal.hover-opacity:hover {
  opacity: 0.7;
}

.animate-false.button-3d:hover {
  top: 2px;
}

.blue.button-normal {
  background: #3498db;
}
.animate-false.blue.button-normal.hover-fill:hover {
  background: #2980b9;
}

.blue.button-ghost {
  box-shadow: inset 0 0 0 2px #3498db;
  color: #3498db;
}
.blue.button-3d {
  background-color: #3498db;
  box-shadow: 0 4px #236d9e;
}
.animate-false.blue.button-3d:hover {
  box-shadow: 0 2px #236d9e;
}

.turquoise.button-normal {
  background: #14a697;
}
.animate-false.turquoise.button-normal.hover-fill:hover {
  background: #118f82;
}

.turquoise.button-ghost {
  box-shadow: inset 0 0 0 2px #14a697;
  color: #14a697;
}
.turquoise.button-3d {
  background-color: #14a697;
  box-shadow: 0 4px #237f73;
}
.animate-false.turquoise.button-3d:hover {
  box-shadow: 0 2px #237f73;
}

.pink.button-normal {
  background: #f25252;
}
.animate-false.pink.button-normal.hover-fill:hover {
  background: #d74949;
}

.pink.button-ghost {
  box-shadow: inset 0 0 0 2px #f25252;
  color: #f25252;
}
.pink.button-3d {
  background-color: #f25252;
  box-shadow: 0 4px #be4141;
}
.animate-false.pink.button-3d:hover {
  box-shadow: 0 2px #be4141;
}

.violet.button-normal {
  background: #745587;
}
.animate-false.violet.button-normal.hover-fill:hover {
  background: #5e456e;
}

.violet.button-ghost {
  box-shadow: inset 0 0 0 2px #745587;
  color: #745587;
}
.violet.button-3d {
  background-color: #745587;
  box-shadow: 0 4px #483554;
}
.animate-false.violet.button-3d:hover {
  box-shadow: 0 2px #483554;
}

.peacoc.button-normal {
  background: #009ee3;
}
.animate-false.peacoc.button-normal.hover-fill:hover {
  background: #158cc0;
}

.peacoc.button-ghost {
  box-shadow: inset 0 0 0 2px #009ee3;
  color: #009ee3;
}
.peacoc.button-3d {
  background-color: #009ee3;
  box-shadow: 0 4px #0f636c;
}
.animate-false.peacoc.button-3d:hover {
  box-shadow: 0 2px #0f636c;
}

.chino.button-normal {
  background: #b4af91;
}
.animate-false.chino.button-normal.hover-fill:hover {
  background: #98947b;
}

.chino.button-ghost {
  box-shadow: inset 0 0 0 2px #b4af91;
  color: #b4af91;
}
.chino.button-3d {
  background-color: #b4af91;
  box-shadow: 0 4px #787561;
}
.animate-false.chino.button-3d:hover {
  box-shadow: 0 2px #787561;
}

.mulled_wine.button-normal {
  background: #7d2133;
}
.animate-false.mulled_wine.button-normal.hover-fill:hover {
  background: #671b2a;
}

.mulled_wine.button-ghost {
  box-shadow: inset 0 0 0 2px #7d2133;
  color: #7d2133;
}
.mulled_wine.button-3d {
  background-color: #7d2133;
  box-shadow: 0 4px #4d141f;
}
.animate-false.mulled_wine.button-3d:hover {
  box-shadow: 0 2px #4d141f;
}

.vista_blue.button-normal {
  background: #1bbc9b;
}
.animate-false.vista_blue.button-normal.hover-fill:hover {
  background: #19a588;
}

.vista_blue.button-ghost {
  box-shadow: inset 0 0 0 2px #1bbc9b;
  color: #1bbc9b;
}
.vista_blue.button-3d {
  background-color: #1bbc9b;
  box-shadow: 0 4px #148971;
}
.animate-false.vista_blue.button-3d:hover {
  box-shadow: 0 2px #148971;
}

.black.button-normal {
  background: #2a2c2b;
}
.animate-false.black.button-normal.hover-fill:hover {
  background: #1b1c1b;
}

.black.button-ghost {
  box-shadow: inset 0 0 0 2px #2a2a2a;
  color: #2a2a2a;
}
.black.button-3d {
  background-color: #2a2a2a;
  box-shadow: 0 4px #7c7c7c;
}
.animate-false.black.button-3d:hover {
  box-shadow: 0 2px #7c7c7c;
}

.grey.button-normal {
  background: #5b7876;
}
.animate-false.grey.button-normal.hover-fill:hover {
  background: #485f5d;
}

.grey.button-ghost {
  box-shadow: inset 0 0 0 2px #5b7876;
  color: #999999;
}
.grey.button-3d {
  background-color: #5b7876;
  box-shadow: 0 4px #334342;
}
.animate-false.grey.button-3d:hover {
  box-shadow: 0 2px #334342;
}

.grey_light.button-normal {
  background: #eeeeee;
  color: #999999;
}
.animate-false.grey_light.button-normal.hover-fill:hover {
  background: #e0e0e0;
}

.grey_light.button-ghost {
  box-shadow: inset 0 0 0 2px #eeeeee;
  color: #999999;
}
.grey_light.button-3d {
  background-color: #eeeeee;
  box-shadow: 0 4px #c0c0c0;
  color: #999999;
}
.animate-false.grey_light.button-3d:hover {
  box-shadow: 0 2px #c0c0c0;
}

.orange.button-normal {
  background: #eb7f00;
}
.animate-false.orange.button-normal.hover-fill:hover {
  background: #d37201;
}

.orange.button-ghost {
  box-shadow: inset 0 0 0 2px #eb7f00;
  color: #eb7f00;
}
.orange.button-3d {
  background-color: #eb7f00;
  box-shadow: 0 4px #b66301;
}
.animate-false.orange.button-3d:hover {
  box-shadow: 0 2px #b66301;
}

.sky.button-normal {
  background: #85a5cc;
}
.animate-false.sky.button-normal.hover-fill:hover {
  background: #7692b4;
}

.sky.button-ghost {
  box-shadow: inset 0 0 0 2px #85a5cc;
  color: #85a5cc;
}
.sky.button-3d {
  background-color: #85a5cc;
  box-shadow: 0 4px #3b75ac;
}
.animate-false.sky.button-3d:hover {
  box-shadow: 0 2px #3b75ac;
}

.green.button-normal {
  background: #588f27;
}
.animate-false.green.button-normal.hover-fill:hover {
  background: #487520;
}

.green.button-ghost {
  box-shadow: inset 0 0 0 2px #588f27;
  color: #588f27;
}
.green.button-3d {
  background-color: #588f27;
  box-shadow: 0 4px #3d7411;
}
.animate-false.green.button-3d:hover {
  box-shadow: 0 2px #3d7411;
}

.yellow.button-normal {
  background: #ffd10f;
}
.animate-false.yellow.button-normal.hover-fill:hover {
  background: #e6bd0f;
}

.yellow.button-ghost {
  box-shadow: inset 0 0 0 2px #ffd10f;
  color: #ffd10f;
}
.yellow.button-3d {
  background-color: #ffd10f;
  box-shadow: 0 4px #e6bd0f;
}
.animate-false.yellow.button-3d:hover {
  box-shadow: 0 2px #e6bd0f;
}

.sandy_brown.button-normal {
  background: #f2b872;
}
.animate-false.sandy_brown.button-normal.hover-fill:hover {
  background: #dca868;
}

.sandy_brown.button-ghost {
  box-shadow: inset 0 0 0 2px #f2b872;
  color: #f2b872;
}
.sandy_brown.button-3d {
  background-color: #f2b872;
  box-shadow: 0 4px #e38054;
}
.animate-false.sandy_brown.button-3d:hover {
  box-shadow: 0 2px #e38054;
}

.purple.button-normal {
  background: #9768d1;
}
.animate-false.purple.button-normal.hover-fill:hover {
  background: #855cb8;
}

.purple.button-ghost {
  box-shadow: inset 0 0 0 2px #9768d1;
  color: #9768d1;
}
.purple.button-3d {
  background-color: #9768d1;
  box-shadow: 0 4px #482b71;
}
.animate-false.purple.button-3d:hover {
  box-shadow: 0 2px #482b71;
}

.white.button-normal {
  background: #ffffff;
  color: #999999;
}

.animate-false.white.button-normal.hover-fill:hover {
  background: #f7f7f7;
}

.white.button-ghost {
  box-shadow: inset 0 0 0 2px #ffffff;
  color: #ffffff;
}
.white.button-3d {
  background-color: #ffffff;
  box-shadow: 0 4px #ebebeb;
  color: #333333;
}
.animate-false.white.button-3d:hover {
  box-shadow: 0 2px #ebebeb;
}

.blue.button-ghost.hover-fill:hover,
.blue.button-ghost.hover-drop:after,
.blue.button-ghost.hover-side:after,
.blue.button-ghost.hover-scene:after,
.blue.button-ghost.hover-screen:after {
  background-color: #3498db;
}

.turquoise.button-ghost.hover-fill:hover,
.turquoise.button-ghost.hover-drop:after,
.turquoise.button-ghost.hover-side:after,
.turquoise.button-ghost.hover-scene:after,
.turquoise.button-ghost.hover-screen:after {
  background-color: #14a697;
}

.pink.button-ghost.hover-fill:hover,
.pink.button-ghost.hover-drop:after,
.pink.button-ghost.hover-side:after,
.pink.button-ghost.hover-scene:after,
.pink.button-ghost.hover-screen:after {
  background-color: #f25252;
}

.violet.button-ghost.hover-fill:hover,
.violet.button-ghost.hover-drop:after,
.violet.button-ghost.hover-side:after,
.violet.button-ghost.hover-scene:after,
.violet.button-ghost.hover-screen:after {
  background-color: #745587;
}

.peacoc.button-ghost.hover-fill:hover,
.peacoc.button-ghost.hover-drop:after,
.peacoc.button-ghost.hover-side:after,
.peacoc.button-ghost.hover-scene:after,
.peacoc.button-ghost.hover-screen:after {
  background-color: #009ee3;
}

.chino.button-ghost.hover-fill:hover,
.chino.button-ghost.hover-drop:after,
.chino.button-ghost.hover-side:after,
.chino.button-ghost.hover-scene:after,
.chino.button-ghost.hover-screen:after {
  background-color: #b4af91;
}

.mulled_wine.button-ghost.hover-fill:hover,
.mulled_wine.button-ghost.hover-drop:after,
.mulled_wine.button-ghost.hover-side:after,
.mulled_wine.button-ghost.hover-scene:after,
.mulled_wine.button-ghost.hover-screen:after {
  background-color: #7d2133;
}

.vista_blue.button-ghost.hover-fill:hover,
.vista_blue.button-ghost.hover-drop:after,
.vista_blue.button-ghost.hover-side:after,
.vista_blue.button-ghost.hover-scene:after,
.vista_blue.button-ghost.hover-screen:after {
  background-color: #1bbc9b;
}

.black.button-ghost.hover-fill:hover,
.black.button-ghost.hover-drop:after,
.black.button-ghost.hover-side:after,
.black.button-ghost.hover-scene:after,
.black.button-ghost.hover-screen:after {
  background-color: #2a2a2a;
}

.grey.button-ghost.hover-fill:hover,
.grey.button-ghost.hover-drop:after,
.grey.button-ghost.hover-side:after,
.grey.button-ghost.hover-scene:after,
.grey.button-ghost.hover-screen:after {
  background-color: #5b7876;
}

.grey_light.button-ghost.hover-fill:hover,
.grey_light.button-ghost.hover-drop:after,
.grey_light.button-ghost.hover-side:after,
.grey_light.button-ghost.hover-scene:after,
.grey_light.button-ghost.hover-screen:after {
  background-color: #eeeeee;
}

.orange.button-ghost.hover-fill:hover,
.orange.button-ghost.hover-drop:after,
.orange.button-ghost.hover-side:after,
.orange.button-ghost.hover-scene:after,
.orange.button-ghost.hover-screen:after {
  background-color: #eb7f00;
}

.sky.button-ghost.hover-fill:hover,
.sky.button-ghost.hover-drop:after,
.sky.button-ghost.hover-side:after,
.sky.button-ghost.hover-scene:after,
.sky.button-ghost.hover-screen:after {
  background-color: #85a5cc;
}

.green.button-ghost.hover-fill:hover,
.green.button-ghost.hover-drop:after,
.green.button-ghost.hover-side:after,
.green.button-ghost.hover-scene:after,
.green.button-ghost.hover-screen:after {
  background-color: #588f27;
}

.yellow.button-ghost.hover-fill:hover,
.yellow.button-ghost.hover-drop:after,
.yellow.button-ghost.hover-side:after,
.yellow.button-ghost.hover-scene:after,
.yellow.button-ghost.hover-screen:after {
  background-color: #ffd10f;
}

.sandy_brown.button-ghost.hover-fill:hover,
.sandy_brown.button-ghost.hover-drop:after,
.sandy_brown.button-ghost.hover-side:after,
.sandy_brown.button-ghost.hover-scene:after,
.sandy_brown.button-ghost.hover-screen:after {
  background-color: #f2b872;
}

.purple.button-ghost.hover-fill:hover,
.purple.button-ghost.hover-drop:after,
.purple.button-ghost.hover-side:after,
.purple.button-ghost.hover-scene:after,
.purple.button-ghost.hover-screen:after {
  background-color: #9768d1;
}

.white.button-ghost.hover-fill:hover,
.white.button-ghost.hover-drop:after,
.white.button-ghost.hover-side:after,
.white.button-ghost.hover-scene:after,
.white.button-ghost.hover-screen:after {
  background-color: #fff;
}

.white.button-ghost.animate-false.hover-fill:hover,
.white.button-ghost.animate-false.hover-drop:hover,
.white.button-ghost.animate-false.hover-side:hover,
.white.button-ghost.animate-false.hover-scene:hover,
.white.button-ghost.animate-false.hover-screen:hover {
  color: #333 !important;
}

.button-ghost.hover-drop,
.button-ghost.hover-side,
.button-ghost.hover-scene,
.button-ghost.hover-screen,
.button-ghost.animate-true {
  background-color: transparent !important;
}

.animate-false.button-ghost.hover-drop:after,
.animate-false.button-ghost.hover-side:after,
.animate-false.button-ghost.hover-scene:after,
.animate-false.button-ghost.hover-screen:after {
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  content: "";
  position: absolute;
  z-index: -1;
  display: block;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.animate-false.button-ghost.hover-side:after {
  width: 0%;
  height: 100%;
}
.animate-false.button-ghost.hover-scene:after {
  width: 0;
  height: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.animate-false.button-ghost.hover-diagonal:after {
  width: 100%;
  height: 0;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.animate-false.button-ghost.hover-screen:after {
  width: 100%;
  height: 0;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.animate-false.button-ghost.hover-drop:hover:after {
  height: 100%;
}
.animate-false.button-ghost.hover-side:hover:after {
  width: 100%;
}
.animate-false.button-ghost.hover-screen:hover:after {
  height: 100%;
  opacity: 1;
}
.animate-false.button-ghost.hover-scene:hover:after {
  width: 100%;
  opacity: 1;
}

.animate-false.button-ghost.hover-fill:hover,
.animate-false.button-ghost.hover-drop:hover,
.animate-false.button-ghost.hover-side:hover,
.animate-false.button-ghost.hover-scene:hover,
.animate-false.button-ghost.hover-screen:hover {
  color: #fff;
}

.animate-false.grey_light.button-ghost.hover-fill:hover,
.animate-false.grey_light.button-ghost.hover-drop:hover,
.animate-false.grey_light.button-ghost.hover-side:hover,
.animate-false.grey_light.button-ghost.hover-scene:hover,
.animate-false.grey_light.button-ghost.hover-screen:hover {
  color: #999999;
}

.animate-false.white.button-ghost.hover-fill:hover,
.animate-false.white.button-ghost.hover-drop:hover,
.animate-false.white.button-ghost.hover-side:hover,
.animate-false.white.button-ghost.hover-scene:hover,
.animate-false.white.button-ghost.hover-screen:hover {
  color: #333;
}

.button.animate-true .btn-icon {
  opacity: 0;
  margin-left: 0;
}
.button.animate-true .txt {
  display: inline-block;
}

.button.animate-true.anim-type-ghost span {
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.button.animate-true.anim-type-reverse span {
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.button.animate-true.anim-type-ghost .txt {
  -webkit-transform: translateX(-7%);
  -ms-transform: translateX(-7%);
  transform: translateX(-7%);
}

.animate-true.anim-type-ghost:hover .txt {
  -webkit-transform: translateX(7%);
  -ms-transform: translateX(7%);
  transform: translateX(7%);
}

.animate-true.anim-type-ghost:hover .btn-icon {
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
  opacity: 1;
}

.button.animate-true.anim-type-reverse .btn-icon {
  opacity: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
  top: -100%;
  left: 0;
  font-size: 180%;
}

.button.animate-true.anim-type-reverse .btn-icon:before {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}

.button.animate-true.anim-type-reverse .txt {
  display: block;
}

.button.animate-true.anim-type-reverse:hover .btn-icon {
  top: 0;
}

.button.animate-true.anim-type-reverse:hover .txt {
  -webkit-transform: translateY(200%);
  -ms-transform: translateY(200%);
  transform: translateY(200%);
}

/*DROPCAP
/*=============*/

.nz-dropcap,
.nz-dropcap.empty {
  display: block;
  float: left;
  font-size: 44px;
  line-height: 28px;
  margin: 8px 8px 0 0;
}

.nz-dropcap.full {
  background-color: #222;
  color: #fff;
  border-radius: 3px;
  padding: 14px 10px 15px 10px;
}

/*HIGHLIGHT
/*=============*/

.nz-highlight {
  display: inline-block;
  padding: 0px 8px;
  color: #fff;
}

.nz-highlight > :last-child {
  margin-bottom: 0 !important;
}

/*ICON LIST
/*=============*/

.nz-i-list {
  list-style: none;
}

.nz-i-list li {
  display: table;
}

.nz-i-list.large li {
  margin-bottom: 15px;
}

.nz-i-list li:last-child {
  margin-bottom: 0px;
}

.nz-i-list > li > div {
  display: table-cell;
  padding: 0;
  margin: 0;
  vertical-align: top;
}

.nz-i-list span.icon {
  display: block;
  margin-right: 10px;
  margin-bottom: 0;
  margin-left: 0;
  margin-top: 6px;
  text-align: center;
  font-size: 12px;
  line-height: 12px !important;
}

.nz-i-list.none span.icon {
  margin-top: 6px;
}

.nz-i-list.square span.icon,
.nz-i-list.circle span.icon {
  margin-top: 2px;
}

/*types*/
.nz-i-list.circle span.icon,
.nz-i-list.square span.icon {
  font-size: 10px;
  width: 20px;
  height: 20px;
  border-radius: 0px;
  line-height: 20px !important;
}

.nz-i-list.circle span.icon {
  border-radius: 50%;
}

/*ICONS
/*=============*/

.nz-icon {
  margin: 5px;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  display: inline-block;
}

/*Sizes*/
.nz-icon.small {
  font-size: 14px;
  line-height: 14px;
}
.nz-icon.medium {
  font-size: 32px;
  line-height: 32px;
}
.nz-icon.large {
  font-size: 64px;
  line-height: 64px;
}

/*Sizes with types*/
.nz-icon.circle.small,
.nz-icon.square.small {
  width: 40px;
  height: 40px;
  line-height: 36px;
  border-width: 2px;
}
.nz-icon.circle.medium,
.nz-icon.square.medium {
  width: 65px;
  height: 65px;
  line-height: 59px;
  border-width: 2px;
}
.nz-icon.circle.large,
.nz-icon.square.large {
  width: 110px;
  height: 110px;
  line-height: 103px;
  border-width: 2px;
}

/*Types*/
.nz-icon.circle {
  border-radius: 50%;
}
.nz-icon.square {
  border-radius: 2px;
}
.nz-icon.circle,
.nz-icon.square {
  border-style: solid;
  border-color: transparent;
}

.nz-icon.animate-true {
  -webkit-animation: iconAnimate 1.4s infinite;
  animation: iconAnimate 1.4s infinite;
}

@-webkit-keyframes iconAnimate {
  0%,
  100% {
    -webkit-transform: translateY(5px);
  }
  50% {
    -webkit-transform: translateY(-5px);
  }
}

@keyframes iconAnimate {
  0%,
  100% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(-5px);
  }
}

/*SEPARATOR
/*=============*/

.nz-separator {
  height: 1px;
  border-bottom-width: 1px;
}
.nz-separator.solid {
  border-bottom-style: solid;
}
.nz-separator.dotted {
  border-bottom-style: dotted;
}
.nz-separator.dashed {
  border-bottom-style: dashed;
}

.sep-wrap.center .nz-separator {
  margin: 0 auto;
}
.sep-wrap.left .nz-separator {
  float: left;
}
.sep-wrap.right .nz-separator {
  float: right;
}

/*ICON SEPARATOR
/*=============*/

.nz-icon-separator {
  position: relative;
  width: 500px;
  max-width: 100%;
  margin: 0 auto;
}

.nz-icon-separator .icon {
  width: 60px;
  font-size: 28px;
  line-height: 28px;
  position: relative;
  z-index: 15;
  display: block;
  margin: 0 auto;
  text-align: center;
}

.nz-separator-left,
.nz-separator-right {
  width: 220px;
  height: 10px;
  position: absolute;
  display: block;
  left: 0;
  top: 50%;
}

.nz-separator-left span,
.nz-separator-right span {
  display: block;
  width: calc(100% - 10px);
  height: 1px;
}

.nz-separator-right {
  left: auto;
  right: 0;
}

.nz-separator-right span {
  float: right;
}

.nz-separator-left span + span,
.nz-separator-right span + span {
  margin-top: 3px;
}

.nz-separator-left span + span {
  float: right;
}

.nz-separator-right span + span {
  float: left;
}

/*SOCIAL LINKS
/*=============*/

.social-links {
  height: 40px;
}
.social-links.right {
  text-align: right;
}
.social-links.left {
  text-align: left;
}
.social-links.center {
  text-align: center;
}

.social-links a {
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 0px;
  font-size: 14px;
  text-align: center;
  color: #ffffff !important;
  background-color: #444444;
  margin: 0 4px 3px 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
}

.footer .widget .social-links a {
  color: #ffffff !important;
  background-color: #39393a;
}

.social-links a:hover,
.footer .widget .social-links a:hover {
  color: #ffffff !important;
}

.social-links a:last-child {
  margin-right: 0px;
}

/*VIDEO, AUDIO EMBED
/*=============*/

/*Flexible embeds*/
.flex-mod {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.flex-mod iframe,
.flex-mod object,
.flex-mod embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.soundcloud iframe {
  max-width: 100%;
}

.wp-video-shortcode,
.wp-audio-shortcode {
  max-width: 100% !important;
  height: auto;
}

.video-modal {
  position: relative;
  display: block;
}

.video-modal:hover:before {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 1);
}

.video-modal .ninzio-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  z-index: 10;
}

.video-modal:hover .ninzio-overlay {
  background-color: rgba(0, 0, 0, 0.6);
}

.video-modal .video-icon {
  width: 82px;
  height: 82px;
  margin: 0 auto 15px auto;

  background-repeat: no-repeat;
  background-position: 50% 50%;
  content: "";
}

.video-modal .video-title {
  color: #fff;
  font-size: 18px;
  line-height: 28px;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
}

.video-modal:hover:after {
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
}

.video-modal img {
  margin: 0 auto;
  display: block;
}

/*RECENT TWEETS
/*=============*/

.nz-tweets {
  position: relative;
  padding-top: 70px;
  text-align: center;
}

.nz-tweets .follow {
  font-size: 18px;
  line-height: 28px;
}

.nz-tweets:before {
  display: block;
  content: "\e76d";
  line-height: 50px;
  font-size: 42px;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
  color: #fff;
}

.nz-tweets ul {
  list-style: none;
  text-align: center;
}

.nz-tweets a,
.nz-tweets p {
  color: inherit;
  font-size: 18px;
  line-height: 28px;
  margin: 0;
}

.nz-tweets a {
  color: inherit !important;
}

.nz-tweets .owl-controls {
  display: block;
  text-align: center;
  margin-top: 30px;
}

.nz-tweets .owl-buttons div {
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  border: 2px solid #e0e0e0;
  color: #e0e0e0;
  position: relative;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.nz-tweets .owl-buttons div:hover {
  border: 2px solid #777777;
  color: #777777;
}

.nz-tweets .owl-buttons div:first-child {
  margin-right: 4px;
}

.nz-tweets .owl-buttons div:first-child:after,
.nz-tweets .owl-buttons div:last-child:after {
  position: absolute;
  content: "\e914";
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 16px;
  text-align: center;
  line-height: 28px;
}

.nz-tweets .owl-buttons div:last-child:after {
  content: "\e917";
}

/*CONTENTBOX
/*=============*/

.nz-content-box > .nz-box {
  width: 100%;
  position: relative;
  margin-bottom: 45px;
}

.nz-content-box .box-icon {
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 200ms;
  transition: all 200ms;
  font-size: 46px;
  line-height: 46px;
  text-align: center;
  position: relative;
  top: 4px;
}

.nz-content-box .back_active .box-icon,
.nz-content-box .bord_active .box-icon {
  font-size: 28px;
  line-height: 28px;
  text-align: center;
  position: relative;
  top: 21px;
}

.nz-content-box .box-icon-wrap {
  position: absolute;
  margin: 0;
  height: 60px;
  width: 60px;
  border-radius: 60px;
  left: 0px;
  top: 5px;
}

.nz-content-box.right .box-icon-wrap {
  right: 15px;
  left: auto !important;
}

.nz-content-box .back_active .box-icon-wrap,
.nz-content-box .bord_active .box-icon-wrap {
  top: 10px;
  left: 15px;
  height: 70px;
  width: 70px;
  border-radius: 70px;
}

.nz-content-box .back_active .box-icon-wrap:before,
.nz-content-box .bord_active .box-icon-wrap:before {
  content: "";
  display: block;
  height: 72px;
  width: 72px;
  border-radius: 72px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -36px;
  margin-top: -36px;
  opacity: 0;
  -webkit-transform: scale(1.4);
  transform: scale(1.4);
  -webkit-transition: all 700ms cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: all 700ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.nz-content-box.version2 .back_active .box-icon-wrap:before,
.nz-content-box.version2 .bord_active .box-icon-wrap:before {
  content: "";
  display: block;
  height: 122px;
  width: 122px;
  border-radius: 122px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -61px;
  margin-top: -61px;
  opacity: 0;
  -webkit-transform: scale(1.4);
  transform: scale(1.4);
  -webkit-transition: all 700ms cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: all 700ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.nz-content-box .back_active:hover .box-icon-wrap:before,
.nz-content-box .bord_active:hover .box-icon-wrap:before {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.nz-content-box .box-data {
  padding-left: 70px;
}

.nz-content-box .box-title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  position: relative;
}

.nz-content-box.right .box-title {
  text-align: right;
}

.nz-content-box.version2 .box-title {
  padding-left: 0px !important;
  padding-bottom: 15px;
}

.nz-content-box .back_active .box-data,
.nz-content-box .bord_active .box-data {
  padding-left: 100px;
}

.nz-content-box.right .box-data {
  padding-left: 0px;
  padding-right: 80px;
}

.nz-content-box.right .back_active .box-data,
.nz-content-box.right .bord_active .box-data {
  padding-left: 0px;
  padding-right: 100px;
}

.nz-content-box .nz-box > .nz-box-wrap {
  padding: 5px 0px;
}

.nz-box > .nz-box-wrap > a {
  color: inherit !important;
}

.box-more {
  display: inline-block;
  text-transform: none;
  margin-top: 10px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #cccccc !important;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  position: relative;
}

.box-more:after {
  content: "";
  position: absolute;
  width: 100% !important;
  height: 2px;
  bottom: 0px;
  left: 50%;
  background-color: #eee;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.nz-content-box.version2 {
  text-align: center;
}

.nz-content-box.version2 .box-data {
  padding-left: 0px !important;
}

.nz-content-box.version2 .box-icon-wrap {
  position: relative;
  margin: 0 auto 30px auto;
  height: 80px;
  width: 80px;
  border-radius: 80px;
  left: 0px;
  top: 0px;
}

.nz-content-box.version2 .back_active .box-icon-wrap,
.nz-content-box.version2 .bord_active .box-icon-wrap {
  height: 120px;
  width: 120px;
  border-radius: 120px;
}

.nz-content-box.version2 .box-icon {
  font-size: 48px;
  line-height: 48px;
  top: 19px;
}

.nz-content-box.version2 .box-icon {
  font-size: 48px;
  line-height: 48px;
  top: 19px;
}

.nz-content-box.version2 .back_active .box-icon,
.nz-content-box.version2 .bord_active .box-icon {
  font-size: 42px;
  line-height: 42px;
  top: 39px;
}

/*TAGLINE
/*=============*/

.nz-tagline {
  width: 100%;
  padding: 40px 0;
  position: relative;
  background-repeat: no-repeat;
  background-position: left top;
  -webkit-background-size: cover;
  -ms-background-size: cover;
  background-size: cover;
}

.nz-tagline img {
  position: absolute;
  bottom: -40px;
  left: 40px;
}

.nz-tagline .container {
  text-align: center;
  max-width: 100% !important;
}

.nz-tagline .container > .tagline-title {
  font-size: 18px;
  line-height: 28px;
  padding-right: 40px;
  font-weight: 700;
  text-transform: uppercase;
}

.nz-tagline .container > .tagline-title,
.nz-tagline .container > .button,
.nz-tagline .container > .tagline-icon {
  vertical-align: middle;
  display: block;
  margin: 0 auto 15px auto !important;
}

.nz-tagline .container > :last-child {
  margin-bottom: 0 !important;
}

.nz-tagline .container > .tagline-icon {
  width: 46px;
  height: 46px;
  line-height: 46px;
  font-size: 46px;
  text-align: center;
  margin-right: 30px;
}

.nz-tagline .container .button {
  margin-bottom: 0;
  max-width: 250px;
}

/*TABS
/*=============*/

.nz-tabs .tabset {
  position: relative;
  z-index: 20;
}

.nz-tabs.horizontal .tabset {
  border-bottom: 1px solid #e0e0e0;
}

.tabset .tab {
  display: block;
  text-align: center;
  padding: 16px 30px;
  color: #999;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.tabset .tab.active {
  color: #333333;
  box-shadow: inset 0 0 0 1px #e0e0e0;
  position: relative;
}

.tabset .tab.active:after {
  content: "";
  display: block;
  bottom: -1px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: calc(100% - 2px);
  height: 2px;
  position: absolute;
  background-color: #ffffff !important;
}

.tabset .tab > span {
  display: inline-block;
  padding-right: 10px;
}

.nz-tabs.full-true .tabset {
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
}

.nz-tabs.full-true .tab {
  display: inline-block;
  float: none !important;
  padding: 20px 60px;
  font-size: 18px;
  z-index: 10;
}

.nz-tabs.full-true.tab-full .tab {
  display: block;
}

.tabset .tab:last-child {
  margin-bottom: 0px;
}

.tabs-container {
  width: 100%;
  height: auto;
  position: relative;
  z-index: 10;
}

.tabs-container .tab-content {
  padding-top: 40px;
}

.nz-tabs.full-true .tab-content {
  padding-top: 80px;
}

.tabs-container .tab-content:not(:first-child) {
  display: none;
}

/*TABLES
/*=============*/

.nz-table td,
.nz-table th {
  border: 1px solid #eaeaea;
  background-color: #fff;
  padding: 15px;
}

.nz-table th {
  font-size: 16px;
  background-color: #f7f7f7;
}

.nz-table tbody tr:nth-child(2n + 2) td {
  background-color: #f7f7f7;
}

/*ACCORDION
/*=============*/

.nz-accordion .toggle-title {
  cursor: pointer;
  width: 100%;
  margin-bottom: 5px;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 70px 15px 30px;
  color: #999999;
  background-color: #f7f7f7;
  border-radius: 200px;
}

.nz-accordion .toggle-title.with-icon {
  padding: 15px 70px 15px 60px;
}

.nz-accordion .toggle-icon,
.nz-accordion .ico-icon {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -25px;
  right: 10px;
  cursor: pointer;
}

.nz-accordion .ico-icon {
  right: auto;
  left: 10px;
  line-height: 50px;
  text-align: center;
  color: #999 !important;
}

.nz-accordion .toggle-icon:before,
.nz-accordion .toggle-icon:after {
  content: "";
  display: block;
  position: absolute;
  width: 12px;
  height: 2px;
  background-color: #999999;
  top: 50%;
  left: 50%;
  margin-left: -6px;
  margin-top: -1px;
  -webkit-transition: transform 0.25s ease-out;
  -ms-transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
}

.nz-accordion .toggle-icon:after {
  height: 12px;
  width: 2px;
  margin-top: -6px;
  margin-left: -1px;
}

.nz-accordion .toggle-title.active {
  color: #333333;
}

.nz-accordion .toggle-title.active .toggle-icon:before {
  transform: rotate(180deg);
}
.nz-accordion .toggle-title.active .toggle-icon:after {
  transform: rotate(90deg);
}

.nz-accordion .toggle-content {
  padding: 30px 30px 35px 30px;
  margin-bottom: 0px;
  position: relative;
}

/*TIMER
/*=============*/

.nz-timer ul {
  list-style: none;
  margin: 0;
}

.nz-timer li {
  width: 100%;
  display: block;
  text-align: center;
  position: relative;
  margin-bottom: 0px;
  padding: 15px;
}

.nz-timer li .timer-wrap {
  width: 150px;
  margin: 0 auto;
}

.nz-timer span {
  display: block;
  margin: 0 auto;
  font-size: 56px;
  line-height: 66px;
  font-weight: 600;
  color: #333;
  position: relative;
}
.nz-timer p {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  padding-top: 10px;
  color: #999999;
  text-transform: uppercase;
}

/*ALERT MESSAGE
/*=============*/

.alert {
  padding: 20px 70px 20px 20px;
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-radius: 0px;
  margin-bottom: 25px;
  font-weight: 600;
}

.alert.note {
  border-color: #e0e0e0;
  background: #f7f7f7;
  color: #231f20;
}

.alert.success {
  border-color: #53a54d;
  background: #ade978;
  color: #245920;
}

.alert.warning {
  border-color: #ffb700;
  background: #fedd89;
  color: #794d19;
}

.alert.error {
  border-color: #e02f0d;
  background: #f9aa9b;
  color: #b0280d;
}

.alert.information {
  border-color: #017bb0;
  background: #92d9f8;
  color: #035e86;
}

.alert .close-alert {
  position: absolute;
  top: 50%;
  right: 20px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-top: -15px;
  color: #ffffff;
}

.alert.note .close-alert {
  color: #231f20;
}
.alert.error .close-alert {
  color: #b0280d;
}
.alert.success .close-alert {
  color: #245920;
}
.alert.information .close-alert {
  color: #035e86;
}
.alert.warning .close-alert {
  color: #c85e10;
}

/*ICON-PROGRESS-BAR
/*=============*/

.nz-icon-progress {
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
}

.nz-icon-progress,
.nz-icon-progress.center {
  text-align: center;
}

.nz-icon-progress.left {
  text-align: left;
}

.nz-icon-progress.right {
  text-align: right;
}

.nz-icon-progress span.icon {
  margin: 0 10px 10px 0;
  color: #999999;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50px;
  font-size: 28px;
  text-align: center;
  display: inline-block;
}

.nz-icon-progress span.icon:last-child {
  margin-right: 0;
}

/*PROGRESS
/*=============*/

.nz-progress {
  width: 100%;
  max-width: 100%;
  padding-top: 1px;
}

.nz-progress .bar {
  margin-bottom: 35px;
}

.nz-progress .bar-line {
  background-color: #dddddd;
  background-repeat: repeat;
  background-position: 0 0;
  width: 100%;
  height: 7px;
  border-radius: 10px;
  position: relative;
}

.nz-progress .nz-line {
  height: inherit;
  text-align: right;
  visibility: hidden;
  position: relative;
  border-radius: 10px;
}

.bar-text {
  position: relative;
}

.nz-progress .progress-percent,
.nz-progress .progress-title {
  display: block;
  line-height: inherit;
  font-size: inherit;
  color: #333;
  font-size: 16px;
  font-weight: 600;
  line-height: 36px;
}

.nz-progress .progress-percent {
  position: absolute;
  left: 0;
  top: 50%;
  visibility: hidden;
  opacity: 0;
  width: 46px;
  text-align: center;
  -webkit-transition-delay: 100ms;
  -webkit-transition-property: opacity;
  transition-delay: 100ms;
  transition-property: opacity;
  -webkit-transform: translate(-50%, -60%);
  -ms-transform: translate(-50%, -60%);
  transform: translate(-50%, -60%);
  color: #777777;
  font-size: 18px;
  line-height: 18px;
}

.nz-progress .progress-percent.visible {
  opacity: 1;
}

.nz-progress .progress-percent:after {
  display: inline-block;
  content: "%";
  line-height: inherit;
  font-size: inherit;
}

/*STEPS
/*=============*/

.nz-steps .stepset {
  position: relative;
  z-index: 20;
  text-align: center;
}

.stepset .step {
  display: inline-block;
  float: none !important;
  text-align: center;
  padding: 120px 70px 15px 70px;
  color: #777;
  cursor: pointer;
  position: relative;
  font-size: 16px;
  text-transform: uppercase;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.nz-steps:not(.step-full) .stepset .step:after {
  display: block;
  position: absolute;
  top: 50px;
  width: 60px;
  height: 1px;
  content: "";
  right: -30px;
  background-color: #f0f0f0;
}

.stepset .step:last-child:after {
  display: none !important;
}

.stepset .step-title {
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  line-height: 100px;
  font-size: 22px;
  font-weight: 600;
  position: absolute;
  left: 50%;
  top: 0px;
  margin-left: -50px;
  background-color: #f0f0f0;
  color: #999999;
}

.stepset .active .step-title {
  color: #ffffff;
}

.stepset .title {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  text-transform: uppercase;
  color: #999999;
}

.stepset .step.active:after {
  color: #fff !important;
}

.stepset .step:hover {
  color: #444;
}

.stepset .step:last-child {
  margin-bottom: 0px;
}

.steps-container {
  width: 100%;
  height: auto;
  position: relative;
  z-index: 10;
}

.steps-container .step-content {
  background-color: #fff;
  padding: 50px 0 0 0;
}

.steps-container .step-content:not(:first-child) {
  display: none;
}

/*CIRCLE PROGRESS
/*=============*/

.nz-circle-progress,
.nz-circle-progress.center {
  text-align: center;
}

.nz-circle-progress.left {
  text-align: left;
}

.nz-circle-progress.right {
  text-align: right;
}

.nz-circle {
  opacity: 0;
}

.nz-circle {
  height: auto;
  display: block;
  display: inline-block;
  width: 240px;
  margin: 0 0px 25px 0px;
  padding: 0 15px;
  position: relative;
}

.nz-circle .circle {
  position: relative;
  text-align: center;
  width: inherit;
  font-weight: bold;
  margin: 0 auto;
  font-size: 3em;
  display: table;
}
.nz-circle .circle canvas {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.circle-counter-percentage {
  font-weight: bold;
}

.nz-circle .title {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  margin-top: 0px;
  padding: 0 35px 0 35px;
  font-size: 42px;
  line-height: 64px;
}

.nz-circle .circle-data:not(:empty) {
  padding-top: 10px;
}

/*COUNTER
/*=============*/

.nz-count {
  width: 100%;
  display: block;
  position: relative;
  margin-bottom: 25px;
  text-align: center;
}

.nz-count-wrap {
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
}

.count-border {
  width: 50%;
  height: 4px;
  border-top-width: 1px;
  border-top-style: solid;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: block;
  position: absolute;
  bottom: -25px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.nz-counter .nz-count:last-child .count-border {
  display: none;
}

.count-value {
  font-size: 56px;
  font-weight: 600;
  line-height: 56px;
  position: relative;
  display: inline-block;
  margin-bottom: 5px;
}

.count-title {
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  color: #999;
  display: block;
  text-transform: uppercase;
}

/*TESTIMONIALS
/*=============*/

.nz-testimonials {
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
}

.nz-persons .owl-pagination,
.nz-clients .owl-pagination {
  display: none !important;
}

.nz-testimonials .testimonial {
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 50px;
  position: relative;
}

.nz-testimonials .test-wrap {
  padding: 70px 30px 30px 30px;
  background-color: #f5f5f5;
  position: relative;
}

.nz-testimonials .text {
  font-size: 14px;
  line-height: 24px;
  position: relative;
  text-align: center;
}

.nz-testimonials .test-info {
  position: relative;
  padding-top: 40px;
}

.nz-testimonials .title {
  font-size: 16px;
  line-height: 26px;
  font-style: italic;
  display: block;
  position: relative;
  color: #999;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.nz-testimonials .name {
  font-size: 18px;
  color: #333;
  line-height: 28px;
  font-weight: 600;
  text-transform: uppercase;
}

.nz-testimonials .test-data {
  padding-bottom: 20px;
  text-align: center;
}

.nz-testimonials img {
  max-width: 92px;
  max-height: 92px;
  position: absolute;
  top: -46px;
  left: 50%;
  margin-left: -46px;
  border: 4px solid #ffffff;
  border-radius: 500px;
}

.nz-testimonials .owl-controls .owl-buttons div {
  margin-top: 0px;
}

.nz-testimonials[data-bullets="false"] .owl-pagination,
.nz-testimonials[data-arrows="false"] .owl-buttons {
  display: none;
}

.nz-testimonials .owl-pagination {
  padding-top: 10px;
}

/*CLIENTS
/*=============*/

.nz-clients {
  padding-left: 40px;
  padding-right: 40px;
  margin-left: 0px;
  margin-right: 0px;
}

.nz-clients .client a {
  display: block;
  text-decoration: none;
}

.nz-clients .client {
  text-align: center;
  margin: 0;
}

.nz-clients .client-inner {
  height: 150px;
  line-height: 150px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  position: relative;
}

.nz-clients .client-inner:before {
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #e0e0e0;
  content: "";
  display: block;
}

.nz-clients .owl-item:first-child .client-inner:before {
  display: none;
}

.nz-clients[data-columns="6"] {
  height: 100px;
  line-height: 100px;
}

.nz-clients .client-inner a {
  display: block;
  position: relative;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.nz-clients .client-inner a:hover {
  opacity: 0.7;
}

.nz-clients .client img {
  margin-bottom: 0;
  vertical-align: middle;
  max-height: 80%;
  max-width: 80%;
}

.nz-clients .owl-controls .owl-buttons {
  right: 5px;
}

.nz-clients .owl-controls .owl-buttons {
  width: 100%;
  top: 50%;
  margin-top: -20px;
  left: 0;
  right: 0;
}

.nz-clients .owl-controls .owl-buttons div:last-child {
  float: right;
}

/*PERSONS
/*=============*/

.nz-persons {
  margin-right: -15px;
  margin-left: -15px;
}

.nz-persons .person {
  width: 100%;
  margin-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.nz-persons .person .ninzio-overlay {
  background-color: rgba(255, 255, 255, 0.7);
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  top: 10px;
  left: 10px;
}

.nz-persons .person .img {
  margin: 0 auto;
  position: relative;
}

.nz-persons .person-body {
  text-align: center;
  border-top: none !important;
  padding: 15px 0px 10px 0px;
  width: 100%;
  position: relative;
  z-index: 10;
}

.nz-persons .person .name {
  font-size: 16px;
  line-height: 26px;
  position: relative;
  color: #333;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  display: inline-block;
}

.nz-persons .person .title {
  font-size: 16px;
  line-height: 26px;
  color: #bbb;
  padding: 0;
  position: relative;
  font-style: italic;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.nz-persons .social-links {
  margin-top: 15px;
}

#nz-content .nz-persons .social-links a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 5px 5px 0;
  color: #ffffff !important;
  font-size: 14px;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}

.nz-persons .owl-controls .owl-buttons div {
  margin-top: -70px;
}

/*POPUP
/*=============*/

.nz-popup {
  display: inline-block;
  position: relative;
  z-index: 999;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 24px;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
}

.nz-popup-border {
  display: block;
  position: absolute;
  left: -13px;
  top: -13px;
  margin: 0 auto;
  border-radius: 100px;
  background-color: transparent;
  opacity: 0.1;
  width: 50px;
  height: 50px;
  border-width: 3px;
  border-style: solid;
  border-radius: 100px;
  -webkit-animation: plusAnim 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55)
    infinite;
  -moz-animation: plusAnim 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite;
  animation: plusAnim 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite;
  -webkit-backface-visibility: hidden; /* Chrome, Safari, Opera */
  backface-visibility: hidden;
  z-index: -1;
}

.nz-popup:before {
  color: #ffffff;
}

@-webkit-keyframes plusAnim {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0;
  }
  8% {
    -webkit-transform: scale(0, 0);
    opacity: 0;
  }
  15% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 1;
  }
  30% {
    -webkit-transform: scale(0.5, 0.5);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}

@-moz-keyframes plusAnim {
  0% {
    -moz-transform: scale(0, 0);
    opacity: 0;
  }
  8% {
    -moz-transform: scale(0, 0);
    opacity: 0;
  }
  15% {
    -moz-transform: scale(0.1, 0.1);
    opacity: 1;
  }
  30% {
    -moz-transform: scale(0.5, 0.5);
    opacity: 1;
  }
  100% {
    -moz-transform: scale(1, 1);
    opacity: 0;
  }
}

@-ms-keyframes plusAnim {
  0% {
    -ms-transform: scale(0, 0);
    opacity: 0;
  }
  8% {
    -ms-transform: scale(0, 0);
    opacity: 0;
  }
  15% {
    -ms-transform: scale(0.1, 0.1);
    opacity: 1;
  }
  30% {
    -ms-transform: scale(0.5, 0.5);
    opacity: 1;
  }
  100% {
    opacity: 0;
    -ms-transform: scale(1, 1);
  }
}

@keyframes plusAnim {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  8% {
    transform: scale(0, 0);
    opacity: 0;
  }
  15% {
    transform: scale(0.1, 0.1);
    opacity: 1;
  }
  30% {
    transform: scale(0.5, 0.5);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/*MEDIA SLIDER
/*=============*/

.nz-media-slider {
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}
.nz-media-slider .slides {
  list-style: none;
  margin: 0;
  padding: 0;
}
.nz-media-slider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
  margin: 0;
  padding: 0;
}
.nz-media-slider .slides > li > * {
  margin: 0;
}
.nz-media-slider .slides img {
  display: block;
}
.flex-pauseplay span {
  text-transform: capitalize;
}

.slides:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
html[xmlns] .nz-media-slider .slides {
  display: block;
}
* html .nz-media-slider .slides {
  height: 1%;
}

.no-js .slides > li:first-child {
  display: block;
}

.flex-viewport {
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}
.nz-media-slider .loading .flex-viewport {
  max-height: 300px;
}

.nz-media-slider .flex-direction-nav {
  list-style: none;
  margin: 0;
  height: 0;
}

/* Control Nav */
.nz-media-slider .flex-control-nav {
  width: 100%;
  text-align: center;
  list-style: none;
  z-index: 50;
  margin: 0;
  opacity: 1;
  padding: 15px 0 15px 0;
  position: absolute;
  bottom: 0;
  left: 0;
}
.nz-media-slider .flex-control-nav li {
  margin: 0 4px;
  display: inline-block;
}
.nz-media-slider .flex-control-paging li a {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  margin: 0 5px 0px 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-indent: 100%;
  overflow: hidden;
  cursor: pointer;
}

.nz-media-slider .flex-direction-nav a {
  position: absolute;
  top: 50%;
  left: -40px;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 40px;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0;
  display: block;
  z-index: 5;
}

.nz-media-slider .flex-direction-nav a.flex-next {
  left: auto;
  right: -40px;
}

.nz-media-slider:hover .flex-direction-nav a.flex-next {
  right: 15px;
  opacity: 1;
}
.nz-media-slider:hover .flex-direction-nav a.flex-prev {
  left: 15px;
  opacity: 1;
}

.nz-media-slider .flex-direction-nav a:before {
  display: block;
  top: 0;
  left: 0;
  height: inherit;
  width: inherit;
  font-size: 16px;
  line-height: 40px !important;
  content: "\e910";
  color: #ffffff;
}

.nz-media-slider .flex-direction-nav a.flex-next:before {
  content: "\e913";
}

/*PRICING TABLE
/*=============*/

.nz-pricing-table:after {
  content: "";
  display: table;
  clear: both;
}

.nz-pricing-table > .column {
  width: 100%;
  margin-bottom: 25px;
  text-align: center;
}

.nz-pricing-table > .column .title {
  width: 100%;
  max-width: 100%;
  margin: 0 auto 0 auto;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 2px;
  position: relative;
  z-index: 6;
  padding: 30px 0 15px 0;
  text-transform: uppercase;
  color: #777777;
}

.nz-pricing-table > .column .tariff {
  margin-top: 10px;
  display: block;
  color: #999;
  font-size: 16px;
  line-height: 26px;
  font-style: italic;
  font-family: Georgia, "Times New Roman", Times, serif;
  text-align: center;
}

.nz-pricing-table > .column img {
  margin-top: 25px;
}

.nz-pricing-table > .column .pricing {
  padding: 30px 20px 30px 20px;
  text-align: center;
  display: table;
  width: 150px;
  height: 150px;
  border-radius: 150px;
  margin: 0 auto;
  position: relative;
  background-color: #f9f9f9;
}

.nz-pricing-table > .column .pricing:before {
  width: calc(100% + 2px);
  height: 4px;
  bottom: -4px;
  left: -1px;
  content: "";
  display: block;
  position: absolute;
  opacity: 0;
}

.nz-pricing-table > .column.highlight-true .pricing:before {
  opacity: 1;
}

.nz-pricing-table > .column .column-inner {
  padding: 0 30px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #f5f5f5;
}

.nz-pricing-table > .column .pricing .plan {
  font-weight: 400;
}

.nz-pricing-table .c-body {
  margin-top: 20px;
  border-top: 1px dashed #eee;
  padding-top: 20px;
  padding-bottom: 10px;
  background-color: #ffffff;
}

.nz-pricing-table .c-row {
  padding: 5px 0px;
  font-size: 14px;
  line-height: 24px;
  position: relative;
}

.nz-pricing-table .price {
  font-size: 46px;
  line-height: 46px;
  display: block;
  position: relative;
}

.nz-pricing-table .currency {
  font-size: 46px;
  line-height: 46px;
  display: inline-block;
  vertical-align: top;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.nz-pricing-table .c-foot {
  padding: 15px 20px 30px 20px;
}

.nz-pricing-table .button,
.nz-pricing-table .button:hover {
  margin: 0;
}

.nz-pricing-table > .column.highlight-true .c-foot a,
.nz-pricing-table > .column.highlight-true .price,
.nz-pricing-table > .column.highlight-true .tariff,
.nz-pricing-table > .column.highlight-true .currency {
  color: #fff !important;
}

/*PROJECTS
/*=============*/

.blog-layout-wrap,
.shop-layout-wrap,
.projects-layout-wrap {
  padding: 90px 0 70px 0;
  background-color: #ffffff;
  position: relative;
}

.small-image-nogap.projects-layout-wrap.filter-false,
.medium-image-nogap.projects-layout-wrap.filter-false,
.tax-projects-tag .small-image-nogap.projects-layout-wrap,
.tax-projects-category .small-image-nogap.projects-layout-wrap,
.tax-projects-tag .medium-image-nogap.projects-layout-wrap,
.tax-projects-category .medium-image-nogap.projects-layout-wrap,
.tax-projects-tag .small-image.projects-layout-wrap,
.tax-projects-category .small-image.projects-layout-wrap,
.tax-projects-tag .medium-image.projects-layout-wrap,
.tax-projects-category .medium-image.projects-layout-wrap,
.tax-projects-tag .large-image.projects-layout-wrap,
.tax-projects-category .large-image.projects-layout-wrap {
  padding-top: 0;
}

.single .shop-layout-wrap.nopadding-true {
  padding-bottom: 0px;
}

.single .projects-layout-wrap {
  padding: 70px 0 0px 0 !important;
}

.single .nz-related-projects {
  padding: 70px 0 70px 0;
  background-color: #f7f7f7;
}

.loop .projects-layout {
  position: relative;
}

.ajax-loading-wrap,
.ajax-loading-error {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #ffffff;
  left: 0;
  top: 0;
  z-index: 50;
}

.ajax-loading-error .alert {
  max-width: 100%;
  width: 50%;
  margin: 0 auto;
}

.ajax-loading-wrap .load-bar {
  height: 4px;
  width: 290px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  background-color: #eeeeee;
}

.ajax-loading-wrap .load-text {
  text-align: center;
  padding-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 5px;
  color: #c0c0c0;
  font-weight: 600;
}

.ajax-loading-wrap .load-bar:before {
  display: block;
  position: absolute;
  content: "";
  left: -40px;
  width: 40px;
  height: 4px;
  -webkit-animation: loading 1s linear infinite;
  animation: loading 1s linear infinite;
}
@keyframes loading {
  0% {
    left: -40px;
  }
  100% {
    left: 100%;
  }
}

@-webkit-keyframes loading {
  0% {
    left: -40px;
  }
  100% {
    left: 100%;
  }
}

.ninzio-navigation {
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  min-height: 38px;
  opacity: 1;
}

.ninzio-navigation.temp-hide {
  opacity: 0;
}

#projects-load-more {
  margin-top: 70px;
  display: block;
  outline: none;
  background: #fafafa;
  box-shadow: inset 0 0 0 1px #e0e0e0;
}

#projects-load-more .project-loader {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 300px;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background-color: #fafafa;
}

#projects-load-more.disable {
  cursor: default;
}

#projects-load-more .project-loader.temp-hide,
#projects-load-more.temp-hide {
  opacity: 0;
}

#projects-load-more .project-loader:before {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  font-size: 10px;
  margin: -15px 0 0 -15px;
  border-top: 2px solid #e0e0e0;
  border-right: 2px solid #e0e0e0;
  border-bottom: 3px solid #e0e0e0;
  -webkit-animation: loadProjects 0.3s infinite linear;
  animation: loadProjects 0.3s infinite linear;
}

@-webkit-keyframes loadProjects {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loadProjects {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loop .projects-layout .projects .nz-thumbnail,
.nz-recent-projects .projects .nz-thumbnail {
  background-color: #f7f7f7;
  text-align: center;
}

.nz-recent-projects,
.loop .projects-layout {
  padding-left: 2px;
  padding-right: 2px;
}

.loop .projects-layout .projects,
.nz-recent-projects .projects {
  padding: 0 2px 4px 2px;
}

.wide .loop > .container {
  width: 100%;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.loop .projects-layout .projects,
.nz-recent-projects .projects {
  overflow: hidden;
  max-width: 100%;
  display: block;
}

.loop .projects-layout .projects-post {
  -webkit-transition: opacity 300ms, height 300ms;
  transition: opacity 300ms, height 300ms;
  opacity: 1;
  margin: 0px auto;
}

.loop .small-standard .projects .ninzio-overlay,
.loop .medium-standard .projects .ninzio-overlay,
.loop .large-standard .projects .ninzio-overlay,
.nz-recent-projects.small-standard .projects .ninzio-overlay,
.nz-recent-projects.medium-standard .projects .ninzio-overlay,
.nz-recent-projects.large-standard .projects .ninzio-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}

.nz-related-products-separator {
  position: relative;
  text-align: center;
  margin-top: 70px;
}

.nz-related-products-separator:before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  margin-top: -0.5px;
  content: "";
  background-color: #e0e0e0;
}

.nz-related-products-separator h3 {
  display: inline-block;
  z-index: 10;
  position: relative;
  margin: 0;
  padding: 5px 10px;
  background-color: #e0e0e0;
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  line-height: 24px;
}

.nz-related-products-separator h3:after,
.nz-related-products-separator h3:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  top: 0;
}

.nz-related-products-separator h3:before {
  left: -10px;
  border-width: 0 0 34px 10px;
  border-color: transparent transparent #e0e0e0 transparent;
}

.nz-related-products-separator h3:after {
  right: -10px;
  border-width: 34px 10px 0 0;
  border-color: #e0e0e0 transparent transparent transparent;
}

.nz-related-products .product {
  padding-bottom: 20px;
  margin-bottom: 0px !important;
}

.loop .small-image .projects .nz-thumbnail a,
.loop .medium-image .projects .nz-thumbnail a,
.loop .large-image .projects .nz-thumbnail a,
.loop .small-image-nogap .projects .nz-thumbnail a,
.loop .medium-image-nogap .projects .nz-thumbnail a,
.nz-recent-projects.small-image .nz-thumbnail a,
.nz-recent-projects.medium-image .nz-thumbnail a,
.nz-recent-projects.large-image .nz-thumbnail a,
.nz-recent-projects.small-image-nogap .nz-thumbnail a,
.nz-recent-projects.medium-image-nogap .nz-thumbnail a,
.nz-related-projects .nz-thumbnail a {
  display: block;
}

.loop .small-image .projects .project-info-tab,
.loop .medium-image .projects .project-info-tab,
.loop .large-image .projects .project-info-tab,
.loop .small-image-nogap .projects .project-info-tab,
.loop .medium-image-nogap .projects .project-info-tab,
.nz-recent-projects.small-image .project-info-tab,
.nz-recent-projects.medium-image .project-info-tab,
.nz-recent-projects.large-image .project-info-tab,
.nz-recent-projects.small-image-nogap .project-info-tab,
.nz-recent-projects.medium-image-nogap .project-info-tab,
.nz-related-projects .project-info-tab {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 40px 15px 10px 15px;
  z-index: 5;
  border-bottom: 1px solid #eee;
  background-color: #ffffff !important;
  text-align: center;
  bottom: -100%;
  -webkit-backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.4s;
  -webkit-transition: bottom 0.4s;
  transition: bottom 0.4s;
}

.nz-related-projects.small-image-nogap .project-info-tab,
.nz-related-projects.medium-image-nogap .project-info-tab {
  background-color: #ffffff !important;
}

.loop .small-image .projects .nz-thumbnail:hover .project-info-tab,
.loop .medium-image .projects .nz-thumbnail:hover .project-info-tab,
.loop .large-image .projects .nz-thumbnail:hover .project-info-tab,
.loop .small-image-nogap .projects .nz-thumbnail:hover .project-info-tab,
.loop .medium-image-nogap .projects .nz-thumbnail:hover .project-info-tab,
.nz-recent-projects.small-image .nz-thumbnail:hover .project-info-tab,
.nz-recent-projects.medium-image .nz-thumbnail:hover .project-info-tab,
.nz-recent-projects.large-image .nz-thumbnail:hover .project-info-tab,
.nz-recent-projects.small-image-nogap .nz-thumbnail:hover .project-info-tab,
.nz-recent-projects.medium-image-nogap .nz-thumbnail:hover .project-info-tab,
.nz-related-projects .projects .nz-thumbnail:hover .project-info-tab {
  bottom: 0%;
}

.loop .small-image .projects img,
.loop .medium-image .projects img,
.loop .large-image .projects img,
.loop .small-image-nogap .projects img,
.loop .medium-image-nogap .projects img,
.nz-recent-projects.small-image img,
.nz-recent-projects.medium-image img,
.nz-recent-projects.large-image img,
.nz-recent-projects.small-image-nogap img,
.nz-recent-projects.medium-image-nogap img,
.nz-related-projects .project-info-tab img {
  -webkit-transition: -webkit-transform 0.45s 0.1s;
  transition: transform 0.45s 0.2s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateX(0) translateY(0);
  -ms-transform: translateX(0) translateY(0);
  transform: translateX(0) translateY(0);
}

.loop .small-image .projects:hover img,
.loop .medium-image .projects:hover img,
.loop .large-image .projects:hover img,
.loop .small-image-nogap .projects:hover img,
.loop .medium-image-nogap .projects:hover img,
.nz-recent-projects.small-image .projects:hover img,
.nz-recent-projects.medium-image .projects:hover img,
.nz-recent-projects.large-image .projects:hover img,
.nz-recent-projects.small-image-nogap .projects:hover img,
.nz-recent-projects.medium-image-nogap .projects:hover img,
.nz-related-projects .project-info-tab .projects:hover img {
  -webkit-transform: translateY(-16px);
  -ms-transform: translateY(-16px);
  transform: translateY(-16px);
}

.loop .project-more,
.nz-recent-projects .project-more {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  position: absolute;
  top: -25px;
  left: 50%;
  margin-left: -25px;
  background-color: #333;

  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.loop .small-image .projects .project-title,
.loop .medium-image .projects .project-title,
.loop .large-image .projects .project-title,
.loop .small-image-nogap .projects .project-title,
.loop .medium-image-nogap .projects .project-title,
.nz-recent-projects.small-image .project-title,
.nz-recent-projects.medium-image .project-title,
.nz-recent-projects.large-image .project-title,
.nz-recent-projects.small-image-nogap .project-title,
.nz-recent-projects.medium-image-nogap .project-title,
.nz-related-projects .project-title {
  color: #333333 !important;
  display: block;
  padding: 0px;
  position: relative;
  z-index: 15;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0px;
  text-align: center;
}

.loop .small-image .projects .nz-overlay-before,
.loop .medium-image .projects .nz-overlay-before,
.loop .large-image .projects .nz-overlay-before,
.loop .small-image-nogap .projects .nz-overlay-before,
.loop .medium-image-nogap .projects .nz-overlay-before,
.nz-recent-projects.small-image .nz-overlay-before,
.nz-recent-projects.medium-image .nz-overlay-before,
.nz-recent-projects.large-image .nz-overlay-before,
.nz-recent-projects.small-image-nogap .nz-overlay-before,
.nz-recent-projects.medium-image-nogap .nz-overlay-before,
.nz-related-projects .nz-overlay-before {
  display: none;
}

.loop .small-image .projects .projects-category,
.loop .medium-image .projects .projects-category,
.loop .large-image .projects .projects-category,
.loop .small-image-nogap .projects .projects-category,
.loop .medium-image-nogap .projects .projects-category,
.nz-recent-projects.small-image .projects-category,
.nz-recent-projects.medium-image .projects-category,
.nz-recent-projects.large-image .projects-category,
.nz-recent-projects.small-image-nogap .projects-category,
.nz-recent-projects.medium-image-nogap .projects-category,
.nz-related-projects .projects-category {
  text-align: center;
  font-size: 16px;
  line-height: 26px;
  font-family: Georgia;
  text-transform: lowercase;
  color: #999999;
  position: static;
  bottom: 0;
  left: 0;
  padding: 0;
}

.loop .small-image .projects .projects-category a,
.loop .medium-image .projects .projects-category a,
.loop .large-image .projects .projects-category a,
.loop .small-image-nogap .projects .projects-category a,
.loop .medium-image-nogap .projects .projects-category a,
.nz-recent-projects.small-image .projects-category a,
.nz-recent-projects.medium-image .projects-category a,
.nz-recent-projects.large-image .projects-category a,
.nz-recent-projects.small-image-nogap .projects-category a,
.nz-recent-projects.medium-image-nogap .projects-category a,
.nz-related-projects .projects-category a {
  color: #999999;
  display: inline-block;
  font-style: italic;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.nz-recent-projects.small-image .projects-category a,
.nz-recent-projects.medium-image .projects-category a,
.nz-recent-projects.large-image .projects-category a,
.nz-recent-projects.small-image-nogap .projects-category a,
.nz-recent-projects.medium-image-nogap .projects-category a {
  color: #999999 !important;
}

.loop .small-standard .project-details,
.loop .medium-standard .project-details,
.loop .large-standard .project-details,
.nz-recent-projects.small-standard .project-details,
.nz-recent-projects.medium-standard .project-details,
.nz-recent-projects.large-standard .project-details {
  padding: 25px 20px;
  text-align: center;
  background-color: #f7f7f7;
}

.loop .small-standard .project-title,
.loop .medium-standard .project-title,
.loop .large-standard .project-title,
.nz-recent-projects.small-standard .project-title,
.nz-recent-projects.medium-standard .project-title,
.nz-recent-projects.large-standard .project-title {
  position: relative;
  bottom: auto;
  padding: 0px;
  color: #333 !important;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 0;
  text-transform: uppercase;
}

.loop .small-standard .projects-category,
.loop .medium-standard .projects-category,
.loop .large-standard .projects-category,
.nz-recent-projects.small-standard .projects-category,
.nz-recent-projects.medium-standard .projects-category,
.nz-recent-projects.large-standard .projects-category {
  position: relative;
  bottom: auto;
  padding: 0px;
  color: #999 !important;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-style: italic;
  font-size: 16px;
  line-height: 26px;
}

.loop .small-standard .project-title a,
.loop .medium-standard .project-title a,
.loop .large-standard .project-title a,
#nz-content .nz-recent-projects.small-standard .project-title a,
#nz-content .nz-recent-projects.medium-standard .project-title a,
#nz-content .nz-recent-projects.large-standard .project-title a {
  color: #333;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.loop .small-standard .projects-category a,
.loop .medium-standard .projects-category a,
.loop .large-standard .projects-category a,
#nz-content .nz-recent-projects.small-standard .projects-category a,
#nz-content .nz-recent-projects.medium-standard .projects-category a,
#nz-content .nz-recent-projects.large-standard .projects-category a {
  color: #999;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-transform: lowercase;
}

.nz-related-projects h3 {
  font-size: 28px;
  line-height: 38px;
  text-align: left;
  padding-bottom: 20px;
  margin-bottom: 40px;
}

.nz-related-projects h3:after {
  display: block;
  width: 70px;
  height: 2px;
  content: "";
  margin-top: 10px;
}

.single .projects-head {
  padding-bottom: 15px;
  margin-bottom: 40px;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
}

.single .projects-head .project-title {
  font-size: 36px;
  line-height: 46px;
  margin-bottom: 0;
  text-align: left;
}

.single .projects-head .projects-navigation {
  position: absolute;
  height: 40px;
  width: 85px;
  top: 0;
  right: 0;
}

.single .projects-head .projects-navigation > a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 40px;
  text-align: center;
  font-size: 15px;
  z-index: 15;
  box-shadow: inset 0 0 0 2px #eee;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  cursor: pointer;
  color: #eee !important;
  text-transform: uppercase;
  position: relative;
  float: right;
}

.single .projects-head .projects-navigation > a:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;

  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.single .projects-head .projects-navigation > a[rel="next"]:before {
}

.single .projects-head .projects-navigation > a:hover {
  box-shadow: inset 0 0 0 80px #eee;
}

.single .projects-head .projects-navigation > a[rel="next"] {
  margin-left: 5px;
}

.projects-description-title {
  font-size: 22px;
  line-height: 32px;
  color: #777777;
}

.single .project-excerpt {
  font-size: 14px;
  line-height: 24px;
  padding-top: 10px;
}

.single .projects .post-social-share {
  background-color: #fff;
  margin: 25px 0;
}

.projects-tags {
  padding-top: 25px;
  margin-top: 5px;
  border-top: 1px dotted #c0c0c0;
}

.projects-tags a {
  margin-right: 2px !important;
}

.project-content {
  padding: 50px 0px 20px 0px;
}

.single-projects .main-content:before {
  display: none;
}

.single-projects .single-details .button {
  margin-bottom: 0px;
  margin-top: 15px;
}

.single-projects .single-details .button:hover {
  color: #fff !important;
}

.single-projects .post-gallery .flex-direction-nav {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 15;
  width: 100%;
  height: 60px;
  margin-top: -30px;
}

.single-projects .post-gallery .flex-direction-nav li {
  width: 40px;
  height: 60px;
  display: block;
  float: left;
}

.single-projects .post-gallery .flex-direction-nav li:last-child {
  float: right;
}

.single-projects .post-gallery .flex-direction-nav li a {
  display: block;
  background-color: #ffffff !important;

  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 40px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #999999 !important;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}

.single-projects .post-gallery .flex-direction-nav li:last-child a {
  background-position: 50% 50%;
}

.single-projects .post-gallery .flex-direction-nav li a:hover {
}

.project-social-link-share .social-links {
  width: 26px;
  height: 26px;
  line-height: 26px;
  border-radius: 26px;
  display: inline-block;
  vertical-align: top;
}

.project-social-link-share .social-links {
  width: auto;
  position: relative;
  left: -9px;
}

.project-social-link-share .social-links a {
  width: 26px;
  height: 26px;
  line-height: 26px;
  border-radius: 26px;
  font-size: 14px;
  text-align: center;
  color: #ccc !important;
  background-color: transparent !important;
  box-shadow: none !important;
  margin: 0;
  -webkit-transition: color 300ms ease-out, opacity 300ms ease-out,
    -webkit-transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: color 300ms ease-out, opacity 300ms ease-out,
    transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
  opacity: 1;
}

.project-social-link-share .social-links a:hover {
  background-color: transparent !important;
}

.ninzio-filter .container {
  width: auto;
  margin: 0 auto;
}

/*POSTS
/*=============*/

.posts-fade-out {
  visibility: hidden;
  opacity: 0;
}

.loop .post,
.nz-recent-posts .post {
  padding-bottom: 30px;
  position: relative;
}

.list .loop .post {
  padding-bottom: 50px;
}

.blog-post,
.nz-recent-posts {
  position: relative;
}

.nz-recent-posts {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.loop .blog-post .post-body,
.nz-recent-posts .post-body {
  padding: 40px 0px;
  text-align: left;
  width: 100%;
  display: table;
  vertical-align: middle;
  position: relative;
  background-color: #ffffff;
}

.standard .blog-post .post-body,
.list .blog-post .post-body {
  text-align: left;
}

.list .post .post-date-full {
  text-align: left !important;
}

.small .loop .blog-post .post-body {
  padding: 40px 10px;
}

.blog-post .post .post-date-full,
.nz-recent-posts .post .post-date-full {
  text-align: center;
  font-size: 16px;
  line-height: 26px;
  font-style: italic;
  font-family: Georgia, "Times New Roman", Times, serif;
  margin-bottom: 10px;
}

.related-posts .post .post-date-full {
  margin-bottom: 0px;
  margin-top: 10px;
  color: #c0c0c0;
  text-align: center !important;
}

.blog-post .post .post-title,
.nz-recent-posts .post .post-title {
  margin: 0px 0 10px 0;
  font-size: 18px;
  line-height: 36px;
  font-weight: 600;
}

.post-date-full-list {
  display: none !important;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: #ccc !important;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-style: italic;
}

.blog-post .post .postmeta,
.nz-recent-posts .postmeta {
  margin-bottom: 35px;
}

.blog-post .post .postmeta > *,
.nz-recent-posts .postmeta > * {
  font-size: 16px;
  line-height: 26px;
  display: inline-block;
  vertical-align: middle;
}

.blog-post .postmeta .post-category {
  position: relative;
  top: 0px;
  right: 0px;
  left: 0px;
}

.blog-post .post .postmeta,
.blog-post .post .postmeta a {
  color: #c0c0c0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.single .blog-post .post .postmeta > * {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.blog-post .post .postmeta > *:not(:first-child),
.nz-recent-posts .postmeta > *:not(:first-child) {
  position: relative;
}

.blog-post .post .postmeta > *:not(:first-child) {
  padding-left: 30px;
}

.blog-post .post .postmeta > *:not(:first-child):before {
  display: block;
  content: "/";
  color: #c0c0c0;
  position: absolute;
  top: 0px;
  left: 10px;
  background-color: transparent !important;
}

.single .blog-post .post .post-title {
  font-size: 30px;
  line-height: 40px;
  position: relative;
  margin-bottom: 0px;
  padding-bottom: 20px;
}

.single .blog-post .post .post-title:after {
  width: 70px;
  height: 2px;
  left: 0;
  bottom: 0;
  position: absolute;
  content: "";
  display: block;
}

.blog-post .post .post-title a,
.nz-recent-posts .post .post-title a {
  color: inherit !important;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: block;
}

.single-project-layout-whole .post-gallery {
  margin-bottom: 40px;
}

.post-gallery {
  position: relative;
}

.post-gallery ul {
  margin: 0;
  list-style: none;
}

.post-gallery ul li {
  padding: 0;
}

.post-gallery .flex-control-nav li {
  margin: 0px 10px 0px 0px;
  display: inline-block;
  padding: 0;
}

.post-gallery .flex-control-nav li:last-child {
  margin: 0px;
}

.post-gallery .flex-control-nav {
  width: 100%;
  text-align: center;
  list-style: none;
  z-index: 50;
  margin: 0;
  opacity: 1;
  padding: 10px 0;
  position: absolute;
  bottom: 10px;
  left: 0;
}

.post-gallery .flex-control-paging li a {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  cursor: pointer;
  margin: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-indent: 100%;
  overflow: hidden;
  cursor: pointer;
}

.sticky-ind {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  padding: 0 !important;

  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 15;
  background-color: #fff;
}

.post-body-in .sticky-ind {
  top: 42px;
  right: 0;
  left: auto;
  background-color: #eeeeee;
}

.list .loop .sticky-ind,
.standard .sticky-ind {
  right: 15px;
  left: auto;
}

.post-audio {
  margin-bottom: 0px;
}
.post-video {
  margin-bottom: 0px;
}

.post.format-chat ul {
  list-style: none;
  margin: 0 0 10px 0;
  padding: 0;
}

.post.format-chat ul li {
  padding: 5px 0;
}

.post.format-chat ul li:after {
  content: "";
  display: table;
  clear: both;
}

.post.format-chat ul li span.name {
  display: block;
  margin-right: 10px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #999;
}

.post.format-chat ul li span.name:after {
  content: ": ";
  display: inline;
}

.post.format-chat ul li p {
  margin: 0;
  display: inline-block;
  background-color: #ffffff;
  padding: 10px 20px;
  border-radius: 0 5px 5px 5px;
  position: relative;
}

.post.format-chat ul li p:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 0 10px;
  border-color: transparent transparent transparent #ffffff;
  position: absolute;
  left: 0;
  top: -7px;
}

.post.format-chat ul li:nth-child(2n + 2) p {
  background-color: #f0f0f0;
}

.post.format-chat ul li:nth-child(2n + 2) p:before {
  border-color: transparent transparent transparent #f0f0f0;
}

.status-author,
.quote-author,
.link-link {
  padding: 15px 0 0 0;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.format-aside .single-post-content,
.format-status .single-post-content,
.format-quote .single-post-content {
  background-color: #f7f7f7;
  padding: 30px;
  font-family: georgia;
  font-size: 18px;
  line-height: 34px;
  font-style: italic;
}

.format-aside .single-post-content p:last-child,
.format-status .single-post-content p:last-child,
.format-quote .single-post-content p:last-child {
  margin: 0;
}

.post .post-excerpt {
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 28px;
}

.post .post-date-custom {
  display: block;
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 5px 13px;
  z-index: 15;
}

.post .post-date-custom > span:first-child {
  font-size: 20px;
  line-height: 35px;
  font-weight: 700;
  height: 30px;
  display: block;
  text-align: center;
  color: #fff;
}

.post .post-date-custom > span:last-child {
  font-size: 10px;
  line-height: 15px;
  font-weight: 700;
  height: 20px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  color: #fff;
}

.post .post-more {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 4px;
  display: inline-block;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: #999999 !important;
}

.post .post-more:after {
  display: block;
  padding: 0;
  width: 100%;
  height: 1px;
  content: "";
  background-color: #eeeeee;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: absolute;
  bottom: -3px;
}

.post .post-category {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 15;
}

.single .post .post-category {
  right: 20px;
}

.list .post .post-category {
  right: auto;
  left: 20px;
}

.post .post-category ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.post .post-category ul li {
  display: inline-block;
}

.post .post-category ul li a {
  display: block;
  padding: 3px 10px 2px 10px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 20px;
  color: #ffffff !important;
}

.loop .load-more {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  padding: 10px 25px;
  width: 100%;
  text-align: center;
  color: #999;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
}

.nz-reletated-posts-sep,
.post-comments-area .comments-title,
.post-comments-area #respond #reply-title {
  padding-bottom: 20px;
  margin-bottom: 30px;
  margin-top: 50px;
  position: relative;
  color: #333;
  font-size: 26px !important;
}

.nz-reletated-posts-sep:after,
.post-comments-area .comments-title:after,
.post-comments-area #respond #reply-title:after {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  margin-top: 7px;
}

.post-comments-area .comments-title,
.post-comments-area #respond #reply-title {
  margin-left: 0px;
  margin-right: 0px;
}

.post-comments-area .comments-title {
  font-size: 20px;
  line-height: 40px;
  margin-left: 0px;
  margin-right: 0px;
}

.loop .post .nz-thumbnail img {
  width: 100%;
}

.single .post-body {
  padding: 0;
  position: relative;
}

.single .blog-post > .post > .nz-thumbnail + .post-body,
.single .blog-post > .post > .post-audio + .post-body,
.single .blog-post > .post > .post-video + .post-body,
.single .blog-post > .post > .post-gallery + .post-body {
  padding: 50px 0 0 0;
}

.single .projects .post-body {
  padding: 0px;
  background-color: transparent;
  margin-bottom: 70px;
}

.single .project-details {
  margin: 20px 0;
}

.single .project-details a {
  position: relative;
}

.single .project-details a:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 0;
  height: 2px;
  opacity: 0;
  -webkit-transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.single .project-details a:hover:after {
  width: 100%;
  opacity: 1;
}

.single .project-details ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.single .project-details ul li {
  padding: 8px 0px 8px 0px;
  border-bottom: 1px dashed #eee;
  margin-bottom: 0px;
  display: block;
  position: relative;
}

.single .project-details ul li:first-child {
  border-top: 1px dashed #eee;
}

.single .project-details ul li strong {
  min-width: 100px;
  display: inline-block;
  color: #777777;
}

.single .related-posts .post-body {
  padding: 30px 0px;
  position: relative;
  min-height: auto;
}

.single .related-posts .post-title {
  font-size: 14px !important;
  line-height: 24px !important;
  padding: 0 !important;
  margin-bottom: 15px !important;
  color: #333;
}

.single .related-posts .post-date {
  font-size: 16px;
  line-height: 26px;
  font-style: italic;
  font-family: Georgia, "Times New Roman", Times, serif;
  color: #999;
  text-align: left;
  padding-top: 15px;
}

.single .post-social-share .social-links {
  overflow: inherit;
  height: 26px;
  line-height: 26px;
}

.single .post-social-share .social-links a {
  margin: 0;
  width: 26px;
  height: 26px;
  border-radius: 0px;
  line-height: 26px;
  box-shadow: none !important;
}

.single .post-social-share span {
  display: inline-block;
  text-align: left;
  padding: 10px 20px;
  padding-left: 70px;

  background-repeat: no-repeat;
  background-position: 15px 50%;
  background-color: #cfcfcf;
  color: #fff;
  margin-top: 25px;
  position: relative;
  vertical-align: top;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}

.single .post-social-share .social-links {
  padding: 0px;
  height: 44px;
  margin-top: 25px;
  display: inline-block;
  overflow: hidden;
}

.single .post-social-share .social-links a {
  position: relative;
  width: 34px;
  height: 44px;
  line-height: 44px;
  border-radius: 44px;
  font-size: 18px;
  text-align: center;
  color: #ccc !important;
  background-color: transparent !important;
  margin: 0;
  -webkit-transition: color 300ms ease-out, opacity 300ms ease-out,
    bottom 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: color 300ms ease-out, opacity 300ms ease-out,
    bottom 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
  bottom: -44px;
  opacity: 0;
}

.single .post-social-share .social-links a:hover {
  background-color: transparent !important;
}

.single .post-social-share .social-links a:nth-child(5) {
  -webkit-transition-delay: 130ms !important;
  transition-delay: 130ms !important;
}

.single .post-social-share .social-links a:nth-child(4) {
  -webkit-transition-delay: 110ms !important;
  transition-delay: 110ms !important;
}

.single .post-social-share .social-links a:nth-child(3) {
  -webkit-transition-delay: 90ms !important;
  transition-delay: 90ms !important;
}

.single .post-social-share .social-links a:nth-child(2) {
  -webkit-transition-delay: 70ms !important;
  transition-delay: 70ms !important;
}

.single .post-social-share .social-links a:nth-child(1) {
  -webkit-transition-delay: 0ms !important;
  transition-delay: 0ms !important;
}

.single .post-social-share .social-links a.animated {
  bottom: 0;
  opacity: 1;
  -webkit-transition: color 300ms ease-out, opacity 300ms ease-out,
    bottom 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: color 300ms ease-out, opacity 300ms ease-out,
    bottom 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.single .post-social-share span:before {
  display: block;
  position: absolute;
  top: 0;
  left: 50px;
  width: 1px;
  background-color: #fff;
  height: 100%;
  content: "";
}

.post-views,
.post-likes {
  float: right;
  height: 36px;
  margin-top: 30px;
}

.post-views {
  padding-left: 20px;
}

.post-views,
.post-likes,
.post-likes a {
  color: #ccc !important;
  font-size: 13px;
  line-height: 36px;
}

.post-views .icon-eye4 {
  position: relative;
  bottom: -2px;
  padding-right: 3px;
  display: inline-block;
  font-size: 16px;
  color: #ccc;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.post-likes #dolike {
  font-size: 14px;
}

.single .post .post-social-share .share-label,
.single .projects .post-social-share .share-label,
.single .product .post-social-share .share-label {
  display: inline-block;
  line-height: 34px;
  padding-right: 15px;
}

.nz-related-products .post-social-share {
  display: none;
}

.post-social-share .social-links {
  height: 36px;
}

.post-social-share .social-links a {
  height: 36px;
  width: 36px;
  line-height: 36px;
  border-radius: 36px;
}

.post-social-share .social-links a {
  background-color: transparent !important;
}

.post-social-share .social-links a:hover {
  background-color: transparent !important;
}

.nz-load-more {
  text-align: center;
  height: 102px;
  padding-top: 50px;
}
.nz-load-more a {
  margin: 0;
  outline: none;
}
.nz-load-more.clicked a {
  display: none;
}
.nz-load-more.clicked .nz-loader {
  display: block;
}

.nz-loader {
  display: none;
  font-size: 10px;
  margin: 0 auto 0 auto;
  border-top: 3px solid rgba(0, 0, 0, 0.1);
  border-right: 3px solid rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
  border-left: 3px solid rgba(0, 0, 0, 0.3);
  -webkit-animation: load8 0.3s infinite linear;
  animation: load8 0.3s infinite linear;
}

.nz-loader,
.nz-loader:after {
  border-radius: 40px;
  width: 40px;
  height: 40px;
}

.single .post-tags {
  padding-top: 50px;
}

.single .post-tags > * {
  margin-right: 5px;
  box-shadow: inset 0 0 0 1px #e0e0e0;
}

.single .post-meta {
  position: relative;
  padding-bottom: 40px;
}

.single-category,
.post-author,
.post-comments {
  display: inline-block;
  position: relative;
}

.single-category a,
.post-comments a {
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.ninzio-nav-single {
  background-color: #fff;
  height: 40px;
  width: 100%;
  margin-top: 20px;
  position: relative;
}

.ninzio-nav-single > * {
  display: block;
  float: left;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  position: relative;
  color: #ccc !important;
  background-color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.ninzio-nav-single > *:hover {
  color: #333 !important;
}

.ninzio-nav-single > *[rel="prev"] {
  padding-left: 50px;
}
.ninzio-nav-single > *[rel="next"] {
  padding-right: 50px;
  text-align: right;
}

.ninzio-nav-single > *:after,
.ninzio-nav-single > *:before {
  content: "\e914";
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 24px;
  top: 50%;
  left: 16px;
  margin-top: -12px;
}

.ninzio-nav-single > *[rel="next"]:after,
.ninzio-nav-single > *[rel="next"]:before {
  transform: rotate(180deg);
}

.ninzio-nav-single > *:after {
  left: 10px;
}

.ninzio-nav-single > *[rel="next"]:after,
.ninzio-nav-single > *[rel="next"]:before {
  right: 16px;
  left: auto;
}

.ninzio-nav-single > *[rel="next"]:after {
  right: 10px;
  left: auto;
}

.ninzio-nav-single > :only-child {
  width: 100% !important;
}

.ninzio-nav-single > *:not(:only-child) {
  width: 50% !important;
}

.standard .loop .blog-post .post-body {
  background-color: transparent;
  padding: 40px 0px;
  margin-bottom: 20px;
  box-shadow: none !important;
}

.standard .loop .blog-post .format-quote .post-body,
.standard .loop .blog-post .format-status .post-body,
.standard .loop .blog-post .format-link .post-body,
.standard .loop .blog-post .format-aside .post-body {
  padding-top: 0;
}

.standard .loop .blog-post .format-quote .post-body .format-wrapper,
.standard .loop .blog-post .format-status .post-body .format-wrapper,
.standard .loop .blog-post .format-link .post-body .format-wrapper,
.standard .loop .blog-post .format-aside .post-body .format-wrapper {
  padding: 50px;
}

.standard .loop .blog-post .format-quote .post-body .format-wrapper > *,
.standard .loop .blog-post .format-status .post-body .format-wrapper > *,
.standard .loop .blog-post .format-link .post-body .format-wrapper > *,
.standard .loop .blog-post .format-aside .post-body .format-wrapper > *,
.standard .loop .blog-post .format-quote .post-body .format-wrapper h3,
.standard .loop .blog-post .format-status .post-body .format-wrapper h3,
.standard .loop .blog-post .format-link .post-body .format-wrapper h3,
.standard .loop .blog-post .format-aside .post-body .format-wrapper h3 {
  color: #999;
}

.standard
  .loop
  .blog-post
  .format-quote
  .post-body
  .format-wrapper
  > .post-excerpt,
.standard
  .loop
  .blog-post
  .format-status
  .post-body
  .format-wrapper
  > .post-excerpt,
.standard
  .loop
  .blog-post
  .format-link
  .post-body
  .format-wrapper
  > .post-excerpt,
.standard
  .loop
  .blog-post
  .format-aside
  .post-body
  .format-wrapper
  > .post-excerpt {
  margin-bottom: 0px;
  font-size: 24px;
  line-height: 34px;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-style: italic;
}

.standard .loop .blog-post .post .post-title {
  font-size: 36px;
  line-height: 56px;
  margin-bottom: 25px;
}

.standard .loop .post .post-excerpt {
  margin-bottom: 25px;
  font-size: 14px;
  line-height: 28px;
}

.standard .loop .post .post-excerpt {
  margin-bottom: 25px;
  font-size: 14px;
  line-height: 34px;
}

.standard .post-date-custom,
.standard .post-format-status {
  left: -65px;
  top: 0;
  padding: 16px 0px;
  width: 66px;
  display: block;
  position: absolute;
  z-index: 15;
  border-radius: 0px;
  background-color: #f0f0f0;
}

.standard .post-format-status {
  top: 82px;
  width: 66px;
  height: 46px;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: center center;
}

.standard .post-format-status + .sticky-ind {
  top: 168px;
}

.standard .post-date-custom > span:first-child {
  font-size: 26px;
  line-height: 35px;
  height: 30px;
  display: block;
  text-align: center;
  color: #fff;
}

.standard .post-date-custom > span:last-child {
  font-size: 11px;
  line-height: 15px;
  height: 20px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  color: #fff;
}

.standard .post-date-custom > span:first-child {
  font-size: 28px;
  line-height: 37px;
}

.standard .post-date-custom > span:last-child {
  font-size: 12px;
  line-height: 20px;
}

.standard .loop .blog-post .format-quote .post-body .format-wrapper,
.standard .loop .blog-post .format-status .post-body .format-wrapper,
.standard .loop .blog-post .format-link .post-body .format-wrapper,
.standard .loop .blog-post .format-aside .post-body .format-wrapper {
  background-color: #f7f7f7;
}

.single .single-post-content a,
.single .single-post-content a:before {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
}

.single .single-post-content a:before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 0;
  height: 2px;
  opacity: 0;
  -webkit-transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.single .single-post-content a:hover:before {
  width: 100%;
  opacity: 1;
}

.post-author-info {
  padding: 40px;
  background-color: #f7f7f7;
  margin: 50px 0;
}

.post-author-info-title a {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
}

.post-author-info > * {
  display: table-cell;
  vertical-align: top;
}

.post-author-info .author-info-box {
  padding-left: 20px;
}

.post-author-info .author-gavatar {
  padding-top: 7px;
  width: 60px;
  height: 60px;
}

.post-author-info .author-gavatar img,
.post-author-info .author-description p {
  margin-bottom: 0;
}

.post-author-info .author-social-links a {
  margin: 0;
  width: 30px;
  height: 30px;
  border-radius: 0px;
  line-height: 30px;
  margin-top: 10px;
  color: #c0c0c0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: inline-block;
}

.related-posts .owl-controls .owl-buttons div {
  top: -60px;
  left: auto !important;
  right: 10px !important;
  opacity: 1 !important;
  width: 30px;
  height: 30px;
}

.related-posts .owl-controls .owl-buttons div:before {
  width: 30px;
  height: 30px;
  top: 0;
  left: 0px !important;
  right: auto !important;
}

.related-posts .owl-controls .owl-buttons .owl-next:hover:before {
  left: 0px !important;
}

.related-posts .owl-controls .owl-buttons div.owl-prev:before {
}

.related-posts .owl-controls .owl-buttons .owl-prev {
  right: 45px !important;
}

.post-single-navigation a {
  display: block;
  width: 30px;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  line-height: 100px;
  position: fixed;
  top: 50%;
  margin-top: -50px;
  left: 0;
  z-index: 52;

  background-repeat: no-repeat;
  background-position: 35% 50%;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.post-single-navigation a[rel="next"] {
  left: auto;
  right: 0;

  background-position: 55% 50%;
}

/*COMMENTS
/*=============*/

/*	Comment form
/*-------------*/

.post-comments-area {
  position: relative;
  padding-bottom: 50px;
  margin-top: 30px;
  border-top: 1px solid #eeeeee;
}

.nz-reletated-posts-sep {
  padding-top: 40px;
  border-top: 1px solid #eeeeee;
}

.post-comments-area #respond {
  margin: 20px 0 20px 0;
}

.post-comments-area .comment-navigation {
  margin-bottom: 20px;
}

.post-comments-area .comment-navigation .nav-previous,
.post-comments-area .comment-navigation .nav-next {
  display: inline-block;
  padding: 5px 15px;
  background-color: #ffffff;
  margin-top: 25px;
  color: #999999 !important;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.post-comments-area .comment-navigation .nav-previous:hover,
.post-comments-area .comment-navigation .nav-next:hover {
  color: #333333 !important;
}

.post-comments-area .comment-navigation .nav-previous {
  margin-right: 10px;
}

.post-comments-area #respond #reply-title small {
  display: block;
  font-weight: normal;
  font-size: 11px;
}

.post-comments-area #respond form,
.post-comments-area #respond .form-submit,
.post-comments-area #respond .form-submit input[type="submit"] {
  margin-bottom: 0;
}

.post-comments-area .comment-form-author,
.post-comments-area .comment-form-email,
.post-comments-area .comment-form-url,
.post-comments-area .respond-textarea {
  display: block;
  width: 100%;
  margin-bottom: 20px;
}

.post-comments-area .comment-form-author input,
.post-comments-area .comment-form-email input,
.post-comments-area .comment-form-url input,
.post-comments-area .respond-textarea textarea {
  width: 100%;
  margin-bottom: 0;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
}

.post-comments-area .comment .comment-form-author input,
.post-comments-area .comment .comment-form-email input,
.post-comments-area .comment .comment-form-url input,
.post-comments-area .comment .respond-textarea textarea {
  background-color: #ffffff !important;
}

.post-comments-area .comment-form-author input,
.post-comments-area .comment-form-email input,
.post-comments-area .comment-form-url input {
  height: 55px;
  line-height: 55px;
}

/*	Comment list
/*-------------*/

.comment-list .comment.bypostauthor {
  background-color: #f7f7f7;
}

.comment-list ul.children {
  margin: 0;
  list-style: none;
}

.comment-list .comment {
  padding: 30px;
  margin: 0 0 20px 0;
  width: 100%;
  position: relative;
  background-color: #f7f7f7;
}

.comment-list .comment #respond {
  margin-bottom: 0;
}

.comment-list .comment .comment-body {
  display: table;
}

.comment-list .comment-gavatar,
.comment-list .comment-content {
  display: table-cell;
  vertical-align: top;
}

.comment-list .comment .comment-gavatar {
  padding-right: 20px;
  min-width: 80px;
}

.comment-list .comment .comment-text p:last-child {
  margin-bottom: 0;
}

.comment-meta .comment-author {
  margin-bottom: 3px;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #333;
  font-weight: 600;
}

.comment-meta .comment-author cite {
  font-style: normal;
  font-size: 14px;
}

.comment-meta .comment-date-time {
  display: inline-block;
  padding-top: 0px;
  padding-bottom: 10px;
}

.comment-meta {
  padding-right: 80px;
}

.comment-meta .replay {
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
}

.comment-meta .replay a {
  display: block;
  padding: 3px 18px 3px 18px;
  -webkit-transition: all 300ms;
  transition: all 300ms;
  position: relative;
  box-shadow: inset 0 0 0 2px #eee;
  text-transform: uppercase;
  text-align: center;
  font-size: 12px;
  border-radius: 50px;
}

.comment-meta .replay a:hover {
  color: #fff !important;
}

.comment-content .edit-link a {
  margin: 10px 0px 0 0;
  color: #999 !important;
}
.comment-meta .comment-date-time a {
  color: #999 !important;
}

.post-comments-area a,
.post-author-info-title a {
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

#comments #submit {
  background-color: #cfcfcf !important;
  padding: 14px 44px;
  color: #fff !important;
}

#comments #submit:hover {
  color: #fff !important;
  background-color: #333333 !important;
}

/*WOO
/*=============*/

/*General
/*=============*/

.woocommerce-mini-cart__empty-message {
  margin: 0;
  display: inline-block;
  vertical-align: middle;
}

.page-content .woocommerce {
  padding-bottom: 50px;
}
.woocommerce-checkout #nz-content .woocommerce {
  padding-bottom: 50px !important;
}
.shop-banner {
  margin-bottom: 30px;
}

.woocommerce-account .page-content .woocommerce {
  margin: 0 auto 70px auto;
}

.page-content .woocommerce.columns-1 .page-content .woocommerce.columns-2,
.page-content .woocommerce.columns-3,
.page-content .woocommerce.columns-4 {
  padding-bottom: 0px;
}

/*Loop
/*=============*/

.woocommerce .demo_store {
  position: fixed;
  margin: 0;
  text-align: center;
  left: 0;
  top: 0;
  width: 100%;
  padding: 10px 20px;
  border-color: #a6c4d8;
  background: #e3eff7;
  color: #225f87;
  z-index: 99;
}

.shop-layout,
.woocommerce .main-content {
  position: relative;
}

.woocommerce .main-content {
  margin-bottom: 0;
}

.woocommerce .page-title {
  display: none;
}

.woocommerce .products {
  position: relative;
  list-style: none;
  overflow: visible !important;
  margin: 0 auto 0 auto;
}

.woocommerce .product {
  position: relative;
  max-width: 100%;
  width: 100%;
  margin-bottom: 30px;
  padding-top: 0;
}

.nz-product-posts .product {
  padding-bottom: 0px;
}

.nz-product-posts .product > a {
  display: block;
  width: 100%;
}

.product .ninzio-overlay {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.loop .products .product .product-body,
.nz-recent-products .products .product .product-body,
.nz-related-products .products .product .product-body {
  display: table;
  width: 100%;
  background-color: #fff;
  padding: 15px 25px 25px 25px;
  text-align: left;
}

.products .product .product-details {
  padding: 20px 0px 0px 0px;
  background-color: #fff;
  text-align: left;
}

.woocommerce .product .onsale {
  position: absolute;
  width: 70px;
  height: 30px;
  border-radius: 0;
  line-height: 30px;
  text-align: center;
  color: #fff;
  top: -10px;
  right: 10px;
  z-index: 50;
}

.loop .products .product .product-title *,
.nz-related-products .products .product .product-title *,
.nz-recent-products .products .product .product-title * {
  display: inline-block;
}

.loop .products .product h3,
.nz-related-products .products .product h3,
.nz-recent-products .products .product h3 {
  font-size: 18px;
}

.loop .products .product h3 a,
.nz-related-products .products .product h3 a,
.nz-recent-products .products .product h3 a {
  color: inherit;
}

.nz-recent-products .products .product h3 a {
  color: inherit !important;
}

.loop .products .product .price,
.nz-related-products .products .product .price,
.nz-recent-products .products .product .price {
  font-size: 22px;
}

.loop .products .product h3,
.nz-related-products .products .product h3,
.nz-recent-products .products .product h3 {
  line-height: 28px;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.loop .products .product h3:hover,
.nz-related-products .products .product h3:hover,
.nz-recent-products .products .product h3:hover {
  color: #999;
}

.loop .products .product .price,
.nz-related-products .products .product .price,
.nz-recent-products .products .product .price {
  line-height: 36px;
  font-size: 26px;
  font-weight: 600;
  padding-top: 5px;
  display: block;
}

.loop .products .product .star-rating,
.nz-related-products .products .product .star-rating,
.nz-recent-products .products .product .star-rating {
  margin-top: 15px;
  margin-bottom: 10px;
}

.loop .animation-true .product {
  opacity: 0 !important;
  -webkit-backface-visibility: hidden;
}
.loop .animation-true .product.css-animated {
  opacity: 1 !important;
}

.woocommerce .product .nz-thumbnail {
  overflow: hidden;
  -webkit-backface-visibility: hidden;
}

.woocommerce .cart-wrap {
  position: relative;
  min-height: 34px;
  height: 34px;
  margin-top: 10px;
}

.woocommerce .product .button,
.woocommerce .product .added_to_cart,
.woocommerce .product .product_type_external {
  display: inline-block;
  width: auto !important;
  margin-bottom: 0;
  position: relative;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 4px;
  font-weight: 600;
  color: #999999 !important;
  text-align: left;
  text-transform: uppercase;
  -webkit-transform: translateY(0) !important;
  -ms-transform: translateY(0) !important;
  transform: translateY(0) !important;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  outline: none;
}

.woocommerce .product .button:before,
.woocommerce .product .added_to_cart:before,
.woocommerce .product .product_type_external:before {
  display: block;
  padding: 0;
  width: 100%;
  height: 1px;
  content: "";
  background-color: #eeeeee;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: absolute;
  bottom: 0px;
}

.woocommerce .product .single-product-summary .button,
.woocommerce .product .single-product-summary .added_to_cart,
.woocommerce .product .single-product-summary .product_type_external {
  padding: 11px 25px 11px 75px;
  font-size: 12px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: normal;
  box-shadow: none;
  border-radius: 0px;
  color: #fff !important;
  background-color: #999999 !important;
  background-repeat: no-repeat;
  background-position: 16px 46%;

  text-transform: uppercase;
  position: relative;
  z-index: 0;
}

.woocommerce .product .single-product-summary .button:before,
.woocommerce .product .single-product-summary .added_to_cart:before,
.woocommerce .product .single-product-summary .product_type_external:before {
  display: none;
}

.woocommerce .product .single-product-summary .button:after,
.woocommerce .product .single-product-summary .added_to_cart:after,
.woocommerce .product .single-product-summary .product_type_external:after {
  content: "";
  width: 1px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 50px;
  background-color: #cccccc;
}

.woocommerce .product .single-product-summary .button:hover,
.woocommerce .product .single-product-summary .added_to_cart:hover,
.woocommerce .product .single-product-summary .product_type_external:hover {
  background-color: #222 !important;
}

@-webkit-keyframes loadIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes loadIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.shop-loader {
  position: absolute;
  width: 100%;
  height: 36px;
  top: 0;
  right: 0;
  z-index: 15;
  display: none;
  background-color: #ffffff;
}

.shop-loader:before {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 0%;
  font-size: 10px;
  margin: -18px 0 0 0px;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  border-right: 2px solid rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  -webkit-animation: load8 0.3s infinite linear;
  animation: load8 0.3s infinite linear;
}

.shop-loader:before,
.shop-loader:after {
  border-radius: 36px;
  width: 36px;
  height: 36px;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.woocommerce .product img {
  -webkit-transform: scale(1) !important;
  -ms-transform: scale(1) !important;
  transform: scale(1) !important;
}

.woocommerce .product del {
  color: #ccc;
}

.woocommerce .product .price {
  font-size: 22px;
}

.woocommerce .single-product-summary .price {
  font-size: 28px;
  font-weight: 600;
  display: inline-block;
}

.woocommerce .single-product-summary div[itemprop="offers"] {
  display: inline-block;
}

.woocommerce .product del .amount {
  font-size: 18px;
  font-weight: normal;
  text-decoration: line-through;
}

.woocommerce .single-product-summary .stock {
  font-weight: 700;
  padding: 0 0 15px 0;
  margin-bottom: 0;
}

.woocommerce .products .product-category a {
  display: block;
  color: transparent;
  overflow: hidden;
}

.woocommerce .category-details {
  box-shadow: inset 0 0 0 1px #eee, 0 0 3px rgba(0, 0, 0, 0.05);
  padding: 30px 0;
  background-color: #f4f4f4;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}

.woocommerce .category-details mark {
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}

.woocommerce .product:hover .category-details h3,
.woocommerce .product:hover .category-details mark {
  color: #333 !important;
}

.woocommerce .category-details h3 {
  margin: 0;
  text-align: center;
  padding: 0 !important;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  font-size: 26px;
  line-height: 36px;
  font-weight: 400;
}

.woocommerce .products .product-category mark {
  background-color: transparent;
}

.woocommerce .products .product-category .cat-det h3 {
  padding-bottom: 0;
  padding-top: 25px;
}

.woocommerce-error {
  list-style: none;
  margin: 0;
  padding: 0;
}

.woocommerce-message,
.woocommerce-info,
.woocommerce-error li,
.cart-empty {
  padding: 20px;
  position: relative;
  margin-bottom: 50px;
  text-align: center;
  font-size: 14px;
  line-height: 36px;
  color: #999;
  background-color: #f7f7f7;
}

.woocommerce-error li {
  background-color: rgba(239, 147, 148, 0.16);
  color: #d2635f !important;
}

.woocommerce-message a,
.woocommerce-info a,
.woocommerce-error li a,
.woocommerce-message .button,
.woocommerce-info .button {
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  text-transform: none;
  margin-bottom: 0;
  display: inline;
  margin-right: 5px;
  margin-left: 5px;
  padding: 10px 20px;
  color: #999 !important;
  box-shadow: inset 0 0 0 2px #ccc;
  background-color: transparent !important;
}

.woocommerce-error li a {
  color: #c90700 !important;
  box-shadow: inset 0 0 0 2px #c90700;
}

.woocommerce-message a:hover,
.woocommerce-info a:hover,
.woocommerce-error li a:hover {
  background-color: #ccc !important;
  color: #fff !important;
}

.woocommerce-error li a:hover {
  background-color: #c90700 !important;
}

.woocommerce label {
  display: block;
  margin-bottom: 5px;
}

.woocommerce input[type="radio"] + label {
  display: inline-block;
}

.woocommerce-ordering,
.woocommerce-ordering select {
  margin-bottom: 50px;
  height: 50px;
  width: auto;
}

.product-category-body {
  width: 100%;
  background-color: #fff;
  padding: 25px;
  margin-bottom: 30px;
  text-align: center;
}

.product-category-body img {
  margin-bottom: 25px;
}

.product-category-body > * {
  vertical-align: middle;
}

.shop-layout .product-term-title,
.nz-related-products-title {
  margin-bottom: 50px;
  position: relative;
}

.nz-related-products-title h3 {
  font-size: 16px;
  line-height: 26px;
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom: 1px solid #eee;
  text-transform: uppercase;
}

/*Single product
/*=============*/

.woocommerce-product-gallery__wrapper .owl-pagination {
  padding-top: 15px;
}

.single-image-content {
  position: relative;
}

.single-image-content > .ninzio-label {
  bottom: auto;
  top: 14px;
  right: 0px;
}

.single-product-image .nz-overlay-before {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.woocommerce.single .summary .product_title {
  margin-bottom: 15px;
  font-size: 36px;
  line-height: 46px;
  font-weight: 400;
}

.single-product-image,
.woocommerce.single .products .product {
  position: relative;
}

.single-product-image {
  margin-bottom: 50px;
  text-align: center;
}

#single-product-slider,
#single-product-carousel {
  position: relative;
}

#single-product-carousel {
  display: inline-block;
  padding: 0px;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 10px;
  z-index: 15;
}

#single-product-slider ul,
#single-product-carousel ul {
  list-style: none;
  margin: 0;
}

#single-product-slider li,
#single-product-carousel li {
  padding: 0 !important;
  float: left;
}

#single-product-carousel li {
  cursor: pointer;
  position: relative;
  margin: 0 5px !important;
}

#single-product-carousel .slides li:hover img {
  box-shadow: none !important;
}

#single-product-carousel .slides li:before,
#single-product-carousel .slides li:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);

  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: 0;
  z-index: 5;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}

#single-product-carousel .slides li:after {
  background-image: none;
  background-color: transparent;
}

#single-product-carousel .slides li.flex-active-slide:after {
  opacity: 1;
}

#single-product-carousel .slides li:hover:before {
  opacity: 1;
}

#single-product-slider .flex-direction-nav {
  margin-bottom: 0;
  position: absolute;
  width: 100%;
  height: 40px;
  margin-top: -20px;
  top: 50%;
  left: 0;
  list-style: none;
}

#single-product-carousel .flex-direction-nav {
  margin: 0;
  padding: 0;
}

#single-product-carousel .flex-direction-nav > li {
  position: static;
}

#single-product-carousel .flex-direction-nav > li a {
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-indent: -9000em;
  display: block;
  position: absolute;
  top: 50%;
  left: -65px;
  margin-top: -20px !important;
  background-color: #f5f5f5 !important;
  border-radius: 50%;

  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  opacity: 0;
  visibility: hidden;
}

#single-product-carousel .flex-direction-nav > li.flex-nav-next a {
  left: auto !important;
  right: -65px;
}

#single-product-carousel:hover .flex-direction-nav > li a {
  left: -25px !important;
  opacity: 1;
  visibility: visible;
}

#single-product-carousel:hover .flex-direction-nav > li.flex-nav-next a {
  right: -25px !important;
  left: auto !important;
  opacity: 1;
  visibility: visible;
}

#single-product-carousel .flex-direction-nav > li a:hover {
  background-color: #333333 !important;
}

#single-product-carousel .flex-direction-nav > li a.flex-next:hover {
}

.woocommerce-product-gallery__wrapper .owl-pagination {
  padding-top: 15px;
}

.zoomLens {
  display: none !important;
}

.ninzio-zoom {
  cursor: zoom-in;
}

.zoomWindow {
  border: 3px solid rgba(0, 0, 0, 0.1) !important;
}

.single-image-content {
  position: relative;
}

.single-image-content > .ninzio-label {
  bottom: auto;
  top: 14px;
  right: 0px;
}

.single-product-image .nz-overlay-before {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.woocommerce.single .summary .product_title {
  margin-bottom: 15px;
  font-size: 32px;
  line-height: 42px;
}

.single-product-image,
.woocommerce.single .products .product {
  position: relative;
}

.single-product-image {
  margin-bottom: 50px;
  text-align: center;
}

.woocommerce .single-product-summary [itemprop="description"] {
  padding: 15px 0 15px 0;
}

.woocommerce .single-product-summary [itemprop="description"] .description {
  margin-bottom: 25px;
  font-size: 14px;
  line-height: 24px;
}

.woocommerce .single-product-summary [itemprop="description"] > :last-child,
.woocommerce .single-product-summary [itemprop="description"] > :only-child {
  margin-bottom: 0;
}

.woocommerce .single-product-summary form.cart {
  margin-bottom: 15px;
  padding-top: 35px;
  border-top: 1px dashed #eee;
}

.woocommerce .quantity {
  display: block;
}

.woocommerce .quantity input {
  margin-bottom: 10px;
  height: 42px;
  float: left;
}

.btn-3d .woocommerce .quantity input {
  height: 54px;
  top: -4px;
}

.woocommerce .quantity input[type="button"].minus,
.woocommerce .quantity input[type="button"].plus {
  margin-right: 0;
  border-radius: 3px 0 0 3px;
  padding: 0px 5px 0px 5px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  font-size: 16px;
  color: #333;
  box-shadow: none;
  width: 25% !important;
  -webkit-transform: translateX(1px);
  -ms-transform: translateX(1px);
  transform: translateX(1px);
  font-weight: 400 !important;
}

.woocommerce .quantity input[type="button"].minus:hover,
.woocommerce .quantity input[type="button"].plus:hover {
  background-color: #f7f7f7;
  opacity: 1;
}

.woocommerce .quantity input[type="button"].plus {
  border-radius: 0px 3px 3px 0px;
  -webkit-transform: translateX(-1px);
  -ms-transform: translateX(-1px);
  transform: translateX(-1px);
}

.woocommerce .quantity input[type="number"] {
  border-radius: 0px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  background-color: #ffffff;
  width: 60px !important;
  height: 44px;
  line-height: 44px;
  padding: 0;
  box-shadow: inset 0 0 0 1px #ccc;
}

.woocommerce .single-product-summary .product_meta {
  font-size: 16px;
  line-height: 26px;
}

.woocommerce .single-product-summary .product_meta a {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.woocommerce .single-product-summary button {
  margin-bottom: 0;
}

.woocommerce .single-product-summary .product_meta,
.comment-meta .comment-date-time {
  color: #777;
}

.woocommerce .single-product-summary .product_meta > * {
  display: inline;
  padding-bottom: 5px;
  position: relative;
  padding-right: 28px;
}

.woocommerce .single-product-summary .product_meta > :last-child {
  padding-bottom: 0px;
}

.woocommerce .single-product-summary .product_meta a {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  color: #333;
}

.woocommerce .single-product-summary .product_meta a:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 0;
  height: 2px;
  opacity: 0;
  -webkit-transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.woocommerce .single-product-summary .product_meta a:hover:after {
  width: 100%;
  opacity: 1;
}

.woocommerce-variation-description {
  padding-bottom: 25px;
  font-size: 14px;
  line-height: 24px;
}

.woocommerce-tabs {
  margin: 70px auto 0 auto;
  width: 100%;
}

.woocommerce-tabs .tabs {
  display: block;
  list-style: none;
  margin-bottom: 0;
  width: 100%;
  background-color: #ffffff;
  border-bottom: 1px solid #eee;
  text-align: center;
}

.woocommerce-tabs .tabs:after {
  content: "";
  display: table;
  clear: both;
}

.woocommerce-tabs .tabs > li {
  display: block;
  float: left;
  text-align: center;
  color: #999;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  text-transform: uppercase;
}

.woocommerce-tabs .tabs > li.active {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #eee;
}

.woocommerce-tabs .tabs > li:not(:first-child) {
  margin-left: -1px;
}

.woocommerce-tabs .tabs > li a {
  display: block;
  outline: none;
  position: relative;
  color: inherit;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 60px 10px 50px;
}

.woocommerce-tabs .tabs > li.active a {
  z-index: 10;
  color: #333;
}

.woocommerce-tabs .tabs > li:before {
  content: "";
  display: block;
  bottom: -1px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  height: 2px;
  position: absolute;
  opacity: 0;
  background-color: #ffffff !important;
}

.woocommerce-tabs .panel {
  padding: 70px 0px;
}

.woocommerce-tabs .shop_attributes {
  width: 100%;
  margin-bottom: 0;
  border-collapse: collapse;
  background-color: #ffffff;
}

.woocommerce-tabs .shop_attributes th,
.woocommerce-tabs .shop_attributes td {
  border: 1px solid #e0e0e0;
  padding: 10px;
  background-color: #ffffff;
}

.woocommerce-tabs .panel > h2,
.woocommerce-tabs .panel > #reviews > #comments > h2 {
  margin-bottom: 15px;
}

.woocommerce-tabs .panel > #reviews textarea,
.woocommerce-tabs .panel > #reviews input[type="text"],
.woocommerce-tabs .panel > #reviews input[type="email"] {
  background-color: #f7f7f7 !important;
}

.woocommerce-tabs .shop_attributes td > p {
  margin-bottom: 0;
}

.woocommerce-tabs #comments {
  position: relative;
}

.woocommerce-tabs #comments #respond {
  margin: 35px 0 20px 0;
}

.woocommerce-tabs #comments .comment-navigation {
  margin-bottom: 20px;
}

.woocommerce-tabs #respond form,
.woocommerce-tabs #respond .form-submit,
.woocommerce-tabs #respond .form-submit input[type="submit"] {
  margin-bottom: 0;
}

.woocommerce-tabs .comment-form-author,
.woocommerce-tabs .comment-form-email {
  display: block;
  width: 100%;
  max-width: 100%;
}

.woocommerce-tabs .comment-form-author input,
.woocommerce-tabs .comment-form-email input,
.woocommerce-tabs label {
  width: 100%;
  max-width: 100%;
}

.woocommerce-tabs .comment-form-rating {
  margin: 10px 0;
}

.woocommerce-tabs .comment-form-rating > label {
  margin-bottom: 10px;
  display: block;
}

.woocommerce-tabs .comment-form-rating .stars:after {
  content: "";
  display: table;
  clear: both;
}

.woocommerce-tabs .comment-form-rating .stars a {
  display: block;
  line-height: 16px;
  font-size: 16px;
  text-align: center;
  text-indent: -9000px;
  position: relative;
  margin-bottom: 5px;
  padding: 10px 20px 10px 30px;
  border-right: 1px solid #eaeaea;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.woocommerce-tabs .comment-form-rating .stars a:last-child {
  border-right: none;
}

.woocommerce-tabs .comment-form-rating .stars a:after {
  font-weight: 400;
  text-transform: none;
  font-size: inherit;
  line-height: inherit;
  position: absolute;
  top: 10px;
  left: 25px;
  text-indent: 0px;
}

.woocommerce-tabs .comment-form-rating .stars a.active {
  color: #333 !important;
}

.woocommerce-tabs .comment-form-rating .stars a.star-1 {
  width: 15px;
}
.woocommerce-tabs .comment-form-rating .stars a.star-2 {
  width: 30px;
}
.woocommerce-tabs .comment-form-rating .stars a.star-3 {
  width: 45px;
}
.woocommerce-tabs .comment-form-rating .stars a.star-4 {
  width: 60px;
}
.woocommerce-tabs .comment-form-rating .stars a.star-5 {
  width: 75px;
}

.woocommerce-tabs .comment-form-rating .stars a.star-1:after {
  content: "\e85e";
}
.woocommerce-tabs .comment-form-rating .stars a.star-2:after {
  content: "\e85e\e85e";
}
.woocommerce-tabs .comment-form-rating .stars a.star-3:after {
  content: "\e85e\e85e\e85e";
}
.woocommerce-tabs .comment-form-rating .stars a.star-4:after {
  content: "\e85e\e85e\e85e\e85e";
}
.woocommerce-tabs .comment-form-rating .stars a.star-5:after {
  content: "\e85e\e85e\e85e\e85e\e85e";
}

.woocommerce-tabs .commentlist {
  margin: 0;
  list-style: none;
}

.woocommerce-tabs .commentlist .comment {
  padding: 40px 30px;
  width: 100%;
  margin: 0 0 20px 0;
  width: 100%;
  background-color: #f9f9f9;
  position: relative;
}

.woocommerce-tabs .commentlist .comment #respond {
  margin-bottom: 0;
}

.woocommerce-tabs .commentlist .comment .comment_container {
  display: table;
}

.woocommerce-tabs .commentlist .avatar,
.woocommerce-tabs .commentlist .comment-text {
  display: table-cell;
  vertical-align: top;
  width: 100%;
}

.woocommerce-tabs .commentlist .avatar {
  padding-right: 10px;
  width: 60px !important;
  height: 60px !important;
  border-radius: 60px;
  max-width: 60px;
  padding-right: 0;
}

.woocommerce-tabs .commentlist .comment-text {
  padding-left: 20px;
}

.woocommerce-tabs .commentlist .comment-text .meta {
  margin-bottom: 0;
  padding-bottom: 13px;
  text-transform: uppercase;
  color: #333;
}

.woocommerce-tabs .commentlist .comment-text time {
  font-weight: 6000;
  text-transform: none;
  color: #999;
}

.woocommerce-tabs .commentlist .comment-text .description {
  margin-bottom: 0;
}

.woocommerce-tabs #reply-title {
  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-top: 50px;
  position: relative;
}

/*rating*/
.woocommerce.single .woocommerce-product-rating {
  padding-bottom: 10px;
  margin-bottom: 10px;
  position: relative;
}

.woocommerce .star-rating,
.woocommerce-page .woocommerce-product-rating .star-rating,
.woocommerce .comment-text .star-rating,
.widget_recent_reviews .star-rating,
.widget_top_rated_products .star-rating,
.woocommerce-tabs .comment-form-rating .stars a {
  display: block;
  overflow: hidden;
  position: relative;
  height: 14px;
  line-height: 14px;
  font-size: 14px;
  width: 70px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.woocommerce .single-product-summary .star-rating,
.widget_rating_filter .star-rating {
  display: inline-block;
  vertical-align: middle;
}

.woocommerce-review-link {
  color: #999999;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  font-size: 16px;
}

.woocommerce-tabs .comment-form-rating .stars a:hover,
.woocommerce-review-link:hover {
  color: #333333;
}

.product-det .star-rating {
  margin: 20px auto 20px auto;
}

.woocommerce .comment-text .star-rating {
  float: right;
}

.woocommerce .star-rating span,
.woocommerce-page .woocommerce-product-rating .star-rating span,
.woocommerce .comment-text .star-rating span,
.widget_recent_reviews .star-rating span,
.widget_top_rated_products .star-rating span {
  overflow: hidden;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  padding-top: 15px;
  display: block;
}

.woocommerce .star-rating:before,
.woocommerce-page .woocommerce-product-rating .star-rating:before,
.woocommerce .comment-text .star-rating:before,
.widget_recent_reviews .star-rating:before,
.widget_top_rated_products .star-rating:before {
  content: "\e85e\e85e\e85e\e85e\e85e";
  color: #eeeeee;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  text-transform: none;
  font-size: inherit;
  line-height: inherit;
}

.woocommerce .star-rating span:before,
.woocommerce-page .woocommerce-product-rating .star-rating span:before,
.woocommerce .comment-text .star-rating span:before,
.widget_recent_reviews .star-rating span:before,
.widget_top_rated_products .star-rating span:before {
  content: "\e85e\e85e\e85e\e85e\e85e";
  top: 0;
  position: absolute;
  left: 0;
  font-weight: 400;
  text-transform: none;
  font-size: inherit;
  line-height: inherit;
}

.woocommerce .hreview-aggregate .star-rating,
.woocommerce-page .woocommerce-product-rating .hreview-aggregate .star-rating {
  margin: 10px 0 0 0;
}

.posted_in a,
.tagged_as a {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

table.variations {
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #eaeaea;
  width: 100%;
}

table.variations select,
table.variations input[type="text"] {
  background-color: #fff;
  border: 1px solid #ccc !important;
}

.reset_variations {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: #999;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 5px;
}

table.variations label {
  height: 50px;
  line-height: 50px;
  margin: 0;
  font-size: 16px;
}

table.variations tr,
table.variations td {
  padding: 0;
}

table.variations td.value {
  padding: 5px 0 0 10px;
}

table.variations tr:last-child td.label {
  vertical-align: top;
  padding-top: 3px;
}

.woocommerce-tabs .panel > h2,
.woocommerce-tabs .panel > #reviews > #comments > h2,
.related-products-title h3 {
  font-size: 26px;
  line-height: 36px;
  padding-bottom: 20px;
  margin-bottom: 40px;
  color: #333333;
}

.woocommerce-tabs .panel > h2:after,
.woocommerce-tabs .panel > #reviews > #comments > h2:after,
.related-products-title h3:after {
  content: "";
  width: 70px;
  height: 3px;
  display: block;
  margin-top: 7px;
}

.woocommerce-tabs .panel > h2:before,
.woocommerce-tabs .panel > #reviews > #comments > h2:before {
  left: 10px;
  bottom: -3px;
}

.woocommerce-tabs .tabs > li > a:before {
  display: inline-block;
  margin-right: 10px;
  width: 22px;
  height: 22px;
  position: relative;
  bottom: -5px;
  background-position: center center;
  background-repeat: no-repeat;
  content: "";
}

.woocommerce-tabs .tabs > li.description_tab > a:before {
}

.woocommerce-tabs .tabs > li.additional_information_tab > a:before {
}

.woocommerce-tabs .tabs > li.reviews_tab > a:before {
}

/*Cart
/*=============*/

.woocommerce .shop_table.cart {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  background-color: #fff;
}

.woocommerce .shop_table.cart td:not(.actions),
.woocommerce .shop_table.cart th {
  border: 1px solid #eeeeee;
  background-color: #ffffff;
}

.product-remove,
.product-quantity,
.product-price {
  text-align: center;
}

.woocommerce .shop_table.cart td.actions {
  border: none;
}

.woocommerce .shop_table.cart .product-remove a {
  display: inline-block;
  color: #999 !important;
  background-color: #f7f7f7;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  font-size: 16px;
  text-align: center;
}

.woocommerce .shop_table.cart .product-remove a:hover {
  color: #333 !important;
}

.woocommerce .shop_table.cart .coupon label {
  display: none;
}

.woocommerce .shop_table.cart .coupon input[type="text"] {
  height: 50px;
  margin-right: 15px;
  margin-bottom: 7px;
  line-height: 50px;
}

.woocommerce .cart-collaterals {
  padding: 50px 0 25px 0;
}

.woocommerce .cart-collaterals:after {
  content: "";
  display: table;
  clear: both;
}

.woocommerce .cart-collaterals .cart_totals,
.woocommerce .cart-collaterals .shipping_calculator {
  width: 100%;
  margin-bottom: 25px;
}

.woocommerce .cart-collaterals .cart_totals > h2,
.woocommerce .cart-collaterals .shipping_calculator > h2 {
  margin-bottom: 25px;
}

.woocommerce .cart-collaterals .cart_totals > table,
.woocommerce #order_review .shop_table {
  width: 100%;
  border-collapse: collapse;
}

.woocommerce .cart-collaterals .cart_totals > table td,
.woocommerce .cart-collaterals .cart_totals > table th,
.woocommerce #order_review .shop_table td,
.woocommerce #order_review .shop_table th {
  border: 1px solid #e0e0e0;
  background-color: #fff;
}

.woocommerce .cart-collaterals .shipping_calculator select,
.woocommerce .cart-collaterals .shipping_calculator input[type="text"] {
  width: 100%;
}

.product-name a {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.woocommerce-cart .shop_table.cart .quantity {
  width: 70% !important;
}

.woocommerce .variation {
  padding: 0px;
  border-radius: 0px;
  border: none;
  margin: 0px;
}

.woocommerce .variation dt {
  width: 70px;
  display: inline-block;
  float: left;
  text-align: left;
}

.woocommerce .variation dd {
  margin: 0 !important;
  padding: 0 !important;
  display: inline-block;
  float: left;
}

.woocommerce .variation dd p {
  margin-bottom: 0;
}

.woo-cart .variation {
  display: block;
}

.woo-cart .variation > * {
  float: none !important;
  width: auto !important;
}

.woocommerce-account .variation {
  background-color: #ffffff;
}

.woocommerce dl dt:after {
  display: none;
}

#shipping_method {
  list-style: none;
}

/*Checkout
/*=============*/

.select2-container .select2-choice {
  display: block;
  outline: 0;
  margin: 0 0 2px;
  text-align: left;
  vertical-align: top;
  height: 50px;
  line-height: 50px;
  max-width: 100%;
  width: 100%;
  padding: 0px 20px !important;
  font-size: 16px;
  background-color: #eee !important;
  border: none;
  color: #999;
  border-radius: 0 !important;
  border: none;
  position: relative;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 300ms;
  transition: all 300ms;
  box-shadow: none;
}

.woocommerce-checkout .page-content-container {
  padding-bottom: 40px;
}

.woocommerce .col2-set:after {
  content: "";
  display: table;
  clear: both;
}

.woocommerce .col2-set .col-1,
.woocommerce .col2-set .col-2 {
  width: 100%;
  margin-bottom: 25px;
}

.woocommerce-billing-fields h3,
.woocommerce-shipping-fields h3 {
  margin-bottom: 25px;
}

.woocommerce-billing-fields label,
.woocommerce-shipping-fields label {
  display: block;
  margin-bottom: 5px;
}

.woocommerce-billing-fields input,
.woocommerce-billing-fields select,
.woocommerce-shipping-fields input,
.woocommerce-shipping-fields select {
  width: 100%;
}

.woocommerce-billing-fields input[type="checkbox"],
.woocommerce-shipping-fields input[type="checkbox"] {
  width: auto;
}

.woocommerce #order_review_heading {
  margin: 50px 0 25px 0;
}

.woocommerce .payment_methods {
  list-style: none;
  margin-top: 50px;
}

.woocommerce-shipping-fields #ship-to-different-address label,
.woocommerce-shipping-fields
  #ship-to-different-address
  #ship-to-different-address-checkbox {
  display: inline-block;
}

.woocommerce-shipping-fields #ship-to-different-address {
  margin-bottom: 20px;
}

.woocommerce .order_details {
  list-style: none;
  margin-bottom: 50px;
}

.woocommerce .order_details li {
  background-color: #fff;
  border: 1px solid #f7f7f7;
  border-bottom: 0;
  padding: 10px;
}

.woocommerce .order_details > li:last-child {
  border-bottom: 1px solid #f7f7f7;
}

.woocommerce .order_details h3 {
  margin-top: 25px;
  margin-bottom: 15px;
}

.woocommerce .shop_table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 50px;
  background-color: #fff;
}

.woocommerce .shop_table th,
.woocommerce .shop_table td {
  border: 1px solid #eee;
  padding: 15px;
}

.woocommerce-account .shop_table th,
.woocommerce-account .shop_table td {
  background-color: #fff;
  border: 1px solid #eee;
}

.woocommerce .customer_details {
  width: 100%;
  margin-bottom: 50px;
}

.woocommerce .customer_details dt,
.woocommerce .customer_details dd {
  background-color: #fff;
  margin: 0;
  padding: 10px;
  vertical-align: middle;
  border-bottom: none;
}

.col2-set address {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #eee;
}

.woocommerce-account .col2-set address {
  background-color: #f7f7f7;
}

.payment_method_paypal img {
  display: none;
}

.woocommerce-order-received address,
.woocommerce-account address {
  padding: 20px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
}

.single-product-summary .post-social-share span:before {
  display: none;
}

.single-product-summary .post-social-share span {
  display: inline-block;
  text-align: left;
  background-color: transparent !important;
  height: 44px;
  line-height: 44px;
  padding: 0;
  padding-left: 30px;

  background-repeat: no-repeat;
  background-position: 0 50%;
  cursor: pointer;
  color: #999;
}

/*Account
/*=============*/

.woocommerce-account .form-row input,
.woocommerce-account .form-row select {
  width: 100%;
}

.woocommerce-account .form-row input[type="submit"],
.woocommerce-account .form-row input[type="checkbox"],
.woocommerce-account .form-row input[type="radio"] {
  width: auto;
}

a.edit,
a.view {
  text-transform: uppercase;
  color: #fff !important;
  padding: 10px 24px;
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  display: inline-block;
  margin-bottom: 10px;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.woocommerce-MyAccount-navigation {
  margin-bottom: 50px;
}

.woocommerce-MyAccount-navigation ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.woocommerce-MyAccount-navigation ul li {
  display: block;
  padding: 0;
  margin: 0;
}

.woocommerce-MyAccount-navigation ul li a {
  display: block;
  padding: 10px 24px;
  font-size: 13px;
  line-height: 22px;
  outline: none;
  border: none;
  color: #999 !important;
  position: relative;
  display: inline-block;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  z-index: 1;
  text-align: center;
  box-shadow: inset 0px 0px 0px 2px rgba(0, 0, 0, 0.1);
  background-color: transparent;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  width: 100%;
}

.woocommerce-MyAccount-navigation ul li a:hover,
.woocommerce-MyAccount-navigation ul li.is-active a {
  color: #333 !important;
  box-shadow: inset 0px 0px 0px 2px rgba(0, 0, 0, 0.2);
}

/*Widgets
/*=============*/

/*	Shopping cart, 
        Products, 
        Recently viewed, 
        Reviews
    /*-----------------------*/

.widget_shopping_cart .cart_list,
.widget_products .product_list_widget,
.widget_recently_viewed_products .product_list_widget,
.widget_recent_reviews .product_list_widget,
.widget_top_rated_products .product_list_widget,
.widget_rating_filter ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.widget_shopping_cart .cart_list > li,
.widget_products .product_list_widget > li,
.widget_recently_viewed_products .product_list_widget > li,
.widget_recent_reviews .product_list_widget > li,
.widget_top_rated_products .product_list_widget > li {
  padding: 10px 0;
  border-bottom: 1px solid #eeeeee;
  position: relative;
}

.widget_shopping_cart .cart_list > li:last-child,
.widget_products .product_list_widget > li:last-child,
.widget_recently_viewed_products .product_list_widget > li:last-child,
.widget_recent_reviews .product_list_widget > li:last-child,
.widget_top_rated_products .product_list_widget > li:last-child {
  border-bottom: none;
}

.widget_shopping_cart .cart_list > li:after,
.widget_products .product_list_widget > li:after,
.widget_recently_viewed_products .product_list_widget > li:after,
.widget_recent_reviews .product_list_widget > li:after,
.widget_top_rated_products .product_list_widget > li:after {
  content: "";
  display: table;
  clear: both;
}

.widget_shopping_cart .cart_list > li > a,
.widget_products .product_list_widget > li > a,
.widget_recently_viewed_products .product_list_widget > li > a,
.widget_recent_reviews .product_list_widget > li > a,
.widget_top_rated_products .product_list_widget > li > a {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: block;
  font-size: 18px !important;
  line-height: 28px !important;
  text-transform: none !important;
  color: #777;
}

.widget_shopping_cart .cart_list > li > a {
  font-size: 14px;
}

.woo-cart .widget_shopping_cart .cart_list > li > a {
  color: #999 !important;
}

.widget_shopping_cart .cart_list > li > a img,
.widget_products .product_list_widget > li > a img,
.widget_recently_viewed_products .product_list_widget > li a img,
.widget_recent_reviews .product_list_widget > li a img,
.widget_top_rated_products .product_list_widget > li a img {
  display: block;
  float: left;
  margin: 0 10px 0px 0;
  max-width: 70px;
  height: auto;
}

.widget_products .product_list_widget .amount,
.widget_recently_viewed_products .product_list_widget .amount,
.widget_recent_reviews .product_list_widget .amount,
.widget_top_rated_products .product_list_widget .amount {
  font-weight: 400;
  font-size: 15px;
}

.widget_products .product_list_widget a,
.widget_recently_viewed_products .product_list_widget a,
.widget_recent_reviews .product_list_widget a,
.widget_top_rated_products .product_list_widget a {
  display: block;
}

.widget_shopping_cart .quantity {
  width: auto !important;
  display: inline-block;
  font-weight: 700;
  font-size: 16px;
  padding-top: 5px;
}

.widget_shopping_cart .total {
  margin-bottom: 25px;
  margin-top: 25px;
}

.widget_shopping_cart p.buttons {
  margin-bottom: 0;
}

.widget_shopping_cart p.buttons > a,
.widget_price_filter .price_slider_amount .button {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #999;
  box-shadow: inset 0 0 0 2px #e0e0e0;
  padding: 8px 20px;
  font-size: 12px;
  line-height: 22px;
  display: inline-block !important;
  text-align: center;
  text-transform: uppercase;
  -webkit-backface-visibility: hidden;
  background-color: transparent;
}

.woo-cart .widget_shopping_cart p.buttons > a {
  width: 135px;
  display: block;
  float: left;
}

.widget_shopping_cart p.buttons > a + a {
  margin-left: 10px;
}

.widget_recent_reviews .star-rating,
.widget_top_rated_products .star-rating {
  margin-top: 5px;
  margin-bottom: 5px;
}

.widget_shopping_cart .cart_list .remove {
  display: block;
  position: absolute !important;
  top: 10px;
  right: 0px;
  width: 20px;
  height: 20px;
  line-height: 20px !important;
  color: #333;
  text-align: center;
  background-color: transparent;
  z-index: 15;
}

.woo-cart .widget_shopping_cart .cart_list li:first-child .remove {
  top: 15px;
}

.widget_shopping_cart .cart_list .remove {
  color: #333 !important;
}

.widget_shopping_cart .cart_list > li > a.remove:hover {
  color: #ffffff !important;
}

/*	Price filter
    /*----------------------*/

.widget_price_filter .price_slider {
  margin-bottom: 25px;
}
.widget_price_filter .clear {
  clear: both;
}

.widget_price_filter .price_slider_amount .button {
  padding: 10px 22px 10px 22px;
  font-size: 13px;
  line-height: 18px;
  top: auto;
  border-radius: 0;
  margin: 0;
}

.widget_price_filter .price_slider_amount .price_label {
  padding: 7px 22px 10px 22px;
  text-align: center;
  display: inline-block;
}

.footer .widget_price_filter .price_slider_amount .price_label {
  background-color: transparent;
}

.widget_price_filter .ui-slider {
  position: relative;
  text-align: left;
}

.widget_price_filter .ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  cursor: pointer;
  outline: none;
  top: -5px;
  background-color: #ffffff;
  border: 1px solid;
}

.widget_price_filter .ui-slider .ui-slider-handle:last-child {
  margin-left: -16px;
}

.widget_price_filter .ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  display: block;
  border: 0;
  border-radius: 10px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.widget_price_filter .price_slider_wrapper .ui-widget-content {
  border-radius: 10px;
  background: #e0e0e0;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.footer .widget_price_filter .price_slider_wrapper .ui-widget-content {
  background: #2d2d2d;
}

.widget_price_filter .ui-slider-horizontal {
  height: 7px;
}

.widget_price_filter .ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}

.widget_price_filter .ui-slider-horizontal .ui-slider-range-min {
  left: -1px;
}

.widget_price_filter .ui-slider-horizontal .ui-slider-range-max {
  right: -1px;
}

/*	Product categories, 
        Layered nav
    /*----------------------*/

.widget_layered_nav ul,
.widget_layered_nav_filters ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.widget_layered_nav ul li,
.widget_layered_nav_filters ul li {
  font-size: 14px;
  margin-bottom: 10px;
  position: relative;
  padding: 0;
}

.widget_layered_nav ul li a,
.widget_layered_nav_filters ul li a {
  font-weight: 700;
  position: relative;
  display: block;
  color: #444 !important;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}

.widget_layered_nav ul li span,
.widget_layered_nav_filters ul li span {
  position: absolute;
  top: 50%;
  display: block;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.widget_layered_nav ul li ul,
.widget_layered_nav_filters ul li ul {
  margin-left: 20px;
}

.widget_product_categories select,
.widget_layered_nav select,
.widget_layered_nav_filters select {
  width: 100%;
  padding-right: 5px;
}

/*	Products search
    /*----------------------*/

.nz-product-search {
  padding-bottom: 50px;
  text-align: center;
}

.nz-product-search > .woocommerce-product-search {
  display: inline-block;
  width: 100%;
}

.widget_product_search form,
.woocommerce-product-search {
  position: relative;
  margin-bottom: 0;
}

.widget_product_search form label,
.woocommerce-product-search label {
  display: none;
}

/*	Shortcodes
    /*----------------------*/
.product.woocommerce > del,
.product.woocommerce > ins,
.product.woocommerce > small {
  margin-right: 5px;
  display: inline-block;
}

/*RESPONSIVE
/*=============*/

/*MIN MOB PORTRAIT
/*-------------*/

@media only screen and (min-width: 320px) {
  .container,
  .full-width-true .nz-tabs.full-true .tab-content,
  .ninzio-navigation,
  .wpml-ls-statics-post_translations {
    /* max-width: 290px;
    width: 290px; */
  }

  .woocommerce .shop_table.cart td,
  .woocommerce .shop_table.cart th {
    padding: 5px;
  }

  .woocommerce .shop_table.cart td.actions {
    padding: 20px 0px;
  }

  .woocommerce .shop_table.cart .product-thumbnail {
    display: none;
  }

  .woocommerce .shop_table.cart .product-thumbnail img {
    max-width: 75px;
  }

  .woocommerce .shop_table.cart .quantity input[type="number"],
  .woocommerce .shop_table.cart .quantity input[type="button"] {
    width: 70% !important;
    display: block;
    float: none;
    margin: 0 auto;
  }

  .woocommerce .shop_table.cart .quantity input[type="button"] {
    height: 30px;
  }

  .sidebar .widget_calendar caption,
  .sidebar .widget_calendar th,
  .sidebar .widget_calendar td,
  .sidebar .widget_calendar td#prev,
  .sidebar .widget_calendar td#next,
  .sidebar .widget_calendar td#prev span,
  .sidebar .widget_calendar td#next span,
  .footer-widget-menu .widget_calendar caption,
  .footer-widget-menu .widget_calendar th,
  .footer-widget-menu .widget_calendar td,
  .footer-widget-menu .widget_calendar td#prev,
  .footer-widget-menu .widget_calendar td#next,
  .footer-widget-menu .widget_calendar td#prev span,
  .footer-widget-menu .widget_calendar td#next span {
    height: 41px;
    width: 41px;
    line-height: 41px;
  }
}

/*MAX MOB PORTRAIT
/*-------------*/

@media only screen and (max-width: 320px) {
  button,
  input[type="reset"],
  input[type="submit"],
  input[type="button"],
  .price_slider_amount .price_label,
  .woocommerce .single-product-summary .entry-summary button,
  .button,
  .woocommerce-MyAccount-navigation ul li {
    width: 100% !important;
    margin-left: 0 !important;
    margin-bottom: 5px;
    text-align: center;
  }

  .woocommerce .product .single-product-image .product-status {
    top: 10px;
    left: 10px;
  }

  .woocommerce .quantity input[type="number"] {
    width: 100% !important;
  }

  .woocommerce-tabs .comment-form-rating .stars a {
    border-right: none;
  }

  .nz-testimonials .text {
    text-align: center;
  }

  .single .post-body {
    padding: 50px 20px;
  }

  .nz-testimonials .text {
    padding: 20px;
  }

  .nz-testimonials .text:after {
    display: none;
  }

  .ninzio-nav-single > *:not(:only-child) {
    font-size: 10px;
  }

  .single .post .post-social-share .share-label {
    padding-left: 0px;
  }

  .rich-header {
    height: auto;
  }

  .nz-mailchimp #mc-embedded-subscribe {
    position: static;
  }

  .nz-mailchimp #mce-EMAIL {
    padding-right: 20px !important;
  }
}

/*MIN MOB LANDSCAPE
/*-------------*/

@media only screen and (min-width: 480px) {
  .container,
  .full-width-true .nz-tabs.full-true .tab-content,
  .ninzio-navigation,
  .wpml-ls-statics-post_translations {
    /* max-width: 440px;
    width: 440px; */
  }

  .woocommerce .quantity {
    display: inline-block;
    vertical-align: top;
  }

  .woocommerce-tabs .comment-form-rating .stars a {
    float: left;
  }

  td.actions input[type="submit"] + input[type="submit"] {
    margin-left: 4px;
  }

  .woocommerce .shop_table.cart .quantity {
    display: block;
    width: 100%;
  }

  .post-social-share {
    width: auto;
  }

  .sidebar .widget_calendar caption,
  .sidebar .widget_calendar th,
  .sidebar .widget_calendar td,
  .sidebar .widget_calendar td#prev,
  .sidebar .widget_calendar td#next,
  .sidebar .widget_calendar td#prev span,
  .sidebar .widget_calendar td#next span {
    height: 62px;
    width: 62px;
    line-height: 62px;
  }

  .footer-widget-area .widget_calendar caption,
  .footer-widget-area .widget_calendar th,
  .footer-widget-area .widget_calendar td,
  .footer-widget-area .widget_calendar td#prev,
  .footer-widget-area .widget_calendar td#next,
  .footer-widget-area .widget_calendar td#prev span,
  .footer-widget-area .widget_calendar td#next span {
    height: 62px;
    width: 62px;
    line-height: 62px;
  }

  .mob-header .widget_calendar caption,
  .mob-header .widget_calendar th,
  .mob-header .widget_calendar td,
  .mob-header .widget_calendar td#prev,
  .mob-header .widget_calendar td#next,
  .mob-header .widget_calendar td#prev span,
  .mob-header .widget_calendar td#next span {
    height: 62px;
    width: 62px;
    line-height: 62px;
  }
}

/*MIN MOBILE 640
/*--------------*/

@media only screen and (min-width: 640px) {
  .nz-slick-item {
    padding-left: 30px;
    padding-right: 30px;
  }

  .slick-prev {
    left: 65px;
  }
  .slick-next {
    right: 65px;
  }

  .loop .projects-layout .projects,
  .nz-recent-projects .projects {
    width: 50%;
  }

  .nz-related-projects .projects {
    width: 100% !important;
  }

  .null-instagram-feed ul li,
  .widget_recent_projects .recent-projects > .post {
    width: 25%;
  }
}

/*MIN TABLET PORTRAIT
/*-------------*/

@media only screen and (max-width: 767px) {
  .no-padding,
  .no-padding > .col-inner {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .woocommerce-tabs .tabs {
    display: block;
  }

  .woocommerce-tabs .tabs > li {
    display: block;
    width: 100%;
    float: none;
    margin: 0 !important;
  }

  .woocommerce-tabs .tabs > li.active:last-child:after {
    bottom: -1px;
  }

  .full-width-true .col12 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .nz-count {
    margin-bottom: 50px;
  }

  .single .projects-head .projects-navigation {
    position: relative;
    margin-top: 10px;
    text-align: center;
  }

  .single .projects-head .projects-navigation a {
    float: none !important;
  }

  .woocommerce-message a,
  .woocommerce-info a,
  .woocommerce-error li a,
  .woocommerce-message .button,
  .woocommerce-info .button {
    display: block;
  }

  .site-widget-area {
    width: 320px;
    -webkit-transform: translateX(320px);
    -ms-transform: translateX(320px);
    transform: translateX(320px);
  }

  .rich-header.version1 {
    height: 170px;
  }

  .rich-header h1 {
    text-align: center;
    margin-bottom: 0;
  }

  .rich-header.version1 h1,
  .rich-header.version2 h1 {
    font-size: 20px !important;
    line-height: 30px !important;
    margin-bottom: 10px;
  }

  .rich-header .rh-subtitle {
    font-size: 16px;
    line-height: 26px;
  }

  .standard .loop .blog-post .format-quote .post-body .format-wrapper,
  .standard .loop .blog-post .format-status .post-body .format-wrapper,
  .standard .loop .blog-post .format-link .post-body .format-wrapper,
  .standard .loop .blog-post .format-aside .post-body .format-wrapper {
    padding: 30px;
  }

  .search-form {
    width: 100%;
  }

  .site-widget-area .custom-scroll-bar {
    padding: 80px 30px 50px 30px;
  }

  .site-widget-area .photos_from_flickr .flickr_badge_image {
    width: 66px;
  }

  .site-widget-area .widget_calendar caption,
  .site-widget-area .widget_calendar th,
  .site-widget-area .widget_calendar td,
  .site-widget-area .widget_calendar td#prev,
  .site-widget-area .widget_calendar td#next,
  .site-widget-area .widget_calendar td#prev span,
  .site-widget-area .widget_calendar td#next span {
    height: 37px;
    width: 37px;
    line-height: 37px;
  }

  .list .post .post-category {
    left: 20px;
  }

  .projects-navigation,
  .post-single-navigation {
    display: none;
  }

  .woocommerce-tabs .tabs {
    border-radius: 0px;
  }

  .woocommerce-tabs .tabs > li:after {
    display: none;
  }

  .slick-dots li {
    margin: 0 10px;
  }

  .slick-dots li:after {
    display: none;
  }

  .nz-content-box .back_active .box-icon-wrap,
  .nz-content-box .bord_active .box-icon-wrap {
    left: 0px;
  }

  .comment-list .comment .comment-body {
    display: block;
  }

  .comment-list .comment-gavatar,
  .comment-list .comment-content {
    display: block;
  }

  .comment-list .comment-gavatar {
    margin-bottom: 30px;
  }

  .single .projects-head .project-title {
    text-align: center;
  }

  .woocommerce-tabs .tabs {
    border-bottom: none;
  }

  .vc_hidden-xs {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .rich-header.version1 .rh-content:after {
    content: "";
    display: table;
    clear: both;
  }

  .rich-header.version1 .rh-content .nz-breadcrumbs {
    float: left;
  }

  .rich-header.version1 .rh-content h1 {
    float: left;
    position: relative;
    padding-left: 20px;
  }

  .rich-header.version1 .rh-content h1:before {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    content: "";
    width: 6px;
    height: 80%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    border-left-width: 2px;
    border-right-width: 2px;
    border-left-style: solid;
    border-right-style: solid;
  }

  .rich-header.version1 .nz-breadcrumbs {
    text-align: right;
    position: relative;
  }

  .container,
  .ninzio-navigation,
  .wpml-ls-statics-post_translations {
    max-width: 720px;
    width: 720px;
  }

  .nz-row {
    margin-left: -15px;
    margin-right: -15px;
  }

  .col12,
  .col11,
  .col10,
  .col9,
  .col8,
  .col7,
  .col6,
  .col5,
  .col4,
  .col3,
  .col2,
  .col1 {
    float: left;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .col1 {
    width: 8.333333332%;
  }
  .col2 {
    width: 16.666666666666664%;
  }
  .col3 {
    width: 25%;
  }
  .col4 {
    width: 33.3%;
  }
  .col5 {
    width: 41.66666666666667%;
  }
  .col6 {
    width: 50%;
  }
  .col7 {
    width: 58.333333336%;
  }
  .col8 {
    width: 66.66666666666666%;
  }
  .col9 {
    width: 75%;
  }
  .col10 {
    width: 83.33333334%;
  }
  .col11 {
    width: 91.66666666666666%;
  }
  .col12 {
    width: 100%;
  }

  .footer-info .container {
    display: table;
  }

  .footer-info .container > * {
    display: table-cell;
    vertical-align: middle;
    width: auto;
    border-bottom: none;
  }

  .footer-info .container > .get-location {
    border-left: 1px solid #434548;
    border-right: 1px solid #434548;
    padding: 0 25px;
    width: 200px;
  }

  .footer-info .container > .footer-text {
    border-left: 1px solid #434548;
    padding: 0 25px;
    position: relative;
    left: -1px;
  }

  .footer-widget-area {
    margin-left: -15px;
    margin-right: -15px;
    width: 100%;
  }

  .footer-widget-area > .widget,
  .footer-widget-area > .yawp_wim_widget {
    width: 50%;
    float: left;
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
  }

  .nz-content-box,
  .nz-timer,
  .nz-counter,
  .woocommerce .products,
  .woocommerce .menu-holder,
  .nz-pricing-table {
    margin-right: -15px;
    margin-left: -15px;
  }

  .nz-content-box.version2 {
    margin-right: -30px;
    margin-left: -30px;
  }

  .nz-recent-products .products {
    margin-right: -1px;
    margin-left: -1px;
  }

  .woocommerce .menu-holder .products {
    margin-left: 0;
    margin-right: 0;
  }

  .nz-timer ul > li {
    width: 50%;
    float: left;
  }

  .nz-content-box > .nz-box,
  .nz-timer > .days,
  .nz-counter > .nz-count,
  .nz-pricing-table > .column {
    padding-left: 15px;
    padding-right: 15px;
    float: left;
  }

  .nz-content-box.version2 > .nz-box {
    position: relative;
    padding-left: 30px;
    padding-right: 30px;
  }

  .nz-content-box.version2 > .nz-box:after {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #eee;
    width: 1px;
    height: 100%;
    content: "";
  }

  .nz-content-box.version2[data-columns="4"] > .nz-box:nth-child(2n + 2):after,
  .nz-content-box.version2[data-columns="2"] > .nz-box:nth-child(2n + 2):after,
  .nz-content-box.version2[data-columns="3"] > .nz-box:nth-child(3n + 3):after {
    display: none;
  }

  .nz-persons > .person {
    float: left;
  }

  .nz-content-box[data-columns="1"] > .nz-box,
  .nz-counter[data-columns="1"] > .nz-count,
  .nz-persons[data-columns="1"] > .person {
    width: 100%;
    float: none;
  }

  .nz-content-box[data-columns="2"] > .nz-box,
  .nz-content-box[data-columns="4"] > .nz-box,
  .nz-counter[data-columns="2"] > .nz-count,
  .nz-persons[data-columns="2"] > .person {
    width: 50%;
  }

  .nz-content-box[data-columns="3"] > .nz-box,
  .nz-counter[data-columns="3"] > .nz-count,
  .nz-persons[data-columns="3"] > .person,
  .nz-pricing-table[data-columns="3"] > .column {
    width: 33.3%;
  }

  .nz-related-projects .projects {
    width: 50% !important;
  }

  .nz-persons[data-columns="4"] > .person,
  .nz-pricing-table[data-columns="4"] > .column,
  .nz-content-box-2[data-columns="4"] > .nz-box,
  .nz-counter[data-columns="4"] > .nz-count {
    width: 50%;
  }

  .count-border {
    width: 1px;
    height: 100%;
    display: block;
    position: absolute;
    top: 50%;
    right: -15px;
    left: auto;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0;
  }

  .nz-counter[data-columns="4"] .nz-count:nth-child(2n + 1) .count-border,
  .nz-counter[data-columns="2"] .nz-count:nth-child(2n + 1) .count-border,
  .nz-counter[data-columns="3"] .nz-count .count-border {
    opacity: 1;
  }

  .nz-content-box[data-columns="5"] > .nz-box,
  .nz-pricing-table[data-columns="5"] > .column {
    width: 20%;
  }

  .nz-content-box[data-columns="6"] > .nz-box {
    width: 16.66666666666667%;
  }

  .slick-prev {
    left: 100px;
  }
  .slick-next {
    right: 100px;
  }

  .nz-section-back-video,
  .nz-video-overlay {
    display: block;
  }

  .nz-tabs.horizontal:not(.tab-full) .tabset {
    padding-left: 0px;
    position: relative;
  }

  .nz-tabs.horizontal:not(.tab-full) .tabset .tab {
    float: left;
    margin-right: 5px;
  }

  .nz-tabs.horizontal:not(.tab-full) .tabset .tab.active,
  .nz-tabs.vertical .tabset .tab.active {
    position: relative;
  }

  .col6 .nz-tabs.vertical .tab,
  .col7 .nz-tabs.vertical .tab,
  .col8 .nz-tabs.vertical .tab,
  .col9 .nz-tabs.vertical .tab,
  .col10 .nz-tabs.vertical .tab,
  .col11 .nz-tabs.vertical .tab,
  .col12 .nz-tabs.vertical .tab {
    margin-bottom: 5px;
  }

  .col6 .nz-tabs.vertical .tab.active:after,
  .col7 .nz-tabs.vertical .tab.active:after,
  .col8 .nz-tabs.vertical .tab.active:after,
  .col9 .nz-tabs.vertical .tab.active:after,
  .col10 .nz-tabs.vertical .tab.active:after,
  .col11 .nz-tabs.vertical .tab.active:after,
  .col12 .nz-tabs.vertical .tab.active:after {
    content: "";
    display: block;
    bottom: auto !important;
    left: auto;
    top: 50%;
    right: -1px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    height: calc(100% - 2px) !important;
    width: 2px !important;
    position: absolute;
    background-color: #ffffff !important;
  }

  .col6 .nz-tabs.vertical .tabset,
  .col6 .nz-tabs.vertical .tabs-container,
  .col7 .nz-tabs.vertical .tabset,
  .col7 .nz-tabs.vertical .tabs-container,
  .col8 .nz-tabs.vertical .tabset,
  .col8 .nz-tabs.vertical .tabs-container,
  .col9 .nz-tabs.vertical .tabset,
  .col9 .nz-tabs.vertical .tabs-container,
  .col10 .nz-tabs.vertical .tabset,
  .col10 .nz-tabs.vertical .tabs-container,
  .col11 .nz-tabs.vertical .tabset,
  .col11 .nz-tabs.vertical .tabs-container,
  .col12 .nz-tabs.vertical .tabset,
  .col12 .nz-tabs.vertical .tabs-container {
    float: left;
  }

  .col6 .nz-tabs.vertical .tab,
  .col7 .nz-tabs.vertical .tab,
  .col8 .nz-tabs.vertical .tab,
  .col9 .nz-tabs.vertical .tab,
  .col10 .nz-tabs.vertical .tab,
  .col11 .nz-tabs.vertical .tab,
  .col12 .nz-tabs.vertical .tab {
    text-align: left;
  }

  .col6 .nz-tabs.vertical .tabs-container .tab-content,
  .col7 .nz-tabs.vertical .tabs-container .tab-content,
  .col8 .nz-tabs.vertical .tabs-container .tab-content,
  .col9 .nz-tabs.vertical .tabs-container .tab-content,
  .col10 .nz-tabs.vertical .tabs-container .tab-content,
  .col11 .nz-tabs.vertical .tabs-container .tab-content,
  .col12 .nz-tabs.vertical .tabs-container .tab-content {
    padding-left: 50px;
    padding-top: 0px;
    border-top: none;
    border-left: 1px solid #e0e0e0;
  }

  .col6 .nz-tabs.vertical .tabset,
  .col7 .nz-tabs.vertical .tabset,
  .col8 .nz-tabs.vertical .tabset,
  .col9 .nz-tabs.vertical .tabset,
  .col10 .nz-tabs.vertical .tabset,
  .col11 .nz-tabs.vertical .tabset,
  .col12 .nz-tabs.vertical .tabset {
    width: 30%;
  }

  .col6 .nz-tabs.vertical .tabs-container,
  .col7 .nz-tabs.vertical .tabs-container,
  .col8 .nz-tabs.vertical .tabs-container,
  .col9 .nz-tabs.vertical .tabs-container,
  .col10 .nz-tabs.vertical .tabs-container,
  .col11 .nz-tabs.vertical .tabs-container,
  .col12 .nz-tabs.vertical .tabs-container {
    width: 70%;
  }

  .loop .blog-post *[data-grid="ninzio_01"],
  .standard .loop .main-content .blog-post *[data-grid="ninzio_01"] {
    width: 100%;
  }

  .medium .loop .blog-post *[data-grid="ninzio_01"],
  .small .loop .blog-post *[data-grid="ninzio_01"],
  .large .loop .blog-post *[data-grid="ninzio_01"],
  .loop .main-content .blog-post *[data-grid="ninzio_01"],
  .nz-related-projects.column-4 .projects,
  .nz-recent-products[data-columns="4"] .products .product {
    width: 50%;
  }

  .list .loop .main-content .blog-post *[data-grid="ninzio_01"],
  .standard .loop .main-content .blog-post *[data-grid="ninzio_01"] {
    float: none;
    width: 100% !important;
  }

  .list .loop .blog-post *[data-grid="ninzio_01"]:after {
    content: "";
    display: table;
    clear: both;
  }

  .list .loop .blog-post *[data-grid="ninzio_01"] > .nz-thumbnail,
  .list .loop .blog-post *[data-grid="ninzio_01"] > .post-body,
  .list .loop .blog-post *[data-grid="ninzio_01"] > .post-gallery {
    float: left;
    display: table-cell;
    width: 360px;
    min-height: 292px;
  }

  .list .loop .blog-post *[data-grid="ninzio_01"] .post-body {
    padding: 0;
  }

  .list .loop .blog-post *[data-grid="ninzio_01"] .post-body-in {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 40px 30px;
  }

  .related-posts {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }

  .related-posts *[data-grid="ninzio_01"],
  .nz-related-products *[data-grid="ninzio_01"] {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .woocommerce .products .product {
    width: 100%;
  }

  .woocommerce .main-content .products .product {
    width: 50%;
    float: left;
  }

  .nz-related-products.column-4 *[data-grid="ninzio_01"],
  .nz-recent-products.column-4 *[data-grid="ninzio_01"],
  .nz-recent-products[data-columns="2"] .products .product {
    width: 50% !important;
    float: left;
  }

  .nz-related-products.column-3 *[data-grid="ninzio_01"],
  .nz-recent-products[data-columns="3"] .products .product {
    width: 33.3333333% !important;
    float: left;
  }

  .thumbset .thumb {
    padding: 10px 5px 0 5px;
  }

  .comment-list ul.children {
    padding: 0 0 0 70px;
    position: relative;
  }

  /*GALLERY*/
  .nz-gallery[data-columns="1"].grid .gallery-item {
    width: 100%;
  }
  .nz-gallery[data-columns="2"].grid .gallery-item {
    width: 50%;
  }
  .nz-gallery[data-columns="3"].grid .gallery-item {
    width: 33.3%;
  }
  .nz-gallery[data-columns="4"].grid .gallery-item {
    width: 25%;
  }
  .nz-gallery[data-columns="5"].grid .gallery-item {
    width: 20%;
  }
  .nz-gallery[data-columns="6"].grid .gallery-item {
    width: 33.3%;
  }
  .nz-gallery[data-columns="7"].grid .gallery-item {
    width: 33.3%;
  }
  .nz-gallery[data-columns="8"].grid .gallery-item {
    width: 25%;
  }
  .nz-gallery[data-columns="9"].grid .gallery-item {
    width: 33.3%;
  }

  .nz-gallery[data-columns="2"] .wp-caption-text,
  .nz-gallery[data-columns="3"] .wp-caption-text,
  .nz-gallery[data-columns="4"] .wp-caption-text {
    display: block;
  }

  .nz-recent-products .products .product {
    padding-right: 15px;
    padding-left: 15px;
    width: 33.3333333%;
  }

  .woocommerce .woocommerce-result-count {
    float: right;
    font-size: 14px;
    margin-bottom: 0;
    position: relative;
    top: 20px;
  }

  .woocommerce.single .woocommerce-message {
    margin-top: 0px;
    margin-bottom: 30px;
  }

  .woocommerce .woocommerce-message {
    margin-top: 44px;
  }

  .woocommerce .loop.width-true .products {
    margin-left: -15px;
    margin-right: -15px;
  }

  .woocommerce .loop.width-true .products {
    margin-left: -15px;
    margin-right: -15px;
  }

  .loop.width-true .shop-layout {
    padding-left: 30px;
    padding-right: 30px;
  }

  .loop.width-true .blog-layout {
    padding-left: 15px;
    padding-right: 15px;
  }

  .woocommerce .loop.width-true .woocommerce-ordering {
    right: 30px;
  }

  .single-product-image,
  .single-product-summary {
    width: 50%;
    float: left;
  }

  .single-product-image {
    padding-right: 30px;
  }

  .coupon {
    display: inline-block;
  }

  .coupon input {
    width: auto;
    margin-right: 0;
    display: inline-block;
  }

  .actions input {
    margin-left: 0 !important;
    margin-right: 5px !important;
  }

  .woocommerce .shop_table.cart .quantity {
    display: inline-block;
    width: 50%;
  }

  .woocommerce .cart-collaterals .cart_totals,
  .woocommerce .cart-collaterals .shipping_calculator,
  .woocommerce .col2-set .col-1,
  .woocommerce .col2-set .col-2 {
    width: 50%;
    float: left;
  }

  .woocommerce .cart-collaterals .cart_totals,
  .woocommerce .col2-set .col-1 {
    padding-right: 15px;
  }

  .woocommerce .cart-collaterals .shipping_calculator,
  .woocommerce .col2-set .col-2 {
    padding-left: 15px;
  }
  .woocommerce .shop_table.cart .product-thumbnail {
    display: block;
    border-left: none !important;
    border-right: none !important;
  }

  .woocommerce .shop_table.cart td.product-thumbnail {
    border: none !important;
    border-top: 1px solid #f7f7f7 !important;
  }

  .woocommerce
    .shop_table.cart
    tbody
    .cart_item:first-child
    td.product-thumbnail {
    border-top: none !important;
  }

  .woocommerce .shop_table.cart td,
  .woocommerce .shop_table.cart th {
    padding: 20px;
  }

  .woocommerce-account .page-content .woocommerce {
    width: 30%;
  }

  .woocommerce-account.logged-in .page-content .woocommerce {
    width: 100%;
  }

  .loop .post,
  .nz-recent-posts .post {
    padding-left: 15px;
    padding-right: 15px;
  }

  .woocommerce .products .product {
    float: left;
    width: 33.3333333%;
  }

  .woocommerce.columns-1 .products .product {
    width: 100%;
  }
  .woocommerce.columns-2 .products .product {
    width: 50%;
  }
  .woocommerce.columns-3 .products .product {
    width: 33.33333333333333%;
  }
  .woocommerce.columns-4 .products .product {
    width: 25%;
  }
  .woocommerce.columns-5 .products .product {
    width: 20%;
  }
  .woocommerce.columns-6 .products .product {
    width: 16.66666666666667%;
  }

  .woocommerce .small .loop .products .product {
    width: 50%;
  }

  .woocommerce .products,
  .woocommerce .nz-related-products .products {
    margin-left: -15px;
    margin-right: -15px;
  }

  .woocommerce .products .product,
  .woocommerce .nz-related-products .products .product {
    padding-left: 15px;
    padding-right: 15px;
  }

  .loop .blog-post,
  .nz-recent-posts {
    margin-left: -15px;
    margin-right: -15px;
  }

  .nz-timeline {
    position: relative;
  }

  .error404-big {
    font-size: 180px;
    line-height: 180px;
  }

  .nz-product-search > .woocommerce-product-search {
    width: 60%;
  }

  #lang_sel_footer ul {
    text-align: center;
  }

  #lang_sel_footer ul li {
    display: inline-block;
    width: auto;
  }

  .sidebar .widget_calendar caption,
  .sidebar .widget_calendar th,
  .sidebar .widget_calendar td,
  .sidebar .widget_calendar td#prev,
  .sidebar .widget_calendar td#next,
  .sidebar .widget_calendar td#prev span,
  .sidebar .widget_calendar td#next span {
    height: 102px;
    width: 102px;
    line-height: 102px;
  }

  .mob-header .widget_calendar caption,
  .mob-header .widget_calendar th,
  .mob-header .widget_calendar td,
  .mob-header .widget_calendar td#prev,
  .mob-header .widget_calendar td#next,
  .mob-header .widget_calendar td#prev span,
  .mob-header .widget_calendar td#next span {
    height: 102px;
    width: 102px;
    line-height: 102px;
  }

  .footer-widget-area .widget_calendar caption,
  .footer-widget-area .widget_calendar th,
  .footer-widget-area .widget_calendar td,
  .footer-widget-area .widget_calendar td#prev,
  .footer-widget-area .widget_calendar td#next,
  .footer-widget-area .widget_calendar td#prev span,
  .footer-widget-area .widget_calendar td#next span {
    height: 44px;
    width: 44px;
    line-height: 44px;
  }

  .photos_from_flickr .flickr_badge_image,
  .mob-header .photos_from_flickr .flickr_badge_image {
    width: 72px;
  }

  .footer-widget-area .photos_from_flickr .flickr_badge_image {
    width: 62px;
  }

  .woocommerce-tabs .tabs > li.active:before {
    opacity: 1;
  }

  .woocommerce-tabs .tabs > li a {
    padding: 10px 40px 10px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .nz-pricing-table[data-columns="4"] .price {
    font-size: 36px;
  }

  .nz-pricing-table[data-columns="3"] .price {
    font-size: 46px;
  }

  .shoping-products .shop-loader,
  .shoping-subcategories .shop-loader {
    width: calc(100% - 30px);
  }

  .null-instagram-feed ul li,
  .widget_recent_projects .recent-projects > .post {
    width: 16.66666666666667%;
  }

  .footer-widget-area .null-instagram-feed ul li,
  .footer-widget-area .widget_recent_projects .recent-projects > .post {
    width: 33.3333333%;
  }

  .list .blog-post .post .post-date-full {
    display: none;
  }

  .list .blog-post .post .post-date-full-list {
    display: inline-block !important;
  }

  .nz-content-box .back_active .box-icon-wrap,
  .nz-content-box .bord_active .box-icon-wrap {
    position: relative;
    top: auto;
    left: auto;
    margin: 0 auto 20px auto;
  }

  .nz-content-box .back_active .box-data,
  .nz-content-box .bord_active .box-data {
    padding-left: 0px;
    text-align: center;
  }

  .vc_hidden-sm {
    display: none;
  }
}

@media only screen and (max-width: 1023px) {
  .nz-slider-banner .nz-banner:not(:last-child) {
    border-bottom: none !important;
  }

  .nz-tagline .container > .tagline-title {
    padding-right: 0 !important;
  }
  .nz-tagline .container {
    padding-left: 0 !important;
    text-align: center !important;
  }
  .nz-tagline img {
    display: none;
  }

  .standard .post-date-custom,
  .standard .post-format-status {
    display: none;
  }

  .single-projects .flex-direction-nav {
    right: 20px !important;
  }

  .footer-menu ul li {
    padding: 3px 0;
  }
}

/*MIN TABLET LANDSCAPE
/*-------------*/

@media only screen and (min-width: 1024px) {
  .container,
  .full-width-true .nz-tabs.full-true .tab-content,
  .ninzio-navigation,
  .wpml-ls-statics-post_translations {
    max-width: 960px;
    width: 960px;
  }

  .main-content:before {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 1px;
    top: 0;
    left: 30px;
    background-color: #eaeaea;
  }

  .main-content.left:before {
    right: 30px;
    left: auto;
  }

  .main-content,
  .sidebar {
    float: left;
    position: relative;
  }

  .main-content {
    width: 75%;
  }
  .sidebar {
    width: 25%;
  }

  .woocommerce .loop .main-content {
    width: calc(100% - 240px);
  }
  .woocommerce .loop .sidebar {
    width: 240px;
  }

  .main-content.right {
    padding-left: 60px;
  }
  .main-content.left {
    padding-right: 60px;
  }

  .single-project-main-detailes {
    display: table;
  }

  .single-project-layout-whole.single-project-main-detailes {
    display: block;
  }

  .rich-header.version1 .rh-content .nz-breadcrumbs {
    float: right;
  }

  .single-projects .main-content {
    width: 70%;
    padding-right: 0px !important;
  }
  .single-projects .sidebar {
    width: 30%;
    padding-left: 60px !important;
  }

  .slick-prev {
    left: 110px;
  }
  .slick-next {
    right: 110px;
  }

  .col8 .nz-tabs.vertical .tabset,
  .col9 .nz-tabs.vertical .tabset,
  .col10 .nz-tabs.vertical .tabset,
  .col11 .nz-tabs.vertical .tabset,
  .col12 .nz-tabs.vertical .tabset {
    width: 25%;
  }

  .col8 .nz-tabs.vertical .tabs-container,
  .col9 .nz-tabs.vertical .tabs-container,
  .col10 .nz-tabs.vertical .tabs-container,
  .col11 .nz-tabs.vertical .tabs-container,
  .col12 .nz-tabs.vertical .tabs-container {
    width: 75%;
  }

  .one-page-bullets {
    display: block;
  }

  .nz-video-poster {
    display: none;
  }

  .loop .blog-post *[data-grid="ninzio_01"] {
    width: 100%;
  }

  .medium .loop .blog-post *[data-grid="ninzio_01"] {
    width: 33.3%;
  }

  .small .loop .blog-post *[data-grid="ninzio_01"] {
    width: 25%;
  }

  .large .loop .blog-post *[data-grid="ninzio_01"] {
    width: 50%;
  }

  .list .loop .blog-post .post .post-title {
    font-size: 22px;
    line-height: 42px;
  }

  .list .loop .main-content .blog-post .post .post-title {
    font-size: 18px;
    line-height: 28px;
  }

  .small .loop .main-content .blog-post *[data-grid="ninzio_01"],
  .medium .loop .main-content .blog-post *[data-grid="ninzio_01"],
  .large .loop .main-content .blog-post *[data-grid="ninzio_01"],
  .list .loop .main-content .blog-post *[data-grid="ninzio_01"] {
    width: 50% !important;
    float: left;
  }

  .list .loop .blog-post *[data-grid="ninzio_01"] > .post-body {
    width: 600px;
  }

  .list .loop .main-content .blog-post *[data-grid="ninzio_01"] > .nz-thumbnail,
  .list .loop .main-content .blog-post *[data-grid="ninzio_01"] > .post-body,
  .list
    .loop
    .main-content
    .blog-post
    *[data-grid="ninzio_01"]
    > .post-gallery {
    float: none;
    display: block;
    width: auto;
    min-height: 0;
  }

  .list .loop .main-content .blog-post *[data-grid="ninzio_01"] {
    float: left;
    width: 50% !important;
  }

  .list .loop .blog-post *[data-grid="ninzio_01"] .post-body-in {
    padding: 40px 50px;
  }

  .list .loop .main-content .blog-post *[data-grid="ninzio_01"] .post-body {
    padding: 40px 30px;
  }

  .list .loop .main-content .blog-post *[data-grid="ninzio_01"] .post-body-in {
    position: relative;
    top: 0;
    transform: none;
    padding: 0px;
  }

  .nz-content-box-2[data-columns="4"] > .nz-box {
    width: 25%;
  }

  .woocommerce .small .loop .products .product {
    width: 25%;
  }

  .woocommerce .small .loop .main-content .products .product {
    width: 50%;
  }

  .loop .small-image-nogap .projects-post .projects,
  .loop .small-image .projects-post .projects,
  .loop .small-standard .projects-post .projects,
  .nz-recent-projects.small-image-nogap .projects,
  .nz-recent-projects.small-image .projects,
  .nz-recent-projects.small-standard .projects,
  .nz-related-projects.column-4 .projects {
    width: 25%;
  }

  .loop .medium-image-nogap .projects-post .projects,
  .loop .medium-image .projects-post .projects,
  .loop .medium-standard .projects-post .projects,
  .nz-recent-projects.medium-image-nogap .projects,
  .nz-recent-projects.medium-image .projects,
  .nz-recent-projects.medium-standard .projects,
  .nz-related-projects.column-3 .projects {
    width: 33.3333333%;
  }

  .loop .large-image .projects,
  .loop .large-standard .projects,
  .nz-recent-projects.large-image .projects,
  .nz-recent-projects.large-standard .projects {
    width: 50%;
  }

  .nz-content-box[data-columns="4"] > .nz-box {
    width: 25%;
  }

  .nz-timer ul > li {
    width: 25%;
  }

  .nz-persons[data-columns="4"] > .person,
  .nz-pricing-table[data-columns="4"] > .column,
  .nz-content-box-2[data-columns="4"] > .nz-box,
  .nz-counter[data-columns="4"] > .nz-count {
    width: 25%;
  }

  .nz-counter[data-columns="4"] .nz-count .count-border {
    opacity: 1 !important;
  }

  .nz-content-box.version2[data-columns="4"] > .nz-box:nth-child(2n + 2):after {
    display: block;
  }

  .nz-content-box.version2[data-columns="4"] > .nz-box:nth-child(4n + 4):after {
    display: none;
  }

  .nz-related-projects.column-4 .projects {
    width: 25% !important;
  }

  .nz-related-projects.column-3 .projects {
    width: 33.33333333333333% !important;
  }

  .nz-content-box-2[data-columns="4"] > .nz-box:nth-child(2) .box-data,
  .nz-content-box-2[data-columns="4"]
    > .nz-box:nth-child(2)
    .nz-box-image:before,
  .nz-content-box-2[data-columns="4"] > .nz-box:nth-child(3) .box-data,
  .nz-content-box-2[data-columns="4"]
    > .nz-box:nth-child(3)
    .nz-box-image:before,
  .nz-content-box-2[data-columns="4"] > .nz-box:nth-child(4) .box-data,
  .nz-content-box-2[data-columns="4"]
    > .nz-box:nth-child(4)
    .nz-box-image:before {
    border-left: none;
  }

  .nz-counter.nz-counter[data-columns="4"] > .nz-count:nth-child(2):after {
    display: block;
  }

  .single-product-summary {
    text-align: left;
    z-index: 10;
  }

  .nz-tagline .container > .tagline-title,
  .nz-tagline .container > .button,
  .nz-tagline .container > .tagline-icon {
    vertical-align: middle;
    display: inline-block;
    margin: 0 !important;
  }

  .nz-tagline .container > .tagline-icon {
    margin-right: 30px !important;
    margin-top: -7px !important;
  }

  .footer-widget-area {
    display: flex;
    width: 100%;
  }

  .footer-widget-area > .widget,
  .footer-widget-area > .yawp_wim_widget {
    vertical-align: top;
    width: 25%;
    float: none;
  }

  .footer-columns-3 .footer-widget-area > .widget,
  .footer-columns-3 .footer-widget-area > .yawp_wim_widget {
    display: table-cell;
    vertical-align: top;
    width: 33.33333333333333%;
    float: none;
  }

  .footer-menu ul li a:after {
    bottom: 5px;
  }

  .footer-menu ul li a:hover:after {
    opacity: 1 !important;
    width: 100% !important;
  }

  .nz-recent-products[data-columns="1"] .product {
    width: 100%;
    float: none;
  }

  .product-category-body {
    display: table;
    text-align: left;
  }

  .product-category-body > * {
    display: table-cell;
    vertical-align: middle;
  }

  .product-category-body img {
    max-width: none;
    margin-bottom: 0;
  }

  .main-content .product-category-body img {
    max-width: 250px;
  }

  .product-category-body .product-category-content {
    padding-left: 25px;
  }

  table.variations {
    width: 70%;
  }

  .main-content table.variations {
    width: 100%;
  }

  .standard.sidebar-false > .loop > .container {
    width: 960px;
    max-width: 960px;
    padding-left: 90px;
  }

  .standard > .loop > .container {
    padding-left: 80px;
  }

  .woocommerce .shop-layout-wrap.small .products .product {
    width: 25% !important;
  }

  .nz-recent-products[data-columns="4"] .products .product {
    width: 25% !important;
    float: left;
  }

  .main-content .nz-recent-products[data-columns="4"] .products .product {
    width: 50% !important;
  }

  .null-instagram-feed ul li,
  .widget_recent_projects .recent-projects > .post {
    width: 14.28571428571429%;
  }

  .sidebar .null-instagram-feed ul li,
  .sidebar .widget_recent_projects .recent-projects > .post,
  .footer-widget-area .null-instagram-feed ul li,
  .footer-widget-area .widget_recent_projects .recent-projects > .post {
    width: 33.3333333%;
  }

  .sidebar .widget_calendar caption,
  .sidebar .widget_calendar th,
  .sidebar .widget_calendar td,
  .sidebar .widget_calendar td#prev,
  .sidebar .widget_calendar td#next,
  .sidebar .widget_calendar td#prev span,
  .sidebar .widget_calendar td#next span {
    height: 34px;
    width: 34px;
    line-height: 34px;
  }

  .footer-widget-area .widget_calendar caption,
  .footer-widget-area .widget_calendar th,
  .footer-widget-area .widget_calendar td,
  .footer-widget-area .widget_calendar td#prev,
  .footer-widget-area .widget_calendar td#next,
  .footer-widget-area .widget_calendar td#prev span,
  .footer-widget-area .widget_calendar td#next span {
    height: 27px;
    width: 27px;
    line-height: 27px;
  }

  .footer-columns-3 .footer-widget-area .widget_calendar caption,
  .footer-columns-3 .footer-widget-area .widget_calendar th,
  .footer-columns-3 .footer-widget-area .widget_calendar td,
  .footer-columns-3 .footer-widget-area .widget_calendar td#prev,
  .footer-columns-3 .footer-widget-area .widget_calendar td#next,
  .footer-columns-3 .footer-widget-area .widget_calendar td#prev span,
  .footer-columns-3 .footer-widget-area .widget_calendar td#next span {
    height: 41px;
    width: 41px;
    line-height: 41px;
  }

  .mob-header .widget_calendar caption,
  .mob-header .widget_calendar th,
  .mob-header .widget_calendar td,
  .mob-header .widget_calendar td#prev,
  .mob-header .widget_calendar td#next,
  .mob-header .widget_calendar td#prev span,
  .mob-header .widget_calendar td#next span {
    height: 137px;
    width: 137px;
    line-height: 137px;
  }

  .sidebar .photos_from_flickr .flickr_badge_image,
  .footer-widget-area .photos_from_flickr .flickr_badge_image {
    width: 61px;
  }

  .woocommerce-MyAccount-navigation,
  .woocommerce-MyAccount-content {
    float: left;
    width: 20%;
  }

  .woocommerce-MyAccount-content {
    width: 80%;
    padding-left: 60px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1099px) {
  .vc_hidden-md {
    display: none;
  }
}

/*DESKTOP
/*-------------*/

@media only screen and (max-width: 1099px) {
  .ninzio-filter {
    text-align: left;
    margin: 0 auto 70px auto;
    position: relative;
    padding-top: 1px;
    font-weight: 600;
    max-width: 480px;
  }

  .ninzio-filter .filter-container {
    display: none;
  }

  .filter-toggle {
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 11px;
    right: 25px;
    cursor: pointer;

    -webkit-transition: all 300ms;
    transition: all 300ms;
    z-index: 25;
  }

  .ninzio-filter .filter {
    cursor: pointer;
    display: block;
    padding: 10px 25px;
    line-height: 22px;
    margin-top: -1px;
    width: 100%;
    position: relative;
    z-index: 3;
    color: #777;
    box-shadow: inset 0 0 0 1px #e0e0e0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    background-color: #f7f7f7;
  }

  .ninzio-filter .filter.active,
  .ninzio-filter .filter:hover {
    color: #ffffff !important;
  }

  .ninzio-filter .filter:last-child {
    margin-right: 0;
  }

  .mob-menu li .label {
    display: none;
  }

  .owl-controls .owl-buttons div {
    left: 0px;
  }

  .owl-controls .owl-buttons .owl-next {
    right: 0px;
    left: auto;
  }
}

@media only screen and (min-width: 1100px) {
  .vc_hidden-lg {
    display: none;
  }

  .container,
  .full-width-true .nz-tabs.full-true .tab-content,
  .ninzio-navigation,
  .wpml-ls-statics-post_translations {
    max-width: 1170px;
    width: 1170px;
  }

  .nz-related-projects > .container {
    max-width: 1200px;
    width: 1200px;
  }

  .version1.full-width-true .container,
  .version2.full-width-true .container,
  .version3.full-width-true .container {
    width: 92%;
    max-width: 92%;
  }

  .mob-header {
    display: none;
  }
  .desk {
    display: block;
  }

  .desk.stuck-false,
  .desk.stuck-boxed-false,
  .desk.stuck-true.fixed-true.active,
  .desk.stuck-boxed-true.fixed-true.active,
  .version3.desk {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11);
  }

  .version4.desk {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11) !important;
  }

  .header-content > .container {
    position: relative;
    margin: 0 auto;
  }

  .header-top {
    position: relative;
  }

  .header-top .slogan {
    line-height: 36px;
  }

  .header-top .top-button {
    padding: 3px 25px;
    border-radius: 0px;
    font-size: 12px;
    line-height: 22px;
    text-transform: uppercase;
    font-weight: 600;
    outline: none;
    border: none;
    position: relative;
    display: inline-block;
    margin: 6px 0 0 25px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    z-index: 1;
    text-align: center;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
  }

  .header-top .header-top-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .header-top .header-top-menu > ul > li {
    margin: 0;
    padding: 0 15px;
    float: left;
    line-height: 40px;
    height: 40px;
    font-size: 12px;
    position: relative;
  }

  .header-top .header-top-menu > ul > li > ul > li {
    padding: 0;
    font-size: 12px;
  }

  .header-top .header-top-menu > ul > li:not(:last-child):after {
    content: "|";
    height: inherit;
    width: 16px;
    display: block;
    position: absolute;
    top: 0;
    right: -7px;
    text-align: center;
  }

  .header-top .header-top-menu ul li a {
    display: inline-block;
    text-align: center;
  }

  .header-top .header-top-menu ul li ul li a {
    display: block;
    text-align: left;
    line-height: 24px;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
  }

  .header-top .header-top-menu ul li a .txt,
  .header-top .header-top-menu ul li a {
    position: relative;
  }

  .header-top .header-top-menu ul ul li a {
    display: block;
    padding: 3px 20px;
  }

  .header-top .header-top-menu ul li .mi {
    position: relative;
    bottom: -1px;
  }

  .header-top .header-top-menu ul li ul {
    position: absolute;
    top: 40px;
    left: 0px;
    max-width: 150px;
    width: 150px;
    padding: 5px 0;
    list-style: none;
    margin-left: 0;
    z-index: -1;
    display: none;
  }

  .no-js .header-top .header-top-menu > ul > li:hover > .sub-menu {
    display: block;
  }

  .header-top .header-top-menu ul li img {
    vertical-align: middle;
    margin-right: 8px;
    position: relative;
    top: -2px;
  }

  /*Z INDEX*/
  .desk {
    z-index: 80;
    position: relative;
  }
  .header-top {
    z-index: 85;
    position: relative;
  }

  .header-body {
    position: relative;
  }

  .header-body > .container {
    height: inherit;
  }

  .desk {
    position: relative;
    top: 0;
    left: 0;
    z-index: 80;
    width: 100%;
  }

  .desk.stuck-true,
  .desk.stuck-boxed-true {
    position: absolute;
    box-shadow: none;
  }

  .desk.stuck-boxed-true.slider-active {
    top: 40px;
    max-width: 1170px;
    width: 1170px;
    left: 50%;
    margin-left: -585px;
  }

  .desk.stuck-boxed-true.slider-active .container {
    max-width: 100%;
    width: 100%;
  }

  .desk.stuck-boxed-true:not(.active) .header-top,
  .desk.stuck-boxed-true:not(.active) .header-body {
    padding-left: 30px;
    padding-right: 30px;
  }

  .desk.stuck-boxed-true.slider-active.fixed-true:not(.active) {
    top: 40px !important;
    left: 50% !important;
  }

  .desk.stuck-boxed-true.fixed-true.active {
    top: 0 !important;
    left: 0 !important;
    margin-left: 0 !important;
    max-width: 100% !important;
    width: 100% !important;
  }

  .desk.stuck-boxed-true.slider-active.fixed-true.active .container {
    max-width: 1170px;
    width: 1770px;
  }

  .desk .logo,
  .desk .logo-title {
    position: relative;
    padding: 0 25px 0 0;
    margin: 0 auto;
    display: block;
    float: left;
  }

  .desk .logo-title {
    font-size: 28px;
  }

  .normal-logo,
  .fixed-logo {
    -webkit-transition: opacity 300ms linear;
    -ms-transition: opacity 300ms linear;
    transition: opacity 300ms linear;
  }

  .fixed-logo {
    position: absolute;
    top: 50%;
    left: 0;
  }

  .fixed-true.active .normal-logo,
  .fixed-logo {
    opacity: 0 !important;
  }

  .fixed-true.active .fixed-logo {
    opacity: 1 !important;
  }

  .desk-menu {
    float: right;
  }

  .desk-menu > ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .desk-menu > ul > li {
    padding: 0;
    position: relative;
    float: left;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    height: 90px;
    line-height: 90px;
  }

  .desk-menu > ul > li:first-child {
    margin-left: 0 !important;
  }

  .desk-menu > ul > li > a {
    display: block;
    z-index: 2;
    position: relative;
    padding: 0;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }

  .desk-menu ul.submenu-languages {
    min-width: 0px;
  }

  .desk-menu > ul > li.menu-item-language > a:after {
    display: none;
  }

  .desk-menu > ul > li.menu-item-language > a > .txt {
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
    padding: 7px 15px;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }

  .desk-menu > ul > li.menu-item-language:hover > a > .txt {
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.7);
  }

  .desk-menu > ul > li.menu-item-language img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }

  .desk-menu .sub-menu li.menu-item-language > a:before {
    margin-top: -8px;
  }

  .desk-menu > ul > li > a > .txt {
    position: relative;
  }

  .desk-menu > ul > li > a > .txt .label {
    position: absolute;
    bottom: 145%;
    left: 50%;
    line-height: 20px;
    font-size: 12px;
    height: 20px;
    padding: 0 10px;
    text-transform: none;
    color: #fff;
    border-radius: 3px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .desk-menu .label2 {
    display: none;
  }

  .desk-menu > ul > li > a > .txt .label > .label2 {
    display: block;
    position: absolute;
    bottom: -3px;
    left: 50%;
    margin-left: -4px;
    width: 8px;
    height: 8px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .desk-menu .sub-menu .label {
    margin-left: 10px;
    display: inline-block;
    padding: 6px 10px 6px 10px;
    border-radius: 0;
    color: #ffffff;
    font-size: 9px;
    line-height: 9px;
    text-align: center;
    position: relative;
    top: -1px;
    left: 0px !important;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    text-transform: uppercase;
  }

  .desk-menu .mi {
    padding-right: 10px;
  }
  .desk-di-false .desk-menu .di {
    display: none;
  }
  .desk-di-false .sub-menu .di,
  .desk-di-true .sub-menu .di {
    display: block;
  }

  .desk-menu ul > li > a > .di {
    display: none;
  }

  .desk-menu ul ul > li > a > .di {
    display: block;
    position: absolute;
    top: 50%;
    right: 15px;
    font-size: 14px;
    margin-top: -6px;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .desk-menu ul ul > li:hover > a > .di {
    -webkit-transform: translateX(5px);
    -ms-transform: translateX(5px);
    transform: translateX(5px);
  }

  .desk-menu .sub-menu {
    position: absolute;
    left: 0px;
    min-width: 230px;
    max-width: 230px;
    padding-top: 15px;
    padding-bottom: 15px;
    list-style: none;
    margin-left: 0;
    z-index: -1;
    display: none;
  }

  .desk-menu .sub-menu .sub-menu {
    left: 230px !important;
    top: -15px !important;
    margin-top: 0px;
    min-width: 200px;
    max-width: 200px;
    z-index: -1;
    display: none;
  }

  .no-js .desk-menu li:hover > ul {
    display: block;
  }

  .desk-menu .sub-menu li {
    position: relative;
    padding: 0px;
  }
  .desk-menu .sub-menu li > a {
    padding: 5px 25px;
    display: block;
    position: relative;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .desk-menu [data-mm="true"] .sub-menu .di {
    display: none !important;
  }

  .desk-menu > ul li:not([data-mm="true"]) ul > li:first-child {
    position: relative;
  }
  .desk-menu > ul > [data-mm="true"] {
    position: static !important;
  }

  .desk-menu > ul > [data-mm="true"] > ul {
    width: 1170px;
    max-width: 1170px;
    height: auto;
    z-index: -1;
    left: 50% !important;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 30px 30px 30px 30px;
    background-repeat: no-repeat;
    background-position: 0 0;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    background-size: cover !important;
  }

  .desk-menu > ul > [data-mm="true"] > ul:after {
    content: "";
    display: table;
    clear: both;
  }

  .desk-menu > ul > [data-mm="true"] > ul ul {
    display: block !important;
    opacity: 1 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    position: static !important;
    top: auto !important;
    left: auto !important;
    height: auto !important;
    min-width: 100% !important;
    max-width: 100% !important;
    box-shadow: none !important;
    padding: 0;
    background-color: transparent !important;
    border-top: none !important;
    margin-left: 0 !important;
  }

  .desk-menu > ul > [data-mm="true"] > ul ul li {
    padding: 0;
  }

  .desk-menu > ul > [data-mm="true"] > ul ul li a {
    padding: 7px 0px !important;
    border-bottom: none;
  }

  .desk-menu > ul > [data-mm="true"] > .sub-menu > li {
    position: relative;
    padding: 15px 20px 5px 20px;
    display: table-cell;
    vertical-align: top;
  }

  .desk-menu > ul > [data-mm="true"] > .sub-menu > li:not(:last-child):after {
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    content: "";
    display: block;
  }

  .desk-menu [data-mm="true"] .sub-menu li > a,
  .desk-menu [data-mm="true"] .sub-menu li:hover a {
    background-color: transparent;
  }

  .desk .desk-menu [data-mm="true"] > .sub-menu > li > a,
  .desk .desk-menu [data-mm="true"] > .sub-menu > li > a:hover {
    background-color: transparent !important;
    border-bottom: none;
  }

  .desk-menu [data-mm="true"] > .sub-menu > li > a {
    position: relative;
    padding: 0px 0 10px 0;
  }

  .desk-menu > ul > [data-mm="true"] > ul ul {
    padding-top: 0px;
  }

  .desk-menu > ul > [data-mm="true"][data-mmc="5"] > ul > li {
    width: 20%;
  }
  .desk-menu > ul > [data-mm="true"][data-mmc="4"] > ul > li {
    width: 25%;
  }
  .desk-menu > ul > [data-mm="true"][data-mmc="3"] > ul > li {
    width: 33.3333333%;
  }
  .desk-menu > ul > [data-mm="true"][data-mmc="2"] > ul > li {
    width: 50%;
  }

  .search-true.cart-false .search-toggle-wrap,
  .cart-true .desk-cart-wrap {
    position: relative;
  }

  .sl-true .header-social-links,
  .search-true.cart-false .search-toggle-wrap,
  .cart-true .desk-cart-wrap,
  .search-false.cart-false .site-sidebar-toggle {
    margin-left: 25px;
  }

  .search-true.cart-true .search-toggle-wrap,
  .search-true.ls-true .search-toggle-wrap,
  .cart-true .desk-cart-wrap,
  .site-sidebar-toggle {
    margin-left: 5px;
  }

  .desk .search {
    right: -18px;
    position: absolute;
    width: 320px;
    height: 80px;
    padding: 18px;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    z-index: -1;
    display: none;
    background-color: #292929;
  }

  .desk.version2 .search {
    right: auto;
  }

  .sidebar-toggle-true .search {
    right: -5px;
  }

  .desk .search.animated {
    display: block;
  }

  .search-toggle-wrap,
  .site-sidebar-toggle {
    height: 40px;
    width: 40px;
    float: right;
    text-align: center;
    position: relative;
    z-index: 15;
    cursor: pointer;
  }

  .desk .search form,
  .desk .search fieldset {
    margin: 0;
    position: relative;
  }

  .desk .search input {
    margin: 0;
  }

  .desk .search input[type="text"] {
    height: 40px;
    width: 100%;
    line-height: 40px;
    background-color: #ffffff;
    margin: 0;
    padding: 0px 40px 0px 20px;
    border: none !important;
    position: relative;
    left: 0;
    top: 0;
  }

  .desk .search input[type="submit"] {
    width: 40px;
    height: 40px;
    text-indent: -9000em;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    outline: none;
    padding: 0;

    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: none;
    border-radius: 0px;
  }

  .desk-cart-wrap {
    float: right;
    position: relative;
    height: 100%;
    width: 40px;
  }

  .desk-cart-toggle,
  .search-toggle {
    width: 40px;
    height: 40px;
    line-height: 36px;
    text-align: center;
    position: relative;
    z-index: 15;
    background-repeat: no-repeat;
    background-position: 46% 48%;
    outline: none;
  }

  .desk-cart-toggle:after,
  .search-toggle:after,
  .desk-cart-toggle:before,
  .search-toggle:before {
    background-position: 25% 50%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    content: "";
    display: block;
    opacity: 0;
    left: 0;
    top: 0;
    position: absolute;
  }

  .search-toggle:before,
  .search-toggle:after,
  .site-sidebar-toggle {
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .site-sidebar-toggle {
    background-position: 50% 57%;
  }

  .desk-cart-toggle:before {
    background-repeat: no-repeat;
    background-position: 17% 48%;
  }

  .desk-cart-toggle:before,
  .search-toggle:before {
    opacity: 1;
  }

  .desk-cart-toggle.animated:before,
  .search-toggle.animated:before {
    opacity: 0;
  }

  .desk-cart-toggle.animated:after,
  .search-toggle.animated:after {
    opacity: 1;
  }

  .desk-cart-toggle a {
    position: relative;
    z-index: 5;
    width: 40px;
    height: 40px;
    display: block;
    outline: none;
    position: relative;
  }

  .desk-cart-toggle span {
    color: #fff;
    position: absolute;
    line-height: 24px;
    font-size: 14px;
    height: 20px;
    width: 20px;
    top: 50%;
    left: 50%;
    text-transform: none;
    margin-left: 0px;
    margin-top: -12px;
  }

  .woo-cart {
    position: absolute;
    width: 320px;
    height: auto;
    padding: 18px;
    position: absolute;
    right: 0px;
    z-index: -1;
    display: none;
    -webkit-transition: all 300ms ease-in;
    transition: all 300ms ease-in;
  }

  .woo-cart.animated {
    display: block;
  }

  .woo-cart .widget_shopping_cart {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .woo-cart .widget_shopping_cart .widgettitle {
    display: none;
  }
  .woo-cart .widget_shopping_cart {
    margin-bottom: 0px;
    border-bottom: none !important;
  }
  .woo-cart .widget_shopping_cart .cart_list,
  .woo-cart .widget_shopping_cart .cart_list li {
    margin: 0;
    padding: 0;
  }

  .woo-cart .widget_shopping_cart .cart_list li {
    margin-bottom: 15px;
    padding-bottom: 15px;
    background-color: transparent;
    position: relative;
  }

  .woo-cart .widget_shopping_cart .cart_list li:after {
    content: "";
    display: table;
    clear: both;
  }

  .woo-cart .widget_shopping_cart .cart_list li .remove {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    position: absolute;
    top: 0px !important;
    right: 0;
    display: block;
    color: #fff !important;
  }

  .woo-cart .widget_shopping_cart .cart_list li img {
    display: block;
    float: left;
    margin-right: 15px;
    padding: 5px;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  .woo-cart .widget_shopping_cart .cart_list {
    list-style: none;
    background-color: transparent;
  }
  .woo-cart .widget_shopping_cart .cart_list > li.empty {
    padding: 10px !important;
    border-bottom: none !important;
    margin-bottom: 0 !important;
    font-size: 14px;
    line-height: 24px;
  }

  .woo-cart .widget_shopping_cart p.buttons > a {
    width: 137px;
    display: block;
    float: left;
    padding: 8px 20px;
    font-size: 12px;
    line-height: 22px;
  }

  .woo-cart .woocommerce .quantity {
    width: 100%;
    display: block;
    line-height: 24px;
  }

  .header-top > .container {
    height: 40px;
  }

  .header-top > .container:empty {
    height: 0;
  }

  .header-top .slogan {
    float: left;
  }

  .header-top .header-top-menu,
  .header-top .top-button,
  .header-top .social-links {
    float: right;
  }

  .header-top .header-top-social-links a {
    background-color: transparent !important;
    box-shadow: none !important;
    width: 30px;
    height: 40px;
    line-height: 40px;
    border: none;
    margin: 0;
    border-radius: 0px;
  }

  .header-top .social-text {
    display: inline-block;
    text-transform: uppercase;
    line-height: 40px;
    vertical-align: top;
    padding-left: 30px;
  }

  .desk-menu > ul > li > a {
    height: 30px;
    line-height: 30px;
  }

  .subeffect-ghost .header-top .header-top-menu ul li ul,
  .subeffect-ghost .header-top .header-top-menu > ul > li:hover > .sub-menu,
  .subeffect-ghost .desk-menu > ul > li > .sub-menu,
  .subeffect-ghost .desk-menu > ul > li:hover > .sub-menu,
  .subeffect-ghost .sub-menu .sub-menu {
    display: none;
    opacity: 0;
    margin-top: -20px;
  }

  .subeffect-slide .header-top .header-top-menu ul li ul,
  .subeffect-slide .header-top .header-top-menu > ul > li:hover > .sub-menu,
  .subeffect-slide .desk-menu > ul > li > .sub-menu,
  .subeffect-slide .desk-menu > ul > li:hover > .sub-menu,
  .subeffect-slide .sub-menu .sub-menu,
  .subeffect-fade .header-top .header-top-menu ul li ul,
  .subeffect-fade .header-top .header-top-menu > ul > li:hover > .sub-menu,
  .subeffect-fade .desk-menu > ul > li > .sub-menu,
  .subeffect-fade .desk-menu > ul > li:hover > .sub-menu,
  .subeffect-fade .sub-menu .sub-menu {
    display: none;
    margin-top: 0px;
    opacity: 0;
  }

  .subeffect-slide .header-top .header-top-menu ul li ul,
  .subeffect-slide .header-top .header-top-menu > ul > li:hover > .sub-menu,
  .subeffect-slide .desk-menu > ul > li > .sub-menu,
  .subeffect-slide .desk-menu > ul > li:hover > .sub-menu,
  .subeffect-slide .sub-menu .sub-menu {
    opacity: 1;
  }

  .subeffect-move .header-top .header-top-menu ul li ul,
  .subeffect-move .header-top .header-top-menu > ul > li:hover > .sub-menu,
  .subeffect-move .desk-menu > ul > li > .sub-menu,
  .subeffect-move .desk-menu > ul > li:hover > .sub-menu,
  .subeffect-move .sub-menu .sub-menu {
    display: none;
    margin-left: -40px;
    opacity: 0;
  }

  /*EFFECTS*/
  .effect-underline .desk-menu > ul > li > a:after,
  .effect-overline .desk-menu > ul > li > a:after {
    content: "";
    position: absolute;
    width: 0;
    height: 3px;
    bottom: -30px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity: 0;
    -webkit-transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .effect-underline .desk-menu > ul > li:hover > a:after,
  .effect-underline .desk-menu > ul > li.one-page-active > a:after,
  .effect-underline .desk-menu > ul > li.current-menu-item > a:after,
  .effect-underline .desk-menu > ul > li.current-menu-parent > a:after,
  .effect-underline .desk-menu > ul > li.current-menu-ancestor > a:after,
  .effect-overline .desk-menu > ul > li:hover > a:after,
  .effect-overline .desk-menu > ul > li.one-page-active > a:after,
  .effect-overline .desk-menu > ul > li.current-menu-item > a:after,
  .effect-overline .desk-menu > ul > li.current-menu-parent > a:after,
  .effect-overline .desk-menu > ul > li.current-menu-ancestor > a:after {
    opacity: 1;
    width: 100%;
  }

  .effect-overline .desk-menu > ul > li:hover > a:after,
  .effect-overline .desk-menu > ul > li.one-page-active > a:after,
  .effect-overline .desk-menu > ul > li.current-menu-item > a:after,
  .effect-overline .desk-menu > ul > li.current-menu-parent > a:after,
  .effect-overline .desk-menu > ul > li.current-menu-ancestor > a:after {
    width: 100%;
  }

  .one-page-active.one-page-top.effect-underline
    .desk-menu
    > ul
    > li.current-menu-item
    > a:after,
  .one-page-active.one-page-top.effect-underline
    .desk-menu
    > ul
    > li.current-menu-parent
    > a:after,
  .one-page-active.one-page-top.effect-underline
    .desk-menu
    > ul
    > li.current-menu-ancestor
    > a:after,
  .one-page-active.one-page-top.effect-overline
    .desk-menu
    > ul
    > li.current-menu-item
    > a:after,
  .one-page-active.one-page-top.effect-overline
    .desk-menu
    > ul
    > li.current-menu-parent
    > a:after,
  .one-page-active.one-page-top.effect-overline
    .desk-menu
    > ul
    > li.current-menu-ancestor
    > a:after {
    opacity: 0;
    width: 0;
  }

  .one-page-active.one-page-top.effect-underline
    .desk-menu
    > ul
    > li:hover
    > a:after,
  .one-page-active.one-page-top.effect-underline
    .desk-menu
    > ul
    > li.one-page-active
    > a:after,
  .one-page-active.one-page-top.effect-overline
    .desk-menu
    > ul
    > li:hover
    > a:after,
  .one-page-active.one-page-top.effect-overline
    .desk-menu
    > ul
    > li.one-page-active
    > a:after {
    opacity: 1 !important;
    width: 100% !important;
  }

  .one-page-active.one-page-top.effect-overline
    .desk-menu
    > ul
    > li:hover
    > a:after,
  .one-page-active.one-page-top.effect-overline
    .desk-menu
    > ul
    > li.one-page-active
    > a:after {
    width: 100% !important;
  }

  .effect-outline .desk-menu > ul > li > a,
  .effect-fill .desk-menu > ul > li > a {
    padding-left: 10px;
    padding-right: 10px;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }

  .one-page-active.one-page-top.effect-fill
    .desk-menu
    > ul
    > li.current-menu-item
    > a,
  .one-page-active.one-page-top.effect-fill
    .desk-menu
    > ul
    > li.current-menu-parent
    > a,
  .one-page-active.one-page-top.effect-fill
    .desk-menu
    > ul
    > li.current-menu-ancestor
    > a {
    background-color: transparent !important;
  }

  .one-page-active.one-page-top.effect-outline
    .desk-menu
    > ul
    > li.current-menu-item
    > a,
  .one-page-active.one-page-top.effect-outline
    .desk-menu
    > ul
    > li.current-menu-parent
    > a,
  .one-page-active.one-page-top.effect-outline
    .desk-menu
    > ul
    > li.current-menu-ancestor
    > a {
    box-shadow: none !important;
  }

  /*FIXED*/
  .page-content-wrap {
    -webkit-transition: padding-top 300ms linear;
    -ms-transition: padding-top 300ms linear;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .version5.fixed-true.active + .page-content-wrap {
    padding-top: 0px !important;
  }

  .page-content-wrap.fixed-true.header-version3 {
    -webkit-transition: none;
    -ms-transition: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .desk.fixed-true {
    position: fixed;
    top: 0;
    left: 0;
    -webkit-transition: height 300ms linear, transform 300ms linear;
    -ms-transition: height 300ms linear, transform 300ms linear;
    transition: height 300ms linear, transform 300ms linear;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }

  .admin-bar .desk.fixed-true {
    top: 32px;
  }

  .desk.fixed-true .header-body {
    -webkit-transition: height 300ms linear, background-color 300ms linear;
    -ms-transition: height 300ms linear, background-color 300ms linear;
    transition: height 300ms linear, background-color 300ms linear;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .desk.top-true.fixed-true.active {
    -webkit-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    transform: translateY(-40px);
  }

  .desk.fixed-true .logo,
  .desk.fixed-true .logo-title,
  .desk.fixed-true .desk-menu > ul > li {
    -webkit-transition: height 300ms linear, line-height 300ms linear;
    -ms-transition: height 300ms linear, line-height 300ms linear;
    transition: height 300ms linear, line-height 300ms linear;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .desk.fixed-true .search,
  .desk.fixed-true .desk-menu .sub-menu,
  .desk.fixed-true .woo-cart {
    -webkit-transition: top 300ms linear;
    -ms-transition: top 300ms linear;
    transition: top 300ms linear;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .desk.fixed-true .search-toggle-wrap,
  .desk.fixed-true .desk-cart-wrap,
  .desk.fixed-true .desk-cart-wrap > .desk-cart-toggle,
  .desk.fixed-true .site-sidebar-toggle,
  .desk.fixed-true .desk-menu > ul > li > a {
    -webkit-transition: margin-top 300ms linear, background-image 300ms linear,
      box-shadow 300ms linear;
    -ms-transition: margin-top 300ms linear, background-image 300ms linear,
      box-shadow 300ms linear;
    transition: margin-top 300ms linear, background-image 300ms linear,
      box-shadow 300ms linear;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .desk.fixed-true .site-sidebar-toggle {
    -webkit-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
  }

  .desk.fixed-true .search-toggle:before,
  .desk.fixed-true .desk-cart-wrap:before,
  .desk.fixed-true .desk-cart-wrap > .desk-cart-toggle:before {
    -webkit-transition: background-image 300ms linear;
    -ms-transition: background-image 300ms linear;
    transition: background-image 300ms linear;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .desk.fixed-true .desk-menu > ul > li > a {
    -webkit-transition: margin-top 300ms linear, color 300ms linear,
      box-shadow 300ms linear, background-color 300ms linear;
    -ms-transition: margin-top 300ms linear, color 300ms linear,
      box-shadow 300ms linear, background-color 300ms linear;
    transition: margin-top 300ms linear, color 300ms linear,
      box-shadow 300ms linear, background-color 300ms linear;
  }

  .desk.fixed-true .desk-cart-toggle span {
    -webkit-transition: color 300ms linear, background-color 300ms linear;
    -ms-transition: color 300ms linear, background-color 300ms linear;
    transition: color 300ms linear, background-color 300ms linear;
  }

  /*VERSIONS*/
  .version2.desk .left-part {
    float: left;
    width: 415px;
  }
  .version2.desk .right-part {
    float: right;
    width: 415px;
  }
  .version2.desk .logo-part {
    width: 340px;
    padding: 0 25px;
    position: absolute;
    top: 0;
    left: 415px;
  }

  .version2.desk .left-part .desk-menu {
    float: right;
  }
  .version2.desk .right-part .desk-menu {
    float: left;
  }

  .version2.desk .logo-title {
    font-size: 38px;
    text-align: center;
  }

  .version2.desk .logo,
  .version2.desk .logo-title {
    padding: 0;
    float: none;
  }

  .version2.search-true .search-toggle-wrap {
    margin-right: 15px !important;
    margin-left: 0px !important;
  }

  .version2.sidebar-toggle-true .search {
    right: auto;
    left: -15px;
  }

  .version2.cart-true .desk-cart-wrap {
    margin-left: 15px !important;
    margin-right: 0px !important;
    float: left;
  }

  .version2 .site-sidebar-toggle {
    margin-right: 25px !important;
    margin-left: 0px !important;
  }

  .version2 .fixed-logo {
    -webkit-transform: translate(5px, 0px);
    -ms-transform: translate(5px, 0px);
    transform: translate(5px, 0px);
  }

  .version3 .header-social-links {
    float: right;
    position: relative;
    height: 40px;
  }

  .version3.search-false.cart-false.sidebar-toggle-false .header-social-links {
    margin-left: 50px;
  }

  .version3.fixed-true .header-social-links {
    -webkit-transition: margin-top 300ms linear;
    -ms-transition: margin-top 300ms linear;
    transition: margin-top 300ms linear;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .version3 .header-social-links a {
    background-color: transparent !important;
    box-shadow: none !important;
    width: 36px;
    height: 40px;
    line-height: 40px;
    border-radius: 0px;
    border: none;
    margin: 0;
  }

  .version3 .header-social-links a:hover {
    background-color: transparent !important;
  }

  .revolution-slider-active .version3.fixed-true {
    position: relative;
  }

  .revolution-slider-active .version3.fixed-true.active {
    position: fixed;
    top: 0;
    left: 0;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }

  .desk.version4 .logo,
  .desk.version4 .logo-title {
    position: relative;
    z-index: 90;
    top: -40px;
    padding-right: 10px;
  }

  .desk.version4 .logo:before,
  .desk.version4 .logo-title:before {
    position: absolute;
    top: 0;
    left: -1000%;
    width: 1000%;
    height: 100%;
    display: block;
    content: "";
  }

  .desk.version4.stuck-boxed-true.slider-active:not(.active) .logo:before,
  .desk.version4.stuck-boxed-true.slider-active:not(.active)
    .logo-title:before {
    position: absolute;
    top: 0;
    left: -30px;
    width: 30px;
    height: 40px;
    display: block;
    content: "";
  }

  .desk.version4 .logo:after,
  .desk.version4 .logo-title:after {
    position: absolute;
    top: 0;
    right: -37px;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 40px 0 0 37px;
  }

  .desk.version4 .header-top > .container {
    padding-left: 350px;
  }

  .desk.version5 .desk-menu {
    float: none;
    position: relative;
  }

  .desk.version5 .desk-menu > ul,
  .desk.version5.stuck-boxed-true:not(.active) .desk-menu {
    max-width: 1170px;
    width: 1170px;
    margin: 0 auto;
    position: relative;
  }

  .desk.version5.stuck-boxed-true:not(.active) .desk-menu {
    left: -30px;
  }

  .desk.version5 .desk-menu > ul:after {
    content: "";
    display: table;
    clear: both;
  }

  .desk.version5 .desk-menu > ul > li {
    height: 40px;
    line-height: 40px;
  }

  .desk.version5 .desk-menu > ul > li > a {
    margin-top: 0px;
    height: 40px;
    line-height: 40px;
  }

  .version5.effect-underline .desk-menu > ul > li > a:after {
    bottom: 0px;
  }

  .version5 .desk-menu .sub-menu {
    top: 40px;
  }

  .version5 .cart-info-2 {
    display: block;
  }

  .version5 .cart-info {
    display: none;
  }

  .version5 .desk-cart-wrap {
    width: 170px;
  }

  .version5 .desk-cart-toggle {
    width: 170px;
    height: 40px;
    line-height: 40px;
    text-align: left;
    position: relative;
    z-index: 15;
    background-position: 0% 50%;
  }

  .version5 .desk-cart-toggle a {
    width: 170px;
  }

  .version5 .desk-cart-toggle a:before {
    width: 1px;
    height: 30px;
    position: absolute;
    top: 5px;
    left: 40px;
    content: "";
    display: block;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
  }

  .version5 .desk-cart-toggle:before {
    background-position: 0% 50%;
  }

  .version5 .desk-cart-toggle:after {
    background-position: 4% 50%;
  }

  .version5 .desk-cart-toggle span {
    position: static;
    line-height: 20px;
    font-size: 12px;
    height: auto;
    width: auto;
    top: auto;
    left: auto;
    text-transform: uppercase;
    margin-left: 0px;
    margin-top: 0px;
  }

  .version5 .desk-cart-toggle .cart-info-2 {
    height: 40px;
  }

  .version5 .my-cart {
    display: block;
  }

  .version5 .my-items,
  .version5 .desk-cart-toggle .amount {
    text-transform: lowercase !important;
    font-size: 14px !important;
  }

  .version5 .desk-cart-toggle a {
    padding-left: 60px;
  }

  .version5 .search {
    right: auto;
    position: static;
    width: 320px;
    height: 40px;
    padding: 0px;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    z-index: 1;
    display: block;
    background-color: transparent !important;
    float: right;
    margin-right: 50px;
  }

  .version5 .search input[type="submit"] {
    background-color: transparent !important;
  }

  /*Widgets in header*/
  .desk .widget,
  .desk .yawp_wim_widget {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .desk .menu-item-type-yawp_wim,
  .desk .menu-item-type-yawp_wim a {
    text-transform: none;
  }

  .desk .menu-item-type-yawp_wim a {
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease-out;
  }

  .desk .yawp_wim_title {
    padding: 0px 0 18px 0;
    display: block;
    margin-bottom: 0px;
  }

  .desk textarea,
  .desk select,
  .desk input[type="date"],
  .desk input[type="datetime"],
  .desk input[type="datetime-local"],
  .desk input[type="email"],
  .desk input[type="month"],
  .desk input[type="number"],
  .desk input[type="password"],
  .desk input[type="search"],
  .desk input[type="tel"],
  .desk input[type="text"],
  .desk input[type="time"],
  .desk input[type="url"],
  .desk input[type="week"] {
    background-color: transparent !important;
  }

  .desk .desk-menu > ul > [data-mm="true"] .null-instagram-feed ul li a {
    padding: 0 !important;
  }

  .desk .widget_schedule {
    text-align: left;
  }

  .desk .widget_schedule li {
    padding: 10px 0 !important;
  }

  .desk .widget_nz_recent_entries a.post-title {
    padding: 0 !important;
  }

  .desk .widget_categories ul li a,
  .desk .widget_pages ul li a,
  .desk .widget_archive ul li a,
  .desk .widget_meta ul li a {
    display: inline-block !important;
  }

  .desk .widget_rating_filter a:before,
  .desk .widget_shopping_cart .cart_list > li > a:before,
  .desk .widget_product_categories li a:before,
  .desk .widget_shopping_cart .cart_list > li > a:before,
  .desk .widget_products .product_list_widget > li > a:before,
  .desk .widget_recently_viewed_products .product_list_widget > li > a:before,
  .desk .widget_recent_reviews .product_list_widget > li > a:before,
  .desk .widget_top_rated_products .product_list_widget > li > a:before {
    display: none !important;
  }

  .desk .widget_calendar th,
  .desk .widget_calendar td,
  .desk .widget_calendar td#prev,
  .desk .widget_calendar td#next,
  .desk .widget_calendar td#prev span,
  .desk .widget_calendar td#next span {
    width: 14.28571428571429% !important;
    height: auto !important;
    line-height: inherit !important;
    text-align: center !important;
  }

  .desk .desk-menu > ul > [data-mm="true"][data-mmc="4"] .widget_calendar th,
  .desk .desk-menu > ul > [data-mm="true"][data-mmc="4"] .widget_calendar td,
  .desk
    .desk-menu
    > ul
    > [data-mm="true"][data-mmc="4"]
    .widget_calendar
    td#prev,
  .desk
    .desk-menu
    > ul
    > [data-mm="true"][data-mmc="4"]
    .widget_calendar
    td#next,
  .desk
    .desk-menu
    > ul
    > [data-mm="true"][data-mmc="4"]
    .widget_calendar
    td#prev
    span,
  .desk
    .desk-menu
    > ul
    > [data-mm="true"][data-mmc="4"]
    .widget_calendar
    td#next
    span,
  .desk
    .desk-menu
    > ul
    > [data-mm="true"][data-mmc="4"]
    .widget_calendar
    caption {
    height: 35px !important;
    line-height: 35px !important;
  }

  .desk .desk-menu > ul > [data-mm="true"][data-mmc="3"] .widget_calendar th,
  .desk .desk-menu > ul > [data-mm="true"][data-mmc="3"] .widget_calendar td,
  .desk
    .desk-menu
    > ul
    > [data-mm="true"][data-mmc="3"]
    .widget_calendar
    td#prev,
  .desk
    .desk-menu
    > ul
    > [data-mm="true"][data-mmc="3"]
    .widget_calendar
    td#next,
  .desk
    .desk-menu
    > ul
    > [data-mm="true"][data-mmc="3"]
    .widget_calendar
    td#prev
    span,
  .desk
    .desk-menu
    > ul
    > [data-mm="true"][data-mmc="3"]
    .widget_calendar
    td#next
    span,
  .desk
    .desk-menu
    > ul
    > [data-mm="true"][data-mmc="3"]
    .widget_calendar
    caption {
    height: 50px !important;
    line-height: 50px !important;
  }

  .desk .desk-menu > ul > [data-mm="true"][data-mmc="2"] .widget_calendar th,
  .desk .desk-menu > ul > [data-mm="true"][data-mmc="2"] .widget_calendar td,
  .desk
    .desk-menu
    > ul
    > [data-mm="true"][data-mmc="2"]
    .widget_calendar
    td#prev,
  .desk
    .desk-menu
    > ul
    > [data-mm="true"][data-mmc="2"]
    .widget_calendar
    td#next,
  .desk
    .desk-menu
    > ul
    > [data-mm="true"][data-mmc="2"]
    .widget_calendar
    td#prev
    span,
  .desk
    .desk-menu
    > ul
    > [data-mm="true"][data-mmc="2"]
    .widget_calendar
    td#next
    span,
  .desk
    .desk-menu
    > ul
    > [data-mm="true"][data-mmc="2"]
    .widget_calendar
    caption {
    height: 75px !important;
    line-height: 75px !important;
  }

  .desk .widget_calendar td#prev span,
  .desk .widget_calendar td#next span {
    width: 100% !important;
  }

  .desk .widget_calendar caption {
    width: 72% !important;
    height: auto !important;
    line-height: inherit !important;
  }

  .desk .widget_calendar table,
  .desk .widget_calendar caption {
    background-color: transparent;
  }

  .desk .widget_calendar td#next {
    right: -1px;
    border-right-width: 1px;
    border-right-style: solid;
  }

  .desk .widget_calendar td#prev {
    border-left-width: 1px;
    border-left-style: solid;
    left: 0px;
  }

  .desk .desk-menu > ul > [data-mm="true"] .widget_categories ul li a,
  .desk .desk-menu > ul > [data-mm="true"] .widget_pages ul li a,
  .desk .desk-menu > ul > [data-mm="true"] .widget_archive ul li a,
  .desk .desk-menu > ul > [data-mm="true"] .widget_meta ul li a {
    padding: 0px 0px 0px 15px !important;
  }

  .desk .widget_nav_menu li,
  .desk .widget_product_categories li {
    background-color: transparent !important;
    border-bottom: none !important;
  }

  .desk .widget_nav_menu li .toggle,
  .desk .widget_product_categories li .toggle {
    display: none;
  }

  .desk .widget_nav_menu ul ul,
  .desk .widget_product_categories ul ul {
    padding-left: 25px !important;
  }

  .desk .widget_search input[type="submit"],
  .desk .widget_product_search input[type="submit"],
  .desk .woocommerce-product-search input[type="submit"],
  .desk .widget_search input[type="submit"]:hover,
  .desk .widget_product_search input[type="submit"]:hover,
  .desk .woocommerce-product-search input[type="submit"]:hover,
  .desk .widget_shopping_cart p.buttons > a,
  .desk .widget_shopping_cart p.buttons > a:hover {
    background-color: transparent !important;
  }

  .desk .widget_tag_cloud .tagcloud a,
  .desk .post-tags a,
  .desk .widget_product_tag_cloud .tagcloud a,
  .desk .projects-tags a {
    background-color: transparent !important;
  }

  .desk .widget_shopping_cart .cart_list > li,
  .desk .widget_products .product_list_widget > li,
  .desk .widget_recently_viewed_products .product_list_widget > li,
  .desk .widget_recent_reviews .product_list_widget > li,
  .desk .widget_top_rated_products .product_list_widget > li {
    padding: 10px 0 !important;
  }

  .desk .widget_shopping_cart .cart_list > li > a,
  .desk .widget_products .product_list_widget > li > a,
  .desk .widget_recently_viewed_products .product_list_widget > li > a,
  .desk .widget_recent_reviews .product_list_widget > li > a,
  .desk .widget_top_rated_products .product_list_widget > li > a {
    padding: 0 !important;
  }

  .desk .widget_price_filter .price_slider_wrapper .ui-widget-content {
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  .desk .widget_recent_projects .recent-projects > .post,
  .desk .null-instagram-feed ul li {
    width: 33.3333333% !important;
  }

  .desk .photos_from_flickr .flickr_badge_image {
    width: 60px;
  }

  .desk .widget_price_filter .price_slider_amount .button {
    background-color: transparent !important;
  }

  .desk
    .desk-menu
    > ul
    > [data-mm="true"]
    .widget_product_categories
    li
    a:before,
  .desk .desk-menu > ul > [data-mm="true"] .widget_categories ul li a:before,
  .desk .desk-menu > ul > [data-mm="true"] .widget_pages ul li a:before,
  .desk .desk-menu > ul > [data-mm="true"] .widget_archive ul li a:before,
  .desk .desk-menu > ul > [data-mm="true"] .widget_meta ul li a:before {
    content: "" !important;
    display: block !important;
    height: 4px !important;
    width: 4px;
    left: 0px !important;
    top: 50%;
    margin-top: -2px;
    -webkit-transform: none !important;
    -moz-transform: none !important;
    transform: none !important;
    opacity: 1;
    -webkit-transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  /*MIX*/

  .inactive-true .rich-header .fixed-container {
    margin-top: 0 !important;
    height: 100%;
  }

  .inactive-true .rich-header .parallax-container {
    top: 0px;
  }
  .inactive-true .rich-header .parallax-container {
    height: 100%;
  }

  .sidebar .widget_calendar caption,
  .sidebar .widget_calendar th,
  .sidebar .widget_calendar td,
  .sidebar .widget_calendar td#prev,
  .sidebar .widget_calendar td#next,
  .sidebar .widget_calendar td#prev span,
  .sidebar .widget_calendar td#next span {
    height: 41px;
    width: 41px;
    line-height: 41px;
  }

  .footer-widget-area .widget_calendar caption,
  .footer-widget-area .widget_calendar th,
  .footer-widget-area .widget_calendar td,
  .footer-widget-area .widget_calendar td#prev,
  .footer-widget-area .widget_calendar td#next,
  .footer-widget-area .widget_calendar td#prev span,
  .footer-widget-area .widget_calendar td#next span {
    height: 34px;
    width: 34px;
    line-height: 34px;
  }

  .footer-columns-3 .footer-widget-area .widget_calendar caption,
  .footer-columns-3 .footer-widget-area .widget_calendar th,
  .footer-columns-3 .footer-widget-area .widget_calendar td,
  .footer-columns-3 .footer-widget-area .widget_calendar td#prev,
  .footer-columns-3 .footer-widget-area .widget_calendar td#next,
  .footer-columns-3 .footer-widget-area .widget_calendar td#prev span,
  .footer-columns-3 .footer-widget-area .widget_calendar td#next span {
    height: 51px;
    width: 51px;
    line-height: 51px;
  }

  .sidebar .photos_from_flickr .flickr_badge_image {
    width: 74px;
  }

  .footer-widget-area .photos_from_flickr .flickr_badge_image {
    width: 61px;
  }

  .nz-circle {
    width: 292px;
  }

  .slick-prev {
    left: 150px;
  }
  .slick-next {
    right: 150px;
  }

  .col8 .nz-tabs.vertical .tabset,
  .col9 .nz-tabs.vertical .tabset,
  .col10 .nz-tabs.vertical .tabset,
  .col11 .nz-tabs.vertical .tabset,
  .col12 .nz-tabs.vertical .tabset {
    width: 20%;
  }

  .col8 .nz-tabs.vertical .tabs-container,
  .col9 .nz-tabs.vertical .tabs-container,
  .col10 .nz-tabs.vertical .tabs-container,
  .col11 .nz-tabs.vertical .tabs-container,
  .col12 .nz-tabs.vertical .tabs-container {
    width: 80%;
  }

  .nz-gallery[data-columns="5"] .wp-caption-text,
  .nz-gallery[data-columns="6"] .wp-caption-text {
    display: block;
  }

  .woocommerce .main-content .products .product {
    width: 50%;
  }

  .nz-related-products.column-4 *[data-grid="ninzio_01"],
  .nz-counter[data-columns="4"] > .nz-count {
    width: 25% !important;
  }

  .main-content .nz-related-products.column-4 *[data-grid="ninzio_01"] {
    width: 50% !important;
  }

  .nz-counter.nz-counter[data-columns="3"] > .nz-count:after {
    width: 150px;
    margin-right: -75px;
  }

  .footer-copyright {
    float: left;
    line-height: 34px;
    font-size: 14px;
  }

  .footer-menu {
    float: right;
  }

  .footer-menu ul li {
    float: left;
    line-height: 34px;
  }

  .footer-menu ul li:not(:first-child) {
    padding-left: 40px;
    position: relative;
  }

  .ninzio-filter {
    text-align: center;
    max-width: 1170px;
    margin: 0 auto 70px auto;
  }

  .ninzio-filter .container {
    text-align: center;
    display: inline-block;
    width: auto;
    margin: 0;
    position: relative;
    background-color: #f9f9f9;
    border-radius: 200px;
    padding: 6px;
  }

  .ninzio-filter .filter {
    cursor: pointer;
    display: inline-block;
    padding: 10px 15px;
    border-radius: 200px;
    margin-bottom: 0px;
    line-height: 22px;
    font-size: 13px;
    font-weight: 600;
    width: auto;
    position: relative;
    z-index: 3;
    color: #777;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-transform: uppercase;
  }

  .nz-recent-projects .ninzio-filter .filter {
    margin-right: 0px;
  }

  .ninzio-filter .filter:before {
    content: attr(data-count);
    display: block;
    width: 30px;
    height: 30px;
    position: absolute;
    top: -15px;
    left: 50%;
    margin-left: -15px;
    border-radius: 3px;
    background-color: #cccccc;
    text-align: center;
    line-height: 30px;
    color: #ffffff;
    opacity: 0;
    -webkit-transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .ninzio-filter .filter:hover:before {
    top: -45px;
    opacity: 1;
  }

  .nz-products-filter .filter {
    margin-right: 3px;
  }

  .ninzio-filter .filter-container {
    display: inline !important;
  }
  .ninzio-filter .filter-toggle {
    display: none;
  }

  .ninzio-filter .filter:last-child {
    margin-right: 0;
  }

  .ninzio-filter .filter.active,
  .ninzio-filter .filter:hover {
    z-index: 15;
    color: #fff;
  }

  .main-content .ninzio-filter {
    text-align: left;
    margin-bottom: 70px;
    position: relative;
  }

  .main-content .ninzio-filter .filter-container {
    display: none;
  }

  .main-content .filter-toggle {
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 11px;
    right: 25px;
    cursor: pointer;

    z-index: 25;
  }

  .main-content .ninzio-filter .filter {
    cursor: pointer;
    display: block;
    padding: 10px 25px;
    line-height: 22px;
    margin-top: -1px;
    width: 100%;
    position: relative;
    z-index: 3;
    color: #777;
    box-shadow: inset 0 0 0 1px #e0e0e0;
    background-color: #edecea;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    float: none;
    margin-bottom: 0;
  }

  .main-content .ninzio-filter .filter.active:before,
  .main-content .ninzio-filter .filter:before {
    display: none;
  }

  .main-content .ninzio-filter .filter:last-child {
    margin-right: 0;
  }

  .main-content .ninzio-filter .filter:after {
    content: attr(data-count);
    width: 26px;
    height: 26px;
    font-size: 12px;
    border-radius: 26px;
    line-height: 26px;
    margin-top: -2px;
    display: inline-block;
    text-align: center;
    color: #777 !important;
    float: right;
    margin-right: 40px;
    background-color: #fff;
  }

  .main-content .ninzio-filter .filter.active {
    background-color: #f7f7f7;
    box-shadow: inset 0 0 0 1px #e0e0e0;
  }

  .main-content .ninzio-filter .filter.active:after {
    background-color: #edecea;
    color: #777 !important;
  }

  .single-product-image {
    width: 570px;
  }

  .single-product-summary {
    width: 600px;
  }

  .main-content .single-product-image {
    width: 50%;
  }

  .main-content .single-product-summary {
    width: 50%;
  }

  .woocommerce .main-content .post-social-share {
    padding: 5px;
  }

  .woocommerce .main-content .share-label {
    padding-right: 10px;
  }

  .main-content .single-image-content .ninzio-label {
    top: 36px;
  }

  .nz-recent-products[data-columns="2"] .product {
    width: 50%;
    float: left;
  }

  .woocommerce .loop .main-content {
    width: calc(100% - 292.5px);
  }
  .woocommerce .loop .sidebar {
    width: 292.5px;
  }

  .single .post .post-meta {
    padding-left: 100px;
  }

  .list .loop .main-content .blog-post *[data-grid="ninzio_01"] {
    width: 100% !important;
    float: none;
  }

  .list .loop .blog-post *[data-grid="ninzio_01"] > .nz-thumbnail,
  .list .loop .blog-post *[data-grid="ninzio_01"] > .post-body,
  .list .loop .blog-post *[data-grid="ninzio_01"] > .post-gallery {
    float: left;
    display: table-cell;
    width: 440px;
    min-height: 357px;
  }

  .list .loop .blog-post *[data-grid="ninzio_01"] > .post-body {
    width: 730px;
  }

  .list .loop .main-content .blog-post *[data-grid="ninzio_01"] {
    width: 100% !important;
    float: none;
  }

  .list .loop .main-content .blog-post *[data-grid="ninzio_01"] > .nz-thumbnail,
  .list .loop .main-content .blog-post *[data-grid="ninzio_01"] > .post-body,
  .list
    .loop
    .main-content
    .blog-post
    *[data-grid="ninzio_01"]
    > .post-gallery {
    display: table-cell;
    width: 380px;
    min-height: 345px;
  }

  .list .loop .main-content .blog-post *[data-grid="ninzio_01"] > .post-body {
    width: 437px;
  }

  .list .loop .blog-post *[data-grid="ninzio_01"] .post-body {
    padding: 0px !important;
  }

  .list .loop .blog-post *[data-grid="ninzio_01"] .post-body-in,
  .list .loop .main-content .blog-post *[data-grid="ninzio_01"] .post-body-in {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 40px 50px;
  }

  .list .loop .main-content .blog-post *[data-grid="ninzio_01"] .post-body-in {
    padding: 40px 30px;
  }

  .list .loop .main-content .blog-post .post .post-title {
    font-size: 20px;
    line-height: 40px;
  }

  .list .loop .main-content .blog-post .post .post-date-full {
    display: none;
  }

  .list .loop .main-content .blog-post .post .post-date-full-list {
    display: inline-block !important;
  }

  .single-project-layout-whole .main-content {
    padding-top: 20px;
  }

  /* .site-overlay.animated {
            cursor:url("images/close.png"), auto;
        } */

  .nz-section.autoheight-true > .nz-row {
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
    display: table;
    width: 100%;
  }
  .nz-section.autoheight-true .nz-row > .col {
    display: table-cell;
    vertical-align: top;
    float: none;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .nz-section.autoheight-true .valign-middle {
    vertical-align: middle !important;
  }

  .nz-section.autoheight-true .valign-bottom {
    vertical-align: bottom !important;
  }

  /*WPML*/
  .desk-menu > ul#header-menu > li.menu-item-language:hover,
  .desk-menu > ul#header-menu > li.menu-item-language a:hover,
  .desk-menu > ul#header-menu > li.menu-item-language:hover a {
    background-color: transparent !important;
    box-shadow: none !important;
  }

  .desk-menu > ul#header-menu > li.menu-item-language:after,
  .desk-menu > ul#header-menu > li.menu-item-language:before,
  .desk-menu > ul#header-menu > li.menu-item-language a:after,
  .desk-menu > ul#header-menu > li.menu-item-language a:before,
  .desk-menu > ul#header-menu > li.menu-item-language a .txt:after,
  .desk-menu > ul#header-menu > li.menu-item-language a .txt:before {
    display: none !important;
  }
}

@media only screen and (min-width: 1301px) {
  #wrap.nz-boxed {
    width: 1280px;
  }

  #wrap.nz-boxed .desk.fixed-true {
    width: 1280px;
    left: 50%;
    margin-left: -640px;
  }

  #wrap.nz-boxed .desk.stuck-boxed-true.slider-active {
    max-width: 1170px;
    width: 1170px;
    margin-left: -585px;
  }

  .slick-prev {
    left: 210px;
  }
  .slick-next {
    right: 210px;
  }

  #wrap.nz-boxed .slick-prev {
    left: 180px;
  }
  #wrap.nz-boxed .slick-next {
    right: 180px;
  }
}

@media only screen and (min-width: 1600px) {
  #wrap.nz-boxed {
    width: 1400px;
  }

  #wrap.nz-boxed .desk.fixed-true {
    width: 1400px;
    left: 50%;
    margin-left: -700px;
  }

  .slick-prev {
    left: 310px;
  }
  .slick-next {
    right: 310px;
  }

  #wrap.nz-boxed .slick-prev {
    left: 180px;
  }
  #wrap.nz-boxed .slick-next {
    right: 180px;
  }

  .woocommerce .main-content .products .product {
    width: 50%;
  }

  .woocommerce.single .main-content .products .product {
    width: 100%;
  }

  .nz-recent-products[data-columns="2"] .products .product {
    width: 50% !important;
  }

  .nz-recent-products[data-columns="1"] .products .product {
    width: 100% !important;
  }

  .full-width-true .desk-menu {
    position: relative;
  }

  .full-width-true .desk-menu > ul > [data-mm="true"] > ul {
    left: auto !important;
    right: 0 !important;
    -webkit-transform: none;
    -moz-transform: none;
    transform: none;
  }

  .desk.stuck-boxed-true.slider-active {
    max-width: 1270px;
    width: 1270px;
    margin-left: -635px;
  }
}

@media only screen and (min-width: 1601px) {
  .slick-prev {
    left: 410px;
  }
  .slick-next {
    right: 410px;
  }

  #wrap.nz-boxed .slick-prev {
    left: 180px;
  }
  #wrap.nz-boxed .slick-next {
    right: 180px;
  }
}

/*RETINA
/*=============*/

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3/2),
  only screen and (min--moz-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .mob-header-top .mob-menu-toggle.animated,
  .mob-sidebar-toggle2.animated,
  .mob-sidebar-toggle.animated,
  .mob-sidebar-toggle2,
  .filter-toggle.animate,
  .iversion-dark .desk-cart-toggle:after,
  .fiversion-dark.active .desk-cart-toggle:after,
  .iversion-dark .search-toggle:after,
  .fiversion-dark.active .search-toggle:after,
  .site-overlay.animated {
    background-size: 15px 15px;
  }

  .active + .filter-toggle.animate,
  .filter:hover + .filter-toggle.animate,
  .iversion-light .desk-cart-toggle:after,
  .fiversion-light.active .desk-cart-toggle:after,
  .iversion-light .search-toggle:after,
  .fiversion-light.active .search-toggle:after {
    background-size: 15px 15px;
  }

  .mob-header-content .cart-toggle {
    background-size: 18px 18px;
  }

  .woocommerce .product .single-product-summary .button,
  .woocommerce .product .single-product-summary .added_to_cart,
  .woocommerce .product .single-product-summary .product_type_external {
    background-size: 18px 18px;
  }

  .widget_recent_comments ul li:before,
  .wiversion-light .widget_recent_comments ul li:before,
  .footer-widget-area .widget_recent_comments ul li:before {
    background-size: 26px 26px;
  }

  .widget_twitter ul li:before,
  .wiversion-light .widget_twitter ul li:before,
  .footer-widget-area .widget_twitter ul li:before {
    background-size: 21px 18px;
  }

  #mce-EMAIL {
    background-size: 25px 18px;
  }

  .wiversion-light #mce-EMAIL {
    background-size: 25px 18px;
  }

  .null-instagram-feed ul li a:after,
  .photos_from_flickr .flickr_badge_image a:after,
  .recent-projects > .post a:after,
  .widget_nz_recent_entries .ninzio-overlay:before {
    background-size: 12px 12px;
  }

  .null-instagram-feed ul li a:after {
    background-size: 22px 22px;
  }

  .video-modal .video-icon {
    background-size: 82px 82px;
  }

  .single .post-social-share span {
    background-size: 18px 18px;
  }

  .woocommerce-tabs .tabs > li.description_tab > a:before {
    background-size: 18px 18px;
  }

  .woocommerce-tabs .tabs > li.additional_information_tab > a:before {
    background-size: 21px 20px;
  }

  .woocommerce-tabs .tabs > li.reviews_tab > a:before {
    background-size: 21px 19px;
  }

  .single-product-summary .post-social-share span {
    background-size: 18px 18px;
  }

  .iversion-dark .desk-cart-toggle:before,
  .fiversion-dark.active .desk-cart-toggle:before {
    background-size: 18px 18px;
  }

  .iversion-light .desk-cart-toggle:before,
  .fiversion-light.active .desk-cart-toggle:before {
    background-size: 18px 18px;
  }

  .version5.iversion-dark .desk-cart-toggle:before,
  .version5.fiversion-dark.active .desk-cart-toggle:before {
    background-size: 26px 26px;
  }

  .version5.iversion-light .desk-cart-toggle:before,
  .version5.fiversion-light.active .desk-cart-toggle:before {
    background-size: 26px 26px;
  }

  .main-content .filter-toggle {
    background-size: 16px 15px;
  }
}

#nz-box-3 .box-icon {
  color: #d0a35e;
}

#nz-box-3 .box-icon-wrap {
  background-color: #f9f9f9;
}

#nz-box-3:hover .box-icon {
  color: #ffffff !important;
}

#nz-box-3 .box-icon-wrap:before {
  background-color: #d0a35e;
}

@media (max-width: 1100px) {
  .social-links {
    height: 40px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: start !important;
  }
}

.logo-desk {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-menu-active {
  display: block !important;
}
.header-menu {
  transition: 0.5s !important;
}

@media (max-width: 990px) {
  /* .dropdown-menu-2 {
    position: inherit !important;
    left: 0 !important;
    margin-left: 1rem !important;
  } */

  /* .dropdown-menu {
    min-width: 100% !important;
    width: 100vw;
    left: 0 !important;
    margin-left: -23px !important;
  } */
}
