
/* swiper js */

.swiper {
  width: 100%;
  height: 100vh;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #cfcbcb;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat !important;
  overflow: hidden;
  background-color: #1e73be;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: auto;
  background-attachment: fixed;
  background-repeat: no-repeat !important;
  position: relative;
  animation: scale 40s linear infinite;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}

.transporting li {
  font-size: 12px !important;
}
.transporting li a {
  color: #ffb900 !important;
  text-decoration: none;
}
.tp-dottedoverlay {
  width: 100%;
  height: 100%;
  position: absolute;

  top: 0;
  left: 0;
}

.tp-ov-background {
  background-repeat: repeat;
}
.banner-content {
  position: absolute;
  top: 40%;
  transform: translate(0, -40%);
}
.banner-content {
  text-align: left;
  margin-left: 10%;
}
.banner-content h1 {
  font-weight: 750;
  color: #ffb700;
  font-size: 42px;
  text-transform: capitalize;
  font-family: "Open Sans", sans-serif;
  max-width: 651px;
}
.banner-content h2 {
  color: #fff;
  font-size: 22px;
  text-transform: capitalize;
}

.main-first {
  width: 250px;
  height: 50px;
  background: #ffb700;
}

.main-second {
  width: 250px;
  height: 50px;
  border: 1px solid #fff;
  background: none;
  margin-left: 2rem;
}
.main-head-buttons {
  margin-top: 3rem;
}

@media (max-width: 990px) {
  .banner-content {
    display: block;
  }
  .swiper-slide img {
    height: 100% !important;
  }
    .main-head-buttons{
      display: none;
    }
         .banner-content{
          text-align: center;
         }
        .banner-content h1{
          font-size: 22px;
        }
                .banner-content h2{
                  font-size: 16px;
                      line-height: 1.4;
                      font-weight: 400;
                }
                .swiper-button-prev{
                  display: none;
                }
                .swiper-button-next{
                  display: none;
                }
                .banner-content{
                  left: 0;
                }
}

.menu-toggler {
  position: absolute;
  right: 12px;
  top: 0;
}

.menu-toggler i {
  font-size: 22px;
}

@media (max-width: 993px) {
  .strip-wrapper {
    grid-template-columns: 1fr 1fr;
  }
  .strip-card {
    margin-bottom: 1rem;
  }
  .accordion-wrapper {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 557px) {
  .strip-wrapper {
    grid-template-columns: 1fr;
  }
  .strip-card {
    margin-bottom: 1rem;
  }
}
.box-data span {
  font-size: 16px;
}
.ul-colouring li a {
  font-size: 16px !important;
  text-decoration: none;
  color: #ffb900;
}
.swiper-slide img {
  height: 100% !important;
}

.strip-card a {
  text-decoration: none;
}
.strip-card a:hover {
  color: #1e73be;
}
.strip-card:hover {
  background: #fff;
  transform: translateY(-.5rem);
}

@keyframes scale {
  50% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
}

.icon-bulb:before {
  content: "\f0eb";
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.icon-info:before {
  content: "\f059";
  font-family: "Font Awesome 5 free";
  font-weight: 400;
}



.height-adj {
  height: 500px !important;
}

.displayAlignment {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 0.1rem;
} 

.ul-colouring a {
  text-transform: uppercase;
}
.nz-content-box .box-title {
  text-transform: uppercase !important;
  font-size: 18px !important;
  min-width: 300px !important;
}
.ul-colouring li a {
  font-size: 16px !important;
}

@media (max-width: 1100px) {
  .desk-menu a {
    margin-top: 0 !important;
  }
  .header-menu {
    display: none;
  }
  .header .logo a img {
    max-width: 94px;
  }
}

.hamburger-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hamburger-main i {
  font-size: 22px;
}
@media (min-width: 1100px) {
  .hamburger-main i {
    display: none;
    font-size: 22px;
  }
}
.location-sec {
  background: url(../image/worldss.jpg) center center;
  background-size: cover;
  padding: 12rem 0 !important;
}
@media screen and (min-width: 1200px) {
  .location-sec {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

.location-div h2 {
  padding: 2rem 0;
  font-size: 32px;
}
.panel-heading a {
  font-size: 22px;
}

.under-strip {
  padding: 1rem 0;
  background-color: #0795b296;
  position: relative;
  transition: 0.5s;
}

.strip-card {
  background: #ffffff;
  padding: 1rem 0 !important;
  display: flex;
  align-items: center;
  width: 95%;
  justify-content: center;
  border-radius: 5px;
  transition: 0.5s;
}

.strip-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 4;
  justify-content: center;
}
@media(max-width:990px){
  .strip-wrapper {
    
      grid-template-columns: 1fr 1fr ;
     
    }
}
@media(max-width:520px) {
  .strip-wrapper {

    grid-template-columns: 1fr ;

  }
}
.strip-card a {
  color: #ffb900;
  text-decoration: none;
  
}
.strip-card i{
  margin-right: .5rem;
}
.strip-card a:hover {
  text-decoration: none;
}

.blogss form .col {
  padding-left: 0 !important;
}